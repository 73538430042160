import Vue 			from 	'vue';
import Router 			from 	'vue-router';
import WizardCategory 		from 	'@/components/WizardCategory';
import WizardCategoryInfo 	from 	'@/components/WizardCategoryInfo';
import WizardSize 		from 	'@/components/WizardSize';
import WizardVariation 		from 	'@/components/WizardVariation';
// import WizardSummary 	from 	'@/components/WizardSummary';
import WizardProfile 		from 	'@/components/WizardProfile';
import WizardProfileInfo 	from 	'@/components/WizardProfileInfo';
import WizardColor 		from 	'@/components/WizardColor';
import WizardColorPicker 	from 	'@/components/WizardColorPicker';
import WizardGlass 		from 	'@/components/WizardGlass';
import WizardAccessories 	from 	'@/components/WizardAccessories';
import WizardAccessoryInfo 	from 	'@/components/WizardAccessoryInfo';
import WizardAccessoriesPicker 	from 	'@/components/WizardAccessoriesPicker';
import WizardOrder 		from 	'@/components/WizardOrder';
import Generator 		from 	'@/components/Generator';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: 		'/',
      name: 		'home',
      component: 	WizardCategory,
      meta: 		{ step: 1 }
    },
    {
      path: 		'/category/:id',
      name: 		'categoryInfo',
      component: 	WizardCategoryInfo,
      meta: 		{ step: 1 }
    },
    {
      path: 		'/size',
      name: 		'size',
      component: 	WizardSize,
      meta: 		{ step: 2 }
    },
    {
      path: 		'/variation',
      name: 		'variation',
      component: 	WizardVariation,
      meta: 		{ step: 2 }
    },
//    {
//      path: 		'/summary',
//      name: 		'summary',
//      component: 	WizardSummary,
//      meta: 		{ step: 2 }
//    },
    {
      path: 		'/profile',
      name: 		'profile',
      component: 	WizardProfile,
      meta: 		{ step: 3 }
    },
    {
      path: 		'/profile/:id',
      name: 		'profileInfo',
      component: 	WizardProfileInfo,
      meta: 		{ step: 3 }
    },
    {
      path: 		'/color',
      name: 		'color',
      component: 	WizardColor,
      meta: 		{ step: 4 }
    },
    {
      path: 		'/color/:face',
      name: 		'colorPicker',
      component: 	WizardColorPicker,
      meta: 		{ step: 4 },
      props: true
    },
    {
      path: 		'/glass',
      name: 		'glass',
      component: 	WizardGlass,
      meta: 		{ step: 5 }
    },
    {
      path: 		'/accessories',
      name: 		'accessories',
      component: 	WizardAccessories,
      meta: 		{ step: 6 }
    },
    {
      path: 		'/accessories/:accessory',
      name: 		'accessoriesPicker',
      component: 	WizardAccessoriesPicker,
      meta: 		{ step: 6 },
      props: 		true
    },
    {
      path: 		'/accessory/:accessory/:id',
      name: 		'accessoryInfo',
      component: 	WizardAccessoryInfo,
      meta: 		{ step: 6 },
      props: 		true
    },
    {
      path: 		'/order',
      name: 		'order',
      component:	WizardOrder,
      meta: 		{ step: 7 }
    },
    {
      path: 		'/generator',
      name: 		'generator',
      component: 	Generator,
      meta: 		{ generator: true }
    }
  ]
});

export default router;
