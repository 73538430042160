import axios from 'axios';

// initial state
const state = {
  colors_accessories: [],
  selected: null
};

// getters
const getters = {};

// actions
const actions = {
  loadColorsAccessories({ commit }, callback) {
    axios
     .get('/wizard/public/storage/colors/colors_accessories.json')
       .then(response => {
	commit('updateColorsAccessories', response.data);
        if (typeof callback === 'function') {
          callback();
        }
      });
  },
  selectColorsAccessories({ commit }, colors_accessories) {
    commit('selectColorsAccessories', colors_accessories);
  }
};

// mutations
const mutations = {
  updateColorsAccessories(state, colors_accessories) {
    if (typeof colors_accessories === 'object') {
      state.colors_accessories = Object.keys(colors_accessories).map(key => colors_accessories[key]);
    } else {
      state.colors_accessories = colors_accessories;
    }
  },
  setColorsAccessoriesLoaded(state) {
    state.loaded = true;
  },
  selectColorsAccessories(state, colors_accessories) {
      state.selected = colors_accessories;
  },
  resetColorsAccessories(state) {
      state.selected = null;
  },
  setColorsAccessories(state, colors_accessories) {
      state.selected = colors_accessories;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
