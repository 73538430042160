import axios from 'axios';

// initial state
const state = {
  accessories:   {
    inner:       null,
    outer:       null,
    blind:       null,
    handle:      { front: null, back: null },
    other:       null,
    insectnet:   null,
    cover:       { front: null, back: null },
    coverdrain:  { front: null, back: null }
  },
  products: {
    inner:       null,
    outer:       null,
    blind:       null,
    handle:      { front: null, back: null },
    other:       null,
    insectnet:   null, 
    cover:       { front: null, back: null },
    coverdrain:  { front: null, back: null }
  }
};

// getters
const getters = {};

// actions
const actions = {
  selectAccessory({ commit }, { accessory, type }) {
    commit('selectAccessory', { accessory, type });
  },
  loadAccessories({ commit }, { type, callback }) {
    axios
      .get(
        `/wizard/public/storage/accessories/${type}.json`
      )
      .then(response => {
        commit('updateAccessories', { data: response.data, type });
        if (typeof callback === 'function') {
          callback();
        }
      });
  }
};

// mutations
const mutations = {
  selectAccessory(state, { accessory, type }) {
    state.accessories[type] = accessory;
  },
  updateAccessories(state, { data, type }) {
    state.products[type] = data;
  },
  resetAccessories(state) {
    state.accessories = {
      inner:       null,
      outer:       null,
      blind:       null,
      handle:      { front: null, back: null },
      other:       null,
      insectnet:   null,
      cover:	   { front: null, back: null },
      coverdrain:  { front: null, back: null }
    };
  },
  setAccessories(state, accessories) {
    state.accessories = accessories;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
