import Vue 			from 'vue';
import Vuex			from 'vuex';
import cart 			from './modules/cart';
import variations 		from './modules/variations';
import currencies		from './modules/currencies';
import discounts 		from './modules/discounts';
import profiles 		from './modules/profiles';
import categories 		from './modules/categories';
import catminmax 		from './modules/catminmax';
import colors 			from './modules/colors';
import glass 			from './modules/glass';
import glassweight		from './modules/glassweight';
import accessories 		from './modules/accessories';
import colors_accessories	from './modules/colors_accessories';


Vue.use(Vuex);

const storeModerator = store => {
  store.subscribe((mutation, state) => {
    if (mutation.type.indexOf('cart') !== -1) {
      window.localStorage.setItem( 'cart_contents', JSON.stringify(state.cart.contents)
      );
    }
  });
};

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state: {
    debug: debug,
    started: true
  },
  modules: {
    cart,
    currencies,
    discounts,
    profiles,
    categories,
    catminmax,
    variations,
    colors,
    glass,
    glassweight,
    accessories,
    colors_accessories
  },
  strict: debug,
  plugins: [storeModerator]
});
