import axios from 'axios';

// initial state
const state = {
  profiles: [],
  selected: null
};

// getters
const getters = {};

// actions
const actions = {
  loadProfiles({ commit }, callback) {
    axios
      .get(
        '/wizard/public/storage/helper/assets/profiles.json'
      )
      .then(response => {
        commit('updateProfiles', response.data);
        if (typeof callback === 'function') {
          callback();
        }
      });
  },
  selectProfile({ commit }, profile) {
    commit('selectProfile', profile);
  }
};

// mutations
const mutations = {
  updateProfiles(state, profiles) {
    var profilesArray = profiles;

    if (typeof profilesArray === 'object') {
      profilesArray = Object.values(profiles);
    }

    var profilesByPosition = profilesArray.slice(0);

    profilesByPosition.sort(function(a,b) {
      let x = parseInt(a.position);
      let y = parseInt(b.position);

      return x < y ? -1 : x > y ? 1 : 0;
    });

    //state.profiles = profiles;
    state.profiles = profilesByPosition;
  },
  selectProfile(state, profile) {
    state.selected = profile;
  },
  resetProfiles(state) {
    state.selected = null;
  },
  setProfiles(state, profile) {
    state.selected = profile;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
