<template>
    <div class="container">
    <div v-show="errorMsg" class="errorAround" @click="closeError"><div class='errorShadow'></div><div class="errorWindow"><div class="cartItemDelete" @click="closeError"></div><div id="errorText" class="errorText"></div></div></div>
    <div class      	    	= "variationInfoIcon"
    	    @mousedown          = "showInfo('top', true)"
    	    @touchstart.prevent = "showInfo('top', true)"
    	    @mouseover          = "showInfo('top')"
    	    @mouseleave         = "closeInfo">                                                                        
        <div class="infoTitle">Nápověda <div class="infoTitleString">Typ produktu</div></div>
    </div>                
    <div class="variationInfo" v-show="isInfoVisible  && infoProfileId == 'top'"
	    @mouseover  	= "showInfo('top')"
            @mouseleave 	= "closeInfo"
    >
       <span @click="closeInfo" @touch="closeInfo" class="icon icon-infoClose"></span>
       <div  class="variationDetail" v-html="details"></div>
    </div>
    <div class="titleH1">
      <h2 class="headline--padded text-center" v-html="$t('variationTitle')"></h2>
    </div>

    <div class="cols padded--bottom movable">
      <div v-if="availableVariations().length > movableSize"
        class="movable__arrow movable__arrow--left">
        <button @click="movablePrev"
        :class="[{ 'disabled' : movableOrder === 0 }]">Prev</button>
      </div>

      <div v-for="(variation, index) in availableVariations()" v-bind:key="index"
      class="variation" :class="[{ 'selected' 
      : isSelected(variation) }]" 
      :style="[{ 'order' : index < movableOrder ?
      availableVariations().length + movableOrder + index : index + movableOrder }]"
      @click="select(variation)"
      v-show="fitMinimalForVariation(variation)"
      >
        <div class="variation__inner">
	<!--  <div style='z-index:1;position:absolute;padding-left:4px;padding-right:4px;top:4px;right:4px;z-index=2;border:1px #ccc solid;'>id:{{ variation.id }} | {{ index + 1 }}</div> -->
          <div class="variation__image">
            <Preview :okna="getOkna({
        	variation, 
        	orientation : isSelected(variation) ? orientation : 'right', 
        	width, 
        	height,
        	canvasWidth: 4000,
        	side: 'front',
        	textureImage: currentCartItem.windowfaces['front'] !== null ? getStorageUrl(currentCartItem.windowfaces['front'].image) : null
    	       })">
            </Preview>
          </div>
          <div class="variation__about">
            <div class="variation__title" v-html="$t(variation.type)">
            </div>
            <div class="variation__size"
            v-html="$t('variationSize', { width: width, height: height })">
            </div>
          </div>
          <div class="variation__orientation"
            :class="[{ 'invisible' : !hasOrientation(variation) }]">
            <div class="variation__orientation__label" v-html="$t('orientation')">
            </div>
            <div class="btn-group">
              <button
              type="button"
              @click="selectOrientation('left')"
              :class="[{ 'selected' : orientation === 'left' && isSelected(variation) }]"
              v-html="$t('orientationLeftSymbol')">
              </button>
              <button
              type="button"
              @click="selectOrientation('right')"
              :class="[{ 'selected' : orientation === 'right' && isSelected(variation) }]"
              v-html="$t('orientationRightSymbol')">
              </button>
            </div>
          </div>
<!--
          <div class="variation__price price price--novat"
          v-html="$t(`priceNoVat`,
          { value: currencyFormatNum(getPriceForVariation(variation))})">
          </div>
-->

          <div class="variation__price price price--novat"
          v-html="$t(`priceNoVat`,
          { value: currencyFormatNum(variation.outprice)})">
          </div>
        </div>
      </div>
      <div v-if="availableVariations().length > movableSize"
        class="movable__arrow movable__arrow--right">
        <button @click="movableNext"
        :class="[{ 'disabled' : movableOrder === movableLimit - movableSize }]">Next</button>
      </div>
      <div v-if="availableVariations().length === 0"
      class="text-center">
        Pro zadané rozměry nejsou k dispozici žádné varianty.
      </div>
    </div>

    <div class="stickyFooterAround">
      <div class="stickyFooter">
	<div class='helpFooter'>
<!--	 <div class="helpImg" @click="helpText();"></div>
	 <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div>
-->
             <div id="helpText" class="helpText" style="inline-block;"><span class="infoTel"><a href="tel:+420 776 327 222">+420 776 327 222</a></span> <br> <span class="infoMail"><a href="mailto:info@skladoken.cz">info@skladoken.cz</a></span></div>
	</div>

        <button
        @click="backButton"
        class="stickyButtonBack"
        v-html="$t('backButtonText')"></button>

        <button
        @click="confirm"
        class="stickyButton"
        :class="[{ 'disabled' : (this.availableVariations().length == 0 || !isConfirmReady()) }]"
        v-html="$t('confirmAndContinue')"></button>
      </div>
    </div>

<!--
    <div class="stickyFooterAround" v-if="availableVariations().length > 0">
      <div class="stickyFooter">
	<div class='helpFooter'>
	 <div class="helpImg" @click="helpText();"></div>
	 <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div>
	</div>

        <button
        @click="backButton"
        class="stickyButtonBack"
        v-html="$t('backButtonText')"></button>

        <button
        @click="confirm"
        class="stickyButton"
        :class="[{ 'disabled' : !isConfirmReady() }]"
        v-html="$t('confirmAndContinue')"></button>
      </div>
    </div>
-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import Preview from "@/components/Preview";

const { mapFields } = createHelpers({
  getterType: "getCurrentCartField",
  mutationType: "updateCurrentCartField"
});

export default {
  name: "Variation",
  components: {
    Preview
  },
  data() {
    return {
      movableOrder: 0,
      isInfoVisible: false,
      infoProfileId: "",
      errorMsg: false
    };
  },
  computed: {
    ...mapState({
      variationsLoaded: state => state.variations.loaded,
      variations:       state => state.variations.variations.slice(1),
      profiles:         state => state.profiles.profiles.slice(1),
      categories:       state => state.categories.categories
    }),
    ...mapFields("cart", [
      "size.width",
      "size.height",
      "variation",
      "profile",
      "category",
      "orientation",
      "mk",
      "vk"
    ]),
    details() {
     if(this.$store.state.variations.variations[0])
      { return this.$store.state.variations.variations[0].details; }
     return false;
    },
/* 22.03.2023 moved to main.js BEGIN
    availableVariations() {
     ...
    },
22.03.2023 moved to main.js END */
    movableLimit() {
      return this.availableVariations().length;
    },
    movableSize() {
      return 4;
    }
  },
  methods: {
    showInfo(id, click)
     {
      if(this.isInfoVisible == false && this.infoProfileId == "")
       {
        this.isInfoVisible  = true;
        this.infoProfileId = id;
       }
      else if (this.isInfoVisible == true && this.infoProfileId != "")
       {
        if (this.infoProfileId == id && click)
         {
          this.isInfoVisible  = false;
          this.infoProfileId  = "";
         }
        else
         {
          this.isInfoVisible  = true;
          this.infoProfileId = id;
         }
       }
      else
       {
        this.isInfoVisible  = false;
	this.infoProfileId = "";
       }
     },
    closeInfo() 
     {
      this.isInfoVisible = false;
      this.infoProfileId = "";
     },
/* 22.03.2023 moved to main.js BEGIN
    getPriceForVariation(variation, source) {
      // console.log(variation);
      return this.getProductPrice(variation, null, this.profiles, 0.0, null, null, null, source);
    },
    fitMinimalForVariation(element) {
      const collection = this.variations.filter( item => element.typenum === item.typenum );
      const minHeight  = this.propertyLimit(collection, "height", "min");
      const minWidth   = this.propertyLimit(collection, "width",  "min");
      
      return (parseInt(this.width,  10) >= minWidth && parseInt(this.height, 10) >= minHeight);
    },
 22.03.2023 moved to main.js END */

    movablePrev() {
      if (this.movableOrder > 0) {
        this.movableOrder = this.movableOrder - 1;
      }
    },
    movableNext() {
      if (this.movableOrder < this.movableLimit - this.movableSize) {
        this.movableOrder = this.movableOrder + 1;
      }
    },
    close() {
      this.$router.go(-1);
    },
    isEligible() {
      return this.width !== "" && this.height !== "";
    },
    isSelected(variation) {
//    console.log('variation', variation);
//    console.log('this.variation', this.variation);
      if (typeof this.variation === 'undefined' || this.variation === null) {
        return false;
      }
      if (typeof variation!== 'undefined')
       {
        return (
          this.variation.height === variation.height &&
          this.variation.width  === variation.width  &&
          this.variation.type   === variation.type   &&
          this.variation.price  === variation.price
         );
       }
      return false;
    },
    selectOrientation(orientation) {
      this.orientation = orientation;
    },
    select(variation) {
      // Select variation
      this.variation 		     = variation;
      this.mk	     		     = variation.mk;
      this.vk	     		     = variation.vk;
    },
    isConfirmReady() {
      if (!this.variation) 		  { return false; }
      if (this.variation        === null) { return false; }
      if (this.variation.height === "")   { return false; }
      if (this.variation.width  === "")   { return false; }
      if (this.variation.type   === "")   { return false; }
      if (this.variation.price  === "")   { return false; }

      return this.variation !== null;
    },
    confirm() {
      let element = document.getElementsByClassName('stickyButton')[0];
      if(element.classList.contains('disabled'))
       { 
        document.getElementById("errorText").innerHTML ="";
        this.errorMsg = true; 
        document.getElementById("errorText").innerHTML ="Vyberte produkt";
       }
      if (this.isConfirmReady() && this.availableVariations().length > 0) {
        this.$router.push({ name: "profile" });
      }
    },
    closeError() {
      document.getElementById("errorText").innerHTML ="";
      this.errorMsg = false;
    },
    backButton() {
//     this.variation = {}; // clear object
//     this.variation.type   = "";
//     this.variation.price  = "";
//     this.variation.width  = "";
//     this.variation.height = "";
     this.$router.push({ name: "size" });
    }
  },
  watch: {
    variation(value, oldValue) {
      if (value !== oldValue) {
        // Set default orientation
        if(this.orientation !== 'left')
         {
          if(this.hasOrientation(this.variation))
           { this.selectOrientation('right'); }
          else
           { this.selectOrientation('none'); }
	 }
      }
    }
  },
  mounted() {
    this.$store.dispatch("loadProfiles");
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.variation {
  .variation__inner {
    @include easeHover;
  }
}

.tooltip--variation {
  position: absolute;
  top: 35px;
  left: 50%;
  margin-left: 230px;
  width: 275px;
  max-width: 275px;
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

.variation__about {
  height: 140px;
}
</style>