<template>
  <div class="container">
    <h2 class="headline--padded text-center" v-html="accessoryTitle(accessory)"></h2>
    <span @click="close" class="icon icon-close"></span>
    <div v-if="accessory === 'inner' || accessory === 'outer'">
      <div class="cols">
        <div class="bumper-left"></div>
        <div class="form--mini">
          <div class="form-row form-row--padded cols">
            <div class="form--mini__cell">
              <!-- Complex field -->
              <div class="complex-field complex-field--compact">
                <div class="complex-field__icon"><span class="sizeWidthIcon"></span></div>
                <div class="complex-field__field">
                  <label for="length" v-html="$t('lengthLabel')"></label>
                  <div class="input-group input-group--lg">
                    <input v-model="length" type="text" class="input input--lg input--rounded" id="length" v-on:keyup="confirmHandle();">
                    <span class="input-group__suffix">mm</span>
            	    <div class='helpLength'>Zadejte délku</div>
                  </div>
                  <p class="help-block" v-html="$t('lengthHelp', { min: lengthMin, max: lengthMax })"></p>
                </div>
              </div>
            </div>
            <div class="form--mini__cell">
              <!-- Complex field -->
              <div class="complex-field complex-field--compact">
                <div class="complex-field__icon"><span class="sizeHeightIcon"></span></div>
                <div class="complex-field__field">
                  <label for="depth" v-html="$t('depthLabel')"></label>
                  <div class="input-group input-group--lg" id="selectBlock" >
                    <div class="input-group__suffix" >
                    <div class="selectArrow">▼</div> mm </div>
            	    <div class='helpDepth'>Vyberte hloubku</div>
                    <select v-model="depth" id="select" class="select">
                      <option v-for="(option, index) in allDepths" v-bind:key="index" v-bind:value="option.depth" >{{ option.depth }}</option>
                    </select>
                  </div>
                  <p class="help-block" v-html="$t('depthHelp', { min: depthMin, max: depthMax })"></p>
                </div>
              </div>
            </div>
          </div>

          <div class="stickyFooterAround">
           <div class="stickyFooter">
	    <div class='helpFooter'>
<!--	     <div class="helpImg" @click="helpText();"></div>
	     <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div> -->
             <div id="helpText" class="helpText" style="inline-block;"><span class="infoTel"><a href="tel:+420 776 327 222">+420 776 327 222</a></span> <br> <span class="infoMail"><a href="mailto:info@skladoken.cz">info@skladoken.cz</a></span></div>
	    </div>
            <button @click="confirm" class="stickyButton" :class="[{ 'disabled' : !isConfirmReady() }]" v-html="$t('color.picker.confirm')"></button>
           </div>
	  </div>
        </div>
        <div class="bumper-right"></div>
      </div>
    </div>
    <div v-else-if="accessory === 'blind'">
      <div class="cols">
        <div class="bumper-left"></div>
        <div class="form--mini">
          <div class="form-row form-row--padded cols">
            <div class="form--mini__cell">
              <!-- Complex field -->
              <div class="complex-field complex-field--compact">
                <div class="complex-field__icon"><span class="sizeWidthIcon"></span></div>
                <div class="complex-field__field">
                  <label for="width" v-html="$t('widthLabel')"></label>
                  <div class="input-group input-group--lg">
                    <input v-model="width" type="text" class="input input--lg input--rounded" id="width">
                    <span class="input-group__suffix">mm</span>
                  </div>
                  <p class="help-block" v-html="$t('widthHelp', { min: widthMin, max: widthMax })"></p>
                </div>
              </div>
            </div>
            <div class="form--mini__cell">
              <!-- Complex field -->
              <div class="complex-field complex-field--compact">
                <div class="complex-field__icon"><span class="sizeHeightIcon"></span></div>
                <div class="complex-field__field">
                  <label for="height" v-html="$t('heightLabel')"></label>
                  <div class="input-group input-group--lg">
                    <input v-model="height" type="text" class="input input--lg input--rounded" id="height">
                    <span class="input-group__suffix">mm</span>
                  </div>
                  <p class="help-block" v-html="$t('heightHelp', { min: widthMin, max: widthMax })"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row form-row--padded text-center">
            <button @click="confirmSize" class="btn btn--lg btn--primary btn--rounded" :class="[{ 'disabled' : !isConfirmReady() }]" v-html="$t('confirmSize')"></button>
          </div>
        </div>
        <div class="bumper-right"></div>
      </div>
    </div>

    <div class="cols padded--bottom justify-center cols--color" v-if="sizeReady">
      <div v-for="(color, index) in fittingProducts" v-bind:key="index" 
        class="color__palette" :class="[{ 'selected' : selectedColor !== null && selectedColor && selectedColor.id && color.id && selectedColor.id === color.id }]" 
        @click="select(color); confirmHandle();">
        
        <h4 class="color__title">{{ color.name }}</h4>
        <div class="color__inner">
          <div class="color__inner__image">
            <img v-if="color && color.color && color.color.image" :src="getStorageUrl(color.color.image)" class="color__image">
            <img v-else-if="color.image" :src="getStorageUrl(color.image)" class="color__image">
          </div>
          <div class="color__price price price--novat" v-if="accessory      === 'blind'"  v-html="$t(`priceNoVatPlus`, { value: currencyFormat(getInCzk(color.price, 'EUR')) })"></div>
	  <div class="color__price price price--novat" v-else-if="accessory === 'handle'" v-html="$t(`priceString`,    { value: getPriceForHandle(color) })"></div>
          <div class="color__price price price--novat" v-else                             v-html="$t(`priceString`, { value: getPriceForAccessory(color) })"></div>
        </div>
      </div>
    </div>

    <div class="stickyFooterAround">
      <div class="stickyFooter">
        <div class='helpFooter'>
<!--         <div class="helpImg" @click="helpText();"></div>
         <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div> 
-->
	 <div id="helpText" class="helpText" style="inline-block;"><span class="infoTel"><a href="tel:+420 776 327 222">+420 776 327 222</a></span> <br> <span class="infoMail"><a href="mailto:info@skladoken.cz">info@skladoken.cz</a></span></div>
        </div>
        <button
        @click="confirm"
        class="stickyButton"
        :class="[{ 'disabled' : !isSelectReady() }]"
        v-html="$t('color.picker.confirm')"></button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType:   "getCurrentCartField",
  mutationType: "updateCurrentCartField"
});

export default {
  name: "WizardAccessoriesPicker",
  props: ["accessory"],
  computed: {
    ...mapState({
      colors:            state => state.colors.colors.slice(1),
      products:          state => state.accessories.products,
      accessoryProducts: state => state.accessories.products,
    }),
    ...mapFields("cart", [
      "variation",
      "accessories",
      "accessories.handle",
      "accessories.inner",
      "accessories.outer",
      "accessories.blind",
      "accessories.cover",
      "accessories.coverdrain",
      "accessories.insectnet"
    ]),
    ...mapFields("cart", {
      windowWidth: "size.width",
      windowHeight: "size.height"
    }),
    fittingProducts() {
      if (this.accessory === "blind") {
        if(this.items)
         {
          const height = Math.round(this.height / 10) * 10;
          const width = Math.round(this.width / 10) * 10;
          return this.items.filter( element => parseInt(element.height) === height && parseInt(element.width) === width );
         }
        else
         {
          return false;
         }
      }
      if (this.accessory === "outer" || this.accessory === "inner") {
        if(this.items)
         {
          const depth = Math.round(this.depth / 5) * 5;
          return this.items.filter(element => parseInt(element.depth) === parseInt(depth));
         }
        else
         {
          return false;
         }
      }
      return this.items;
    },
    items() {
      let acc = this.accessory;
      if(this.products[acc] !== null)
       {
        if (this.products[acc] && this.products[acc].slice(1).items) 
         { return this.products[acc].slice(1).items; }
        return this.products[acc].slice(1);
       }
      else
       {
        return null;
       }
    },
    allDepths() {
      let acc = this.accessory;
      var flags = {};
      
      if(this.products[acc] !== null)
       {
        if (this.products[acc] && this.products[acc].slice(1).items) 
         { let out = this.products[acc].slice(1).items.sort(function(a, b){return a.depth - b.depth}); 
            return out.filter(function(entry) 
    		{ if (flags[entry.depth]) { return false; }
                flags[entry.depth] = true; return true;
              });         
         }    
        let out = this.products[acc].slice(1).sort(function(a, b){return a.depth - b.depth});
        return out.filter(function(entry) 
    		{ if (flags[entry.depth]) { return false; }
                flags[entry.depth] = true; return true;
              });
       }
      else
       {
        return null;
       }
     },
    selected()  { return this.$store.state.colors.slice(1)[this.face];    },
    widthMin()  { return this.propertyLimit(this.items, "width", "min");  },
    widthMax()  { return this.propertyLimit(this.items, "width", "max");  },
    depthMin()  { return this.propertyLimit(this.items, "depth", "min");  },
    depthMax()  { return this.propertyLimit(this.items, "depth", "max");  },
    heightMin() { return this.propertyLimit(this.items, "height", "min"); },
    heightMax() { return this.propertyLimit(this.items, "height", "max"); },

    sizeReady() {
      if ((this.accessory === "inner" || this.accessory === "outer") 
         && parseInt(this.length) >= this.lengthMin 
         && parseInt(this.length) <= this.lengthMax 
         && parseInt(this.depth)  >= this.depthMin 
         && parseInt(this.depth)  <= this.depthMax 
      ) { return true; }
      if (  this.accessory === "blind" 
         && parseInt(this.height) >= this.heightMin 
         && parseInt(this.height) <= this.heightMax 
         && parseInt(this.width) >= this.widthMin 
         && parseInt(this.width) <= this.widthMax 
         && this.confirmedSize
      ) { return true; }
      if (this.accessory === "handle") 
        { return true; }
      return false;
    }
  },
  data() {
    return {
      placeholder: "/static/colors/placeholder.png",
      lengthMin: 0,
      lengthMax: 6000,
      length: "",
      depth: "",
      height: "",
      width: "",
      inLength: "",
      inDepth: "",
      inHeight: "",
      inWidth: "",
      confirmedSize: false,
      selectedColor: null,
      inSelectedColor: null
    };
  },
  watch: {
    width()  { this.confirmedSize = false; },
    height() { this.confirmedSize = false; },
    depth()  { this.confirmedSize = false; },
    length() { this.confirmedSize = false; },
  },
  methods: {
    close()       { 
//    console.log(this.inSelectedColor);
       this[this.accessory] = {
	width:  parseInt(this.inWidth),
        height: parseInt(this.inHeight),
        length: parseInt(this.inLength),
        depth:  parseInt(this.inDepth),
        color:  this.inSelectedColor
      };
     this.$router.push({ name: "accessories" });       
    },
    select(color) { this.selectedColor = color; this.confirmHandle(); },
    confirmSize() { this.confirmedSize = true;                        },

    getPriceForHandle(color)
     {
      let outPriceString = '';
      let outPriceValue   = 0;

      if(this.selectedColor !== null)
       {
        if (this.selectedColor.id === color.id)
         {
	  outPriceString = 'Vybráno';
          return outPriceString;	
         }
        else
         {
	  outPriceValue = this.currencyFormatNum(this.getInCzk(this.applyDiscount(color.price, 'variations'), 'EUR')) 
			- this.currencyFormatNum(this.getInCzk(this.applyDiscount(this.selectedColor.price, 'variations'), 'EUR'));

          if (outPriceValue > 0)       { outPriceString = '+' + outPriceValue.toString(); }
          else                         { outPriceString =       outPriceValue.toString(); }

          return outPriceString + " Kč <span class='bezDPH'>bez DPH</span>";
         }
       }
     },

    getPriceForAccessory(color)
     {
      let outPriceString = '';
      let outPriceValue   = 0;

      if(this.selectedColor !== null)
       {
        if (this.selectedColor.id === color.id)
         {
	  outPriceString = 'Vybráno';
          return outPriceString;	
         }
        else
         {
	  outPriceValue = this.currencyFormatNum(this.applyDiscount(this.getPriceForInner(color), 'variations')) 
			- this.currencyFormatNum(this.applyDiscount(this.getPriceForInner(this.selectedColor), 'variations'));

          if (outPriceValue > 0)       { outPriceString = '+' + outPriceValue.toString(); }
          else                         { outPriceString =       outPriceValue.toString(); }

          return outPriceString + " Kč <span class='bezDPH'>bez DPH</span>";
         }
       }
      else
       {
        outPriceValue = this.currencyFormatNum(this.applyDiscount(this.getPriceForInner(color), 'variations'))
        
        if (outPriceValue > 0)       { outPriceString = '+' + outPriceValue.toString(); }
        else                         { outPriceString =       outPriceValue.toString(); }

        return outPriceString + " Kč <span class='bezDPH'>bez DPH</span>";
       }
     },
    confirmHandle() {
        this[this.accessory] = {
        width:  parseInt(this.width),
        height: parseInt(this.height),
        length: parseInt(this.length),
        depth:  parseInt(this.depth),
        color:  this.selectedColor 
      };
    },

    confirm()     {
        this[this.accessory] = {
        width:  parseInt(this.width),
        height: parseInt(this.height),
        length: parseInt(this.length),
        depth:  parseInt(this.depth),
        color:  this.selectedColor
      };
      this.$router.push({ name: "accessories" });
    },
    isConfirmReady() {
      if (
        (this.accessory === "outer" || this.accessory === "inner") 
        && parseInt(this.length) >= this.lengthMin 
        && parseInt(this.length) <= this.lengthMax 
        && parseInt(this.depth)  >= this.depthMin 
        && parseInt(this.depth)  <= this.depthMax
      ) { return true; }
      if (
        this.accessory === "blind" 
        && parseInt(this.width)  >= this.widthMin 
        && parseInt(this.width)  <= this.widthMax 
        && parseInt(this.height) >= this.heightMin 
        && parseInt(this.height) <= this.heightMax
      ) { return true; }
      return false;
    },
    isSelectReady() { return this.selectedColor !== null; }
  },
  mounted() {
   this.$store.dispatch("loadAccessories", { type: this.accessory });

   if (this.accessory !== 'inner' && this.accessory !== 'outer') {
     this.$store.dispatch("loadColors", () => {
       setTimeout(() => {
        if (this.selectedColor === null && typeof this.fittingProducts[0] !== "undefined")
         {
          if(this.accessories[this.accessory] !== null && this.accessories[this.accessory].color !== null && this.accessories[this.accessory].color.id)
           {
            var getProduct = this.fittingProducts.filter(obj => { return obj.id === this.accessories[this.accessory].color.id });
            this.select(getProduct[0]); 
            this.inSelectedColor = getProduct[0];
            this.inLength = parseInt(this.length);
            this.inDepth  = parseInt(this.depth);
            this.inHeight = parseInt(this.height);
            this.inWidth  = parseInt(this.width);
           }
          else
           {
            this.select(this.fittingProducts[0]);
            this.inSelectedColor = this.fittingProducts[0];
	    this.inLength = parseInt(this.length);
	    this.inDepth  = parseInt(this.depth);
	    this.inHeight = parseInt(this.height);
	    this.inWidth  = parseInt(this.width);
           }
         }
       }, 100);
     });
   }
   else {
      this.$store.dispatch("loadColors", () => {
       setTimeout(() => {
        if (this.selectedColor === null && typeof this.fittingProducts[0] !== "undefined") 
         {
          if(this.accessories[this.accessory] !== null &&this.accessories[this.accessory].color !== null && this.accessories[this.accessory].color.id)
           {
            var getProduct = this.fittingProducts.filter(obj => { return obj.id === this.accessories[this.accessory].color.id });
            this.select(getProduct[0]); 
            this.inSelectedColor = getProduct[0];
	    this.inLength = parseInt(this.length);
	    this.inDepth  = parseInt(this.depth);
	    this.inHeight = parseInt(this.height);
	    this.inWidth  = parseInt(this.width);
           }
         }
       }, 100);
     });
   }
   
   // Choose default color
   if ( this.$store.state.accessories.accessories && this.$store.state.accessories.accessories[this.accessory]) 
    {
       const selected     = this.$store.state.accessories.accessories[this.accessory];
       this.depth         = parseInt(selected.depth);
       this.length        = parseInt(selected.length);
       this.width         = parseInt(selected.width);
       this.height        = parseInt(selected.height);
       this.selectedColor = selected.color;
    } 
   else if (this.accessory === "inner") 
    {
     this.length        = this.windowWidth;
     this.depth         = 250;
    } 
   else if (this.accessory === "outer") 
    {
     this.length        = this.windowWidth;
     this.depth         = 225;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";
.cols--color {
  flex-wrap: wrap;
}
.color__palette {
  max-width: 12.5%;
  min-width: 12.5%;
}
.color__palette {
  @media (max-width: $screen-xs-max) {
    min-width: 100%;
    max-width: 100%;
  }
}
.color__title {
  text-align: center;
}
.color__price {
  margin-top: 6px;
  text-align: center;
}
.color__inner {
  margin-left: 10px;
  margin-right: 10px;
  padding: 7px 7px 0 7px;
  border: 1px solid $border-grey;
}
.color__inner__image {
  position: relative;
  overflow: hidden;
  height: 120px;
  border: 1px solid $border-grey;
}
.selected .color__inner {
  border: 1px solid $blue;
}
.selected .color__price {
  background-color: $blue;
  color: $white;
  margin-left: -7px;
  margin-right: -7px;
  padding-left: 7px;
  padding-right: 7px;
}
.breadcrumbs-step-6 {
  .color__inner img {
    width: auto;
    max-height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
  }
}
</style>
