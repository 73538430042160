<template>
  <div class="container">
    <div class      	    = "accessoriesInfoIcon"
        @mousedown          = "showInfo('top', 'top', true)"
        @touchstart.prevent = "showInfo('top', 'top', true)"
        @mouseover          = "showInfo('top', 'top')"
        @mouseleave         = "closeInfo">
        <div class="infoTitle">Nápověda <div class="infoTitleString">Doplňky</div></div>
    </div>                
    <div class="sizeInfo" v-show="blockName == 'top' && isInfoVisible && infoAccessoryId == 'top'"
        @mouseover  = "showInfo('top', 'top')"
        @mouseleave = "closeInfo"
    >
	<span @click="closeInfo" @touch="closeInfo" class="icon icon-infoClose"></span>
	<div  class="accessoriesDetail" v-html="details"></div>
    </div>

    <div class="titleH1">
     <h2 class="headline--padded text-center" v-html="$t('accessories.title')"></h2>
    </div>
    <div class="cols padded--bottom justify-center wizard-accessories-main">
      <div v-for="(accessory, index) in accessoryTypes" v-bind:key="index" class="doplnky_face">
	
	<div class="tooltip profile__tooltip" v-if="accessory !== 'other'">
            <div class      	    = "profileInfoIcon"
                @mousedown     	    = "showInfo(1, accessoryTypes[index], true)" 
                @touchstart.prevent = "showInfo(1, accessoryTypes[index], true)" 
                @mouseover  	    = "showInfo(1, accessoryTypes[index])"
                @mouseleave 	    = "closeInfo">
	    </div>                
	    <div v-show="blockName == accessoryTypes[index] && isInfoVisible && infoAccessoryId == 1" class="accessoryInfo" 
                @mouseover  = "showInfo(1, accessoryTypes[index])" 
                @mouseleave = "closeInfo"
	       >
		<span @click="closeInfo" @touch="closeInfo" class="icon icon-infoClose"></span>	
		<div class="accessoryDetail" v-html="detailsText(accessory)"></div> 
	    </div>
        </div>

        <div v-if="!(accessory === 'handle' && !hasHandle(variation))">
          <h3 class="text-center color__face__title" v-html="accessoryTitle(accessory)"></h3>
	  
          <div class="color_face__preview accessory__preview" v-if="accessory !=='other'">
            <img :src="getStorageUrl(accessoryImage(accessory))">
          </div>

	  <div v-if="accessory==='other'">
            <div class="accessoryOtherDetails" v-html="detailsText(accessory)"></div>
	  </div>
	  <div v-if="accessory==='other' && accessoryProducts.other!==null">
	    <div class="accessoryOtherElement" v-for="(otherAcc, idx) in accessoryProducts.other.slice(1)" v-bind:key="idx">
	      
	      <img   class="accessoryOtherImg"      :src="getStorageUrl(otherAcc.image)">
	      <div   class="accessoryOtherName"     v-html="otherAcc.name"></div>
	      <input name="checkboxOther" class="accessoryOtherCheckbox" v-if="getOther(otherAcc.type, currentCartItem)"
	        v-bind:id="otherAcc.type" type="checkbox"
	        @click = "setOther(otherAcc.type, otherAcc)" checked="checked"/>
	      <input name="checkboxOther" class="accessoryOtherCheckbox" v-else
	        v-bind:id="otherAcc.type" type="checkbox"
	        @click = "setOther(otherAcc.type, otherAcc)"/>
	    
	    </div>
	  </div>          
          
          <div class="color_face__palette align-center justify-center accessory__color" v-if="selected(accessory) !== false && selected(accessory).color">
            <div class="color_face__palette__image accessory__color__preview" v-if="selected(accessory) && selected(accessory).color && selected(accessory).color.color && selected(accessory).color.color.image && selected(accessory).color.color.image.length">
              <img :src="getStorageUrl(selected(accessory).color.color.image)">
            </div>
            <div class="color_face__palette__image accessory__color__preview" v-else-if="selected(accessory) && selected(accessory).color && selected(accessory).color.image && selected(accessory).color.image.length">
              <img :src="getStorageUrl(selected(accessory).color.image)">
            </div>
            <div class="color_face__palette__about" v-if="selected(accessory) && selected(accessory).color">
              <span @click="removeAccessory(accessory)" class="icon icon-delete delete"></span>
              <h4 class="color_face__palette__title accessories_title" v-html="selected(accessory).color.name"></h4>
              <p class="color_face__palette__property accessories_property" v-if="selected(accessory).width"  v-html="buildSentence('width',  accessory)"></p>
              <p class="color_face__palette__property accessories_property" v-if="selected(accessory).height" v-html="buildSentence('height', accessory)"></p>
              <p class="color_face__palette__property accessories_property" v-if="selected(accessory).length" v-html="buildSentence('length', accessory)"></p>
              <p class="color_face__palette__property accessories_property" v-if="selected(accessory).depth"  v-html="buildSentence('depth',  accessory)"></p>
	      <p class="color_face__palette__property covers_property"      v-if="selected(accessory).color && selected(accessory).color.covers" v-html="$t(`accessories.property.covers`)"></p>
	      <div class="color_face__palette__price" v-if="accessory !== 'handle' && accessory !== 'blind'" v-html="$t(`priceNoVatPlus`, { value: currencyFormatNum(getPriceForInner(selected(accessory).color, selected(accessory).length)) })"></div>
              <div class="color_face__palette__price" v-else-if="accessory === 'handle'" v-html="$t(`priceNoVatPlus`, { value: currencyFormatNum(getInCzk(applyDiscount(selected(accessory).color.price, 'variations'), 'EUR')) } ) "></div>
              <div class="color_face__palette__price" v-else v-html="$t(`priceNoVatPlus`, { value: currencyFormatNum(getInCzk(selected(accessory).color.price, 'EUR')) })"></div>
            </div>
          </div>
          <div v-if="accessory !== 'other'">
            <button v-if="selected(accessory) !== false && selected(accessory).color" type="button" @click="pick(accessory)" class="btn btn--lg btn--primary btn--rounded" v-html="$t(`accessories.button_change`)"></button>
            <button v-else type="button" @click="pick(accessory)" class="btn btn--lg btn--primary btn--rounded" v-html="$t(`accessories.button`)"></button>
          </div>
        </div>
      </div>
    </div>

    <div class="stickyFooterAround">
      <div class="stickyFooter">
        <div class='helpFooter'>
<!--         <div class="helpImg" @click="helpText();"></div>
         <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div>
-->
             <div id="helpText" class="helpText" style="inline-block;"><span class="infoTel"><a href="tel:+420 776 327 222">+420 776 327 222</a></span> <br> <span class="infoMail"><a href="mailto:info@skladoken.cz">info@skladoken.cz</a></span></div>
        </div>
        <button @click="backButton" class="stickyButtonBack" v-html="$t('backButtonText')"></button>
        <button @click="confirm" class="stickyButton" v-html="$t('confirmAndContinue')"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "getCurrentCartField",
  mutationType: "updateCurrentCartField"
});
export default {
  name: "WizardAccessories",
  data() {
    return {
      accessoryTypes: [
        "inner",
        "outer",
        "other",
     // "blind",
     // "handle"
        ],
      isInfoVisible: false, 
      infoAccessoryId: "",
      blockName: ""
    };
  },
  computed: {
    ...mapState({
      accessoryProducts: state => state.accessories.products,
    }),
    ...mapFields("cart", [
      "size.width",
      "size.height",
      "variation",
      "accessories",
      "accessories.handle",
      "accessories.inner",
      "accessories.outer",      
      "accessories.other",
      "accessories.blind",
      "accessories.insectnet"
    ]),
    details() {
     if(this.$store.state.variations.variations[0])
      { 
       return this.$store.state.variations.variations[0].details_accessories; 
      }
     return false;
    },
  },
  methods: {
    showInfo(id, blockId, click)
     {
      if(this.isInfoVisible == false && this.infoAccessoryId == "" && this.blockName == "")
       {
        this.isInfoVisible   = true;
        this.infoAccessoryId = id;
	this.blockName	     = blockId;
       }
      else if (this.isInfoVisible == true && this.infoAccessoryId != "" && this.blockName !== "")
       {
	if(this.infoAccessoryId == id && this.blockName == blockId && click)
	 {
          this.isInfoVisible   = false;
          this.infoAccessoryId = "";
          this.blockName       = "";
	 }
	else
	 {
          this.isInfoVisible   = true;
          this.infoAccessoryId = id;
          this.blockName       = blockId;
	 }
       }
      else
       {
        this.isInfoVisible   = false;
	this.infoAccessoryId = "";
        this.blockName       = "";	
       }
     },
    closeInfo() 
     {
      this.isInfoVisible   = false;
      this.infoAccessoryId = "";
      this.blockName       = "";
     },
    removeAccessory(type) {
      this.accessories[type] = null;
    },
    buildSentence(property, accessory) {
      const value = this.selected(accessory)[property];
      return this.$t(`accessories.property.${property}`, { value });
    },
    selected(accessory) {
      if (typeof this[accessory] !== "undefined" && this[accessory] !== null) {
        return this[accessory];
      }
      return false;
    },
    pick(accessory) {
      this.$router.push({ name: "accessoriesPicker", params: { accessory } });
    },
    confirm() { 
      this.$router.push({ name: "order" });
    },
    backButton() {
      // this.accessories.handle.front    = null;
      // this.accessories.handle.back    = null;      
      // this.accessories.inner     = null;
      // this.accessories.outer     = null;
      // this.accessories.blind     = null;
      // this.accessories.other     = null;
      // this.accessories.insectnet = null;
      this.$router.push({ name: "glass" });
    },
    setOther(type, element)
     {
//      console.log(type);
//      console.log(element);
//      console.log(document.getElementById(type)); 
      let otherKey = false;

      var chkOther = document.getElementsByName('checkboxOther');
       for (var i=0; i<chkOther.length; i++) 
        { if(chkOther[i].checked == true) { otherKey = true; } }

      if(document.getElementById(type).checked) 
       { this.accessories[type] = element;}
      if(!document.getElementById(type).checked) 
       { this.accessories[type] = null; }
      if(otherKey)
       this.accessories.other = true;
      else
       this.accessories.other = null;
     },
    selectFirstHandle() {
//    console.log(this.handle);
      if (
        this.accessoryProducts.handle &&
        this.accessoryProducts.handle[1] &&
        this.handle === null
      ) {
        const product = this.accessoryProducts.handle[1];
        this.handle = {
          color: product
        };
      }
//      else {
//        const product = this.accessoryProducts.handle[this.handle.id];
//        this.handle = {
//          color: product
//         };
//       }
    },
  },
  mounted() {
    this.$store.dispatch("loadAccessories", { type: "handle", callback: () => { this.selectFirstHandle(); }});
    this.$store.dispatch("loadAccessories", { type:  "inner"});
    this.$store.dispatch("loadAccessories", { type:  "outer"});
    this.$store.dispatch("loadAccessories", { type:  "other"});
    this.$store.dispatch("loadCurrencies");
  }
};
</script>

<style lang="scss">
.color_face {
  max-width: 350px;
  text-align: center;
  margin: 0 25px;
}

.color_face__palette {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  text-align: left;
  & > * {
    flex: 1;
  }
}

.color_face__palette__image {
  max-width: 90px;
  text-align: center;
  img {
    max-height: 60px;
    max-width: 90px;
    height: 60px;
    width: auto;
  }
}

.color_face__palette__about {
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
  max-width: 140px;
  h4,
  strong {
    margin: 0;
    font-weight: 400;
  }
  h4 {
    margin-bottom: 20px;
  }
}

.color__face__title {
  font-weight: 400;
  margin-top: 5px;
}

.accessory__preview {
  height: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.cols--mini.cols--mini--accessory {
  max-width: 180px;
}

.accessories_property {
  margin: 0 0 5px;
  font-size: 12px;
}

.color_face__palette__title.accessories_title {
  margin: 0 0 5px;
}

.delete {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 10px;
  width: 15px;
  height: 15px;
}

.accessory__color__preview {
  height: 60px;
  overflow: hidden;
}

.accessory__color {
  height: 140px;
}
</style>
