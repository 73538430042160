<template>
  <div id="app" class="wrapper" :class="[`wizard-step-${$route.meta.step}`, `route-path-${$route.name}`]" v-if="started">
    <div id="menuBlock">
      <Header></Header>
      <Breadcrumbs></Breadcrumbs>
    </div>
    <div id="contentBlock">
     <router-view :class="routerCssClass"/>
    </div>
    <Summary></Summary>
    <Footer></Footer>
  </div>
</template>

<script>
import Header 		from "./components/partials/Header";
import Footer 		from "./components/partials/Footer";
import Breadcrumbs 	from "./components/partials/Breadcrumbs";
import Summary 		from "./components/partials/Summary";
import axios 		from 'axios';

export default {
  name: "app",
  components: {
    Header,
    Footer,
    Breadcrumbs,
    Summary
  },
  computed: {
    routerCssClass() {
      return "container";
    }
  },
  watch: {
   $route(to, from) {
      this.firstLoad = from.name == null ? true : false;
    }
  },
  created() {

    this.$store.dispatch("loadVariations");
    this.$store.dispatch("loadDiscounts");
    this.$store.dispatch("loadCurrencies");

    if(this.$route.name == 'home')
     {
      if (!this.currentCartItem) 
       { this.$store.dispatch("cart/addDefaultItem"); }
      else
       { 
        this.$store.commit('cart/removeCartItemAll'); 
        this.$store.dispatch("cart/addDefaultItem");
       }
     }
    if (typeof this.$route.query.configuration !== "undefined")
     { 
      this.$store.commit('cart/removeCartItemAll'); 
      this.$store.dispatch("cart/addDefaultItem");
      
      axios
      .get("https://www.skladoken.cz/index.php?fc=module&module=konfigproduct&controller=sendmail&konfigurator=" + this.$route.query.configuration)
      .then(response => {
              let objCart = JSON.parse(decodeURIComponent(escape(window.atob(response.data.replace(/\s/g, '')))));
              console.log(objCart);
              this.$store.state.cart.contents = objCart;
             })
      .catch(err => console.log(err));      
     }
  },
};
</script>

<style lang="scss">
@import "./assets/styles/variables.scss";
// @import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./assets/styles/style.scss";
</style>
