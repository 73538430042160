import axios from 'axios';

// initial state
const state = {
  catminmax: [],
  selected: null
};

// getters
const getters = {};

// actions
const actions = {
  loadCatMinMax({ commit }, callback) {
    axios.get('/wizard/public/storage/helper/assets/catminmax.json')
       .then(response => {
	commit('updateCatMinMax', response.data);
        if (typeof callback === 'function') {
          callback();
        }
      });
  }
};

// mutations
const mutations = {
  updateCatMinMax(state, catminmax) {
    if (typeof catminmax === 'object') {
      state.catminmax = Object.keys(catminmax).map(key => catminmax[key]);
    } else {
      state.catminmax = catminmax;
    }
  },
  setCatMinMaxLoaded(state) {
    state.loaded = true;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
