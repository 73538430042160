<template>
  <div class="container container-wizard">
    <canvas id="konfigurace"></canvas>
    <div class="textures">
      <img src="//konfigurator.skladoken.cz/static/colors/01_zlaty_dub.png" 	   id="zlaty-dub">
      <img src="//konfigurator.skladoken.cz/static/colors/02_tmavy_dub.png" 	   id="tmavy-dub">
      <img src="//konfigurator.skladoken.cz/static/colors/03_orech.png" 	   id="orech">
      <img src="//konfigurator.skladoken.cz/static/colors/04_cokoladova.png" 	   id="cokoladova">
      <img src="//konfigurator.skladoken.cz/static/colors/05_mahagon.png" 	   id="mahagon">
      <img src="//konfigurator.skladoken.cz/static/colors/06_svetle_seda.png" 	   id="svetle-seda">
      <img src="//konfigurator.skladoken.cz/static/colors/07_antracit.png" 	   id="antracit">
      <img src="//konfigurator.skladoken.cz/static/colors/08_kremova.png" 	   id="kremova">
      <img src="//konfigurator.skladoken.cz/static/colors/09_cervena.png" 	   id="cervena">
      <img src="//konfigurator.skladoken.cz/static/colors/10_svetle_modra.png"    id="svetle-modra">
      <img src="//konfigurator.skladoken.cz/static/colors/11_tmave_modra.png" 	   id="tmave-modra">
      <img src="//konfigurator.skladoken.cz/static/colors/12_zelena.png" 	   id="zelena">
      <img src="//konfigurator.skladoken.cz/static/colors/13_seda_hladka.png" 	   id="seda-hladka">
      <img src="//konfigurator.skladoken.cz/static/colors/14_oregon.png" 	   id="oregon">
      <img src="//konfigurator.skladoken.cz/static/colors/15_winchester.png" 	   id="winchester">
      <img src="//konfigurator.skladoken.cz/static/colors/16_tresen.png" 	   id="tresen">
      <img src="//konfigurator.skladoken.cz/static/colors/17_antracit_hladky.png" id="antracit-hladky">
    </div>
    <div class="control">
      <h2>Generátor náhledu oken</h2>
      <div v-for="(okno, key) in okna"
      class="control__okno"
      :key="`okno-${key}`">
        <h3>Okno {{ (key + 1) }}</h3>
        <div class="control__group">
          <div class="control__label">
            Šířka rámu:
          </div>
          <div class="control__value">
            <input v-model="okno.sirka_ramu" type="range" min="0" max="50" step="1">
          </div>
          <div class="control__help">
            {{ okno.sirka_ramu }}
          </div>
        </div>
        <div class="control__group">
          <div class="control__label">
            Šířka:
          </div>
          <div class="control__value">
            <input v-model="okno.sirka" type="range" min="500" max="3500" step="1">
          </div>
          <div class="control__help">
            {{ okno.sirka }}
          </div>
        </div>
        <div class="control__group">
          <div class="control__label">
            Výška:
          </div>
          <div class="control__value">
            <input v-model="okno.vyska" type="range" min="500" max="3500" step="1">
          </div>
          <div class="control__help">
            {{ okno.vyska }}
          </div>
        </div>
        <div class="control__group">
          <div class="control__label">
            Počet mříží vertikálních:
          </div>
          <div class="control__value">
            <input v-model="okno.pocetMriziVertikalnich" type="range" min="0" max="5" step="1">
          </div>
          <div class="control__help">
            {{ okno.pocetMriziVertikalnich }}
          </div>
        </div>
        <div class="control__group">
          <div class="control__label">
            Počet mříží horizontálních:
          </div>
          <div class="control__value">
            <input v-model="okno.pocetMriziHorizontalnich" type="range" min="0" max="5" step="1">
          </div>
          <div class="control__help">
            {{ okno.pocetMriziHorizontalnich }}
          </div>
        </div>
        <div class="control__group">
          <div class="control__label">
            Otevírání levé:
          </div>
          <div class="control__value">
            <select v-model="okno.otvirani.left">
              <option value="1">Ano</option>
              <option value="0">Ne</option>
            </select>
          </div>
          <div class="control__help"></div>
        </div>
        <div class="control__group">
          <div class="control__label">
            Otevírání pravé:
          </div>
          <div class="control__value">
            <select v-model="okno.otvirani.right">
              <option value="1">Ano</option>
              <option value="0">Ne</option>
            </select>
          </div>
          <div class="control__help"></div>
        </div>
        <div class="control__group">
          <div class="control__label">
            Otevírání horní:
          </div>
          <div class="control__value">
            <select v-model="okno.otvirani.top">
              <option value="1">Ano</option>
              <option value="0">Ne</option>
            </select>
          </div>
          <div class="control__help"></div>
        </div>
        <div class="control__group">
          <div class="control__label">
            Otevírání dolní:
          </div>
          <div class="control__value">
            <select v-model="okno.otvirani.bottom">
              <option value="1">Ano</option>
              <option value="0">Ne</option>
            </select>
          </div>
          <div class="control__help"></div>
        </div>
        <div class="control__textures">
          <div v-for="(texture, key) in textures"
            :key="`texture-${key}`"
            class="texture"
            :class="[{ 'active' : key === okno.texture }]"
            @click="okno.texture = key">
            <img :src="texture.src">
          </div>
        </div>
      </div>
      <button type="button"
        @click="add"
        class="btn">
        Přidat nové okno
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Generator",
  mounted() {
    this.canvas = document.getElementById(this.canvasId);
    this.recountCanvasSize();
    this.ctx = this.canvas.getContext("2d");
    this.draw();
  },
  watch: {
    okna: {
      handler() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.recountStartX();
        this.recountCanvasSize();
        this.draw();
      },
      deep: true
    }
  },
  methods: {
    recountStartX() {
      let startX = 0;
      /* eslint-disable */
      this.okna.forEach(element => {
        element.startX = startX;
        startX += element.sirka / 10;
      });
      /* eslint-enable */
    },
    recountCanvasSize() {
      let canvasWidth = 0;
      let canvasHeight = 0;
      this.okna.forEach(element => {
        canvasWidth += element.sirka / 10;
        if (element.vyska / 10 > canvasHeight) {
          canvasHeight = element.vyska / 10;
        }
      });
      this.canvas.width = canvasWidth;
      this.canvas.height = canvasHeight;
    },
    add() {
      this.okna.push({
        sirka_ramu: 14, // px
        startX: 100,
        startY: 0,
        sirka: 800, // mm
        vyska: 1800, // mm
        otvirani: {
          top: 1,
          right: 1,
          bottom: 0,
          left: 0
        },
        pocetMriziVertikalnich: 3,
        pocetMriziHorizontalnich: 2,
        sirkaMrizi: 4, // px
        sitka: 0, // 0/1
        zaluzie: 0, // 0/1
        pricka: 0 // 0/1
      });
    },
    draw() {
      this.okna.forEach(element => {
        this.drawSingle(element);
      });
    },
    /* eslint-disable */
    drawSingle(element) {
      // Draw border
      this.ctx.fillStyle = this.colors.borderColor;
      this.ctx.fillRect(
        element.startX,
        element.startY,
        element.sirka / 10,
        element.vyska / 10
      );

      // Draw ram
      this.ctx.fillStyle = this.colors.ramColor;

      if (element.texture) {
        const img = document.getElementById(this.textures[element.texture].id);
        const pat = this.ctx.createPattern(img, "repeat");
        this.ctx.fillStyle = pat;
      }

      this.ctx.fillRect(
        element.startX + 1,
        element.startY + 1,
        element.sirka / 10 - 2,
        element.vyska / 10 - 2
      );

      // Draw ram inner - outer borders
      this.ctx.fillStyle = this.colors.borderColor;
      this.ctx.fillRect(
        element.startX + 3,
        element.startY + 2.5,
        element.sirka / 10 - 6,
        0.5
      );

      this.ctx.fillStyle = this.colors.borderColor;
      this.ctx.fillRect(
        element.startX + 3,
        element.startY + element.vyska / 10 - 3,
        element.sirka / 10 - 6,
        0.5
      );

      this.ctx.fillStyle = this.colors.borderColor;
      this.ctx.fillRect(
        element.startX + 2.5,
        element.startY + 2.5,
        0.5,
        element.vyska / 10 - 6
      );

      this.ctx.fillStyle = this.colors.borderColor;
      this.ctx.fillRect(
        element.startX + element.sirka / 10 - 2.5,
        element.startY + 2.5,
        0.5,
        element.vyska / 10 - 6
      );

      // Draw ram inner - inner borders
      this.ctx.fillStyle = this.colors.borderColor;
      this.ctx.fillRect(
        element.startX + parseInt(element.sirka_ramu, 10) - 2,
        element.startY + parseInt(element.sirka_ramu, 10) - 2,
        element.sirka / 10 - parseInt(element.sirka_ramu, 10) * 2 + 4,
        0.5
      );

      this.ctx.fillStyle = this.colors.borderColor;
      this.ctx.fillRect(
        element.startX + parseInt(element.sirka_ramu, 10) - 2,
        element.startY +
          element.vyska / 10 -
          parseInt(element.sirka_ramu, 10) +
          1,
        element.sirka / 10 - parseInt(element.sirka_ramu, 10) * 2 + 4,
        0.5
      );

      this.ctx.fillStyle = this.colors.borderColor;
      this.ctx.fillRect(
        element.startX + parseInt(element.sirka_ramu, 10) - 2,
        element.startY + parseInt(element.sirka_ramu, 10) - 2,
        0.5,
        element.vyska / 10 - parseInt(element.sirka_ramu, 10) * 2 + 4
      );

      this.ctx.fillStyle = this.colors.borderColor;
      this.ctx.fillRect(
        element.startX +
          element.sirka / 10 -
          parseInt(element.sirka_ramu, 10) +
          1,
        element.startY + parseInt(element.sirka_ramu, 10) - 2,
        0.5,
        element.vyska / 10 - parseInt(element.sirka_ramu, 10) * 2 + 4
      );

      // Draw inner ram
      this.ctx.fillStyle = this.colors.borderColor;
      this.ctx.fillRect(
        element.startX + parseInt(element.sirka_ramu, 10),
        element.startY + parseInt(element.sirka_ramu, 10),
        element.sirka / 10 - parseInt(element.sirka_ramu, 10) * 2,
        element.vyska / 10 - parseInt(element.sirka_ramu, 10) * 2
      );

      // Draw glass
      this.ctx.fillStyle = this.colors.glassColor;
      this.ctx.fillRect(
        element.startX + parseInt(element.sirka_ramu, 10) + 1,
        element.startY + parseInt(element.sirka_ramu, 10) + 1,
        element.sirka / 10 - parseInt(element.sirka_ramu, 10) * 2 - 2,
        element.vyska / 10 - parseInt(element.sirka_ramu, 10) * 2 - 2
      );

      // Draw klika border
      this.ctx.fillStyle = this.colors.borderColor;
      const sirkaKliky = parseInt(element.sirka_ramu, 10) / 2;
      /* let vyskaKliky = element.vyska / 10 / 5;
      if (element.sirka > element.vyska) {
        vyskaKliky = element.vyska / 10 / 3;
      } */
      const vyskaKliky = 20;
      this.ctx.fillRect(
        element.startX +
          (element.sirka / 10 - parseInt(element.sirka_ramu, 10)) +
          parseInt(element.sirka_ramu, 10) / 4,
        element.startY + element.vyska / 10 / 2 - vyskaKliky / 2,
        sirkaKliky,
        vyskaKliky
      );

      // Draw klika
      this.ctx.fillStyle = this.colors.ramColor;
      this.ctx.fillRect(
        element.startX +
          (element.sirka / 10 - parseInt(element.sirka_ramu, 10)) +
          parseInt(element.sirka_ramu, 10) / 4 +
          1,
        element.startY + element.vyska / 10 / 2 - vyskaKliky / 2 + 1,
        sirkaKliky - 2,
        vyskaKliky - 2
      );

      // Draw mrize
      const glassStartX = element.startX + parseInt(element.sirka_ramu, 10) + 1;
      const glassStartY = element.startY + parseInt(element.sirka_ramu, 10) + 1;
      const glassWidth =
        element.sirka / 10 - parseInt(element.sirka_ramu, 10) * 2 - 2;
      const glassHeight =
        element.vyska / 10 - parseInt(element.sirka_ramu, 10) * 2 - 2;
      this.ctx.fillStyle = this.colors.mrizeColor;
      if (element.pocetMriziVertikalnich > 0) {
        const segmentWidth = glassWidth / element.pocetMriziVertikalnich;
        for (let i = 0; i < element.pocetMriziVertikalnich; i++) {
          const mrizePoziceX =
            glassStartX +
            segmentWidth * i +
            segmentWidth / 2 -
            element.sirkaMrizi / 2;

          this.ctx.fillRect(
            mrizePoziceX,
            glassStartY,
            element.sirkaMrizi,
            glassHeight
          );
        }
      }

      if (element.pocetMriziHorizontalnich > 0) {
        const segmentHeight = glassHeight / element.pocetMriziHorizontalnich;
        for (let i = 0; i < element.pocetMriziHorizontalnich; i++) {
          const mrizePoziceY =
            glassStartY +
            segmentHeight * i +
            segmentHeight / 2 -
            element.sirkaMrizi / 2;

          this.ctx.fillRect(
            glassStartX,
            mrizePoziceY,
            glassWidth,
            element.sirkaMrizi
          );
        }
      }

      // Draw smer otevirani
      // Right
      if (parseInt(element.otvirani.right, 10)) {
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.colors.borderColor;
        this.ctx.lineWidth = 0.5;
        this.ctx.moveTo(glassStartX, glassStartY);
        this.ctx.lineTo(
          glassStartX + glassWidth,
          glassStartY + glassHeight / 2
        );
        this.ctx.lineTo(glassStartX, glassStartY + glassHeight);
        this.ctx.stroke();
      }

      // Top
      if (parseInt(element.otvirani.top, 10)) {
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.colors.borderColor;
        this.ctx.lineWidth = 0.5;
        this.ctx.moveTo(glassStartX, glassStartY + glassHeight);
        this.ctx.lineTo(glassStartX + glassWidth / 2, glassStartY);
        this.ctx.lineTo(glassStartX + glassWidth, glassStartY + glassHeight);
        this.ctx.stroke();
      }

      // Left
      if (parseInt(element.otvirani.left, 10)) {
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.colors.borderColor;
        this.ctx.lineWidth = 0.5;
        this.ctx.moveTo(glassStartX + glassWidth, glassStartY);
        this.ctx.lineTo(glassStartX, glassStartY + glassHeight / 2);
        this.ctx.lineTo(glassStartX + glassWidth, glassStartY + glassHeight);
        this.ctx.stroke();
      }

      // Bottom
      if (parseInt(element.otvirani.bottom, 10)) {
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.colors.borderColor;
        this.ctx.lineWidth = 0.5;
        this.ctx.moveTo(glassStartX, glassStartY);
        this.ctx.lineTo(
          glassStartX + glassWidth / 2,
          glassStartY + glassHeight
        );
        this.ctx.lineTo(glassStartX + glassWidth, glassStartY);
        this.ctx.stroke();
      }
    }
    /* eslint-enable */
  },
  data() {
    return {
      canvasId: "konfigurace",
      ctx: null,
      canvas: null,
      settings: {
        velikost: 200
      },
      colors: {
        borderColor: "#8b8b8b",
        ramColor: "#f4f3f4",
        glassColor: "#bbeefc",
        mrizeColor: "#f7f7f7"
      },
      textures: [
        {
          src: "//konfigurator.skladoken.cz/static/colors/00_bila.png",
          id: "bila"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/01_zlaty_dub.png",
          id: "zlaty-dub"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/02_tmavy_dub.png",
          id: "tmavy-dub"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/03_orech.png",
          id: "orech"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/04_cokoladova.png",
          id: "cokoladova"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/05_mahagon.png",
          id: "mahagon"
        },
        {
          src:
            "//konfigurator.skladoken.cz/static/colors/06_svetle_seda.png",
          id: "svetle-seda"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/07_antracit.png",
          id: "antracit"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/08_kremova.png",
          id: "kremova"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/09_cervena.png",
          id: "cervena"
        },
        {
          src:
            "//konfigurator.skladoken.cz/static/colors/10_svetle_modra.png",
          id: "svetle-modra"
        },
        {
          src:
            "//konfigurator.skladoken.cz/static/colors/11_tmave_modra.png",
          id: "tmave-modra"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/12_zelena.png",
          id: "zelena"
        },
        {
          src:
            "//konfigurator.skladoken.cz/static/colors/13_seda_hladka.png",
          id: "seda-hladka"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/14_oregon.png",
          id: "oregon"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/15_winchester.png",
          id: "winchester"
        },
        {
          src: "//konfigurator.skladoken.cz/static/colors/16_tresen.png",
          id: "tresen"
        },
        {
          src:
            "//konfigurator.skladoken.cz/static/colors/17_antracit_hladky.png",
          id: "antracit-hladky"
        }
      ],
      okna: [
        {
          sirka_ramu: 14, // px
          startX: 0,
          startY: 0,
          sirka: 1000, // mm
          vyska: 600, // mm
          // otvirani: 'RT', // trbl
          otvirani: {
            top: 1,
            right: 1,
            bottom: 0,
            left: 0
          },
          pocetMriziVertikalnich: 3,
          pocetMriziHorizontalnich: 2,
          sirkaMrizi: 4, // px
          sitka: 1, // 0/1
          zaluzie: 1, // 0/1
          pricka: 0, // 0/1
          texture: 0
        },
        {
          sirka_ramu: 14, // px
          startX: 100,
          startY: 0,
          sirka: 800, // mm
          vyska: 1800, // mm
          // otvirani: 'RT', // trbl
          otvirani: {
            top: 1,
            right: 1,
            bottom: 0,
            left: 0
          },
          pocetMriziVertikalnich: 3,
          pocetMriziHorizontalnich: 2,
          sirkaMrizi: 4, // px
          sitka: 0, // 0/1
          zaluzie: 0, // 0/1
          pricka: 0, // 0/1
          texture: 0
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.control {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  border-left: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

#konfigurace {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.control__group {
  display: flex;
  width: 100%;
  & > div {
    flex: 1;
  }
  padding-top: 10px;
  padding-bottom: 10px;
  .control__label {
    padding-right: 10px;
    font-weight: bold;
    min-width: 100px;
  }
  .control__help {
    max-width: 60px;
    padding-left: 10px;
    color: #ccc;
  }
  .control__value input {
    width: 100%;
  }
}

.control .btn {
  width: 100%;
  transition: all 0.3s;
  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.textures {
  display: none;
}

.control__okno {
  border: 1px solid #eee;
  padding: 0 10px 10px 10px;
  margin-bottom: 10px;
}

.control__textures {
  display: flex;
  flex-wrap: wrap;
  .texture {
    flex: 1;
    width: 20%;
    max-width: 20%;
    min-width: 20%;
    padding: 2px;
    border: 1px solid #eee;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
    }
    &:hover,
    &.active {
      border: 1px solid #999;
    }
  }
}
</style>
