import axios from 'axios';

// initial state
const state = {
  colors: [],
  front: null,
  back: null
};

// getters
const getters = {};

// actions
const actions = {
  loadColors({ commit }, callback) {
    axios
      .get(
        '/wizard/public/storage/colors/colors.json'
      )
      .then(response => {
        commit('updateColors', response.data);
        if (typeof callback === 'function') {
          callback();
        }
      });
  },
  selectColor({ commit }, { color, face }) {
    commit('selectColor', color, face);
  }
};

// mutations
const mutations = {
  updateColors(state, colors) {
    var colorsArray = colors;

    if (typeof colorsArray === 'object') {
      colorsArray = Object.values(colors);
    }

    var colorsByPosition = colorsArray.slice(0);

    colorsByPosition.sort(function(a,b) {
      let x = parseInt(a.position);
      let y = parseInt(b.position);

      return x < y ? -1 : x > y ? 1 : 0;
    });

    //state.colors = colors;
    state.colors = colorsByPosition;
  },
  selectColor(state, { color, face }) {
    state[face] = color;
  },
  resetColors(state) {
    state.colors = [];
    state.front = null;
    state.back = null;
  },
  setColors(state, options) {
    state.colors = options.colors;
    state.front = options.front;
    state.back = options.back;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
