<template>
  <div class="container">
    <div v-show="errorMsg" class="errorAround" @click="closeError"><div class='errorShadow'></div><div class="errorWindow"><div class="cartItemDelete" @click="closeError"></div><div id="errorText" class="errorText"></div></div></div>
    <div class = "sizeInfoIcon"
        @mousedown          = "showInfo('top', true)"
        @touchstart.prevent = "showInfo('top', true)"
        @mouseover          = "showInfo('top')"
        @mouseleave         = "closeInfo">                                                                    
        <div class="infoTitle">Nápověda <div class="infoTitleString">Profil</div></div>
    </div>                
    <div class="sizeInfo" v-show = "isInfoVisible && infoProfileId == 'top'"
        @mouseover  = "showInfo('top')"
        @mouseleave = "closeInfo"
    > 
        <span @click="closeInfo" @touch="closeInfo" class="icon icon-infoClose"></span>
        <div  class="colorDetail" v-html="details"></div>
    </div>    
    <div class="titleH1">
     <h2 class="headline--padded text-center" v-html="$t('profileTitle')"></h2>
    </div>
    <div class='profileSecondHeadLine' v-if="typeof currentCartItem !=='undefined' && typeof currentCartItem.category !=='undefined' && currentCartItem.category.id === '3'">
    U vedlejších vchodových dveří jsou <strong>profily osazeny nízkým hliníkovým práhem 20 mm</strong>.
    </div>
    <div class='profileSecondHeadLine' v-if="typeof currentCartItem !=='undefined' && typeof currentCartItem.category !=='undefined' && currentCartItem.category.id === '4'">
    U hlavních vchodových dveří jsou <strong>profily osazeny nízkým hliníkovým práhem 20 mm</strong>.
    </div>
    <div class="cols padded--bottom justify-center wizard-profile-main">
      <div v-for="(profile, index) in profiles"
      v-bind:key="index"
      v-show="isExisting(profile)"
      class="profile"
      :class="[{ 'selected' : isSelected(profile), 'disabled' : ! isExisting(profile)}]"
      @click="select(profile)">
        <div class="profile__inner">
          <div class="profile__about">
            <div class="profile__title">
              {{ profile.name }}
            </div>

            <div class              = "profileInfoIcon"
                @mousedown          = "showInfo(profile.id, true)" 
                @touchstart.prevent = "showInfo(profile.id, true)" 
                @mouseover          = "showInfo(profile.id)"
                @mouseleave	    = "closeInfo"
               >
	    </div>                
	    <div v-show     = "isInfoVisible && infoProfileId == profile.id" class="profileInfo"
                @mouseover  = "showInfo(profile.id)" 
                @mouseleave = "closeInfo"
               >
	      <span @click  = "closeInfo" @touch="closeInfo" class="icon icon-infoClose"></span>
	      <div class="profileDetail" v-html="profile.details"></div>
	    </div>

            <div class="profile__image">
              <img :src="getStorageUrl(profile.image)">
            </div>
	    <div class="profile__price price price--novat" v-html="$t(`priceString`, { value: getPriceForProfile(profile, 'wizardProfile') })"></div>
          </div>
          <div class="profile__info">
            <div class="profile__property">
              <div class="profile__icon"><i class="icon icon-prostup_tepla"></i></div>
              <div class="profile__label" v-html="$t('propustnost')"></div>
              <div class="profile__value" v-html="profile.propustnost"></div>
            </div>
            <div class="profile__property">
              <div class="profile__icon"><i class="icon icon-stavebni_hloubka"></i></div>
              <div class="profile__label" v-html="$t('hloubka')"></div>
              <div class="profile__value" v-html="profile.hloubka"></div>
            </div>
            <div class="profile__property">
              <div class="profile__icon"><i class="icon icon-pocet_komor"></i></div>
              <div class="profile__label" v-html="$t('komory')"></div>
              <div class="profile__value" v-html="profile.komory"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="stickyFooterAround">
      <div class="stickyFooter">
        <div class='helpFooter'>
<!--         <div class="helpImg" @click="helpText();"></div>
         <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div>
-->
             <div id="helpText" class="helpText" style="inline-block;"><span class="infoTel"><a href="tel:+420 776 327 222">+420 776 327 222</a></span> <br> <span class="infoMail"><a href="mailto:info@skladoken.cz">info@skladoken.cz</a></span></div>
        </div>
        
        <button
        @click="backButton"
        class="stickyButtonBack"
        v-html="$t('backButtonText')"></button>

        <button
        @click="confirm"
        class="stickyButton"
        :class="[{ 'disabled' : !isConfirmReady() }]"
        v-html="$t('confirmAndContinue')"></button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "getCurrentCartField",
  mutationType: "updateCurrentCartField"
});

export default {
  name: "WizardProfile",
  data() {
    return { 
      isInfoVisible: false, 
      infoProfileId: "",
      errorMsg: false
    };
  },
  computed: {
    ...mapFields("cart", [
      "width",
      "height",
      "variation",
      "category",
      "profile"
    ]),
    ...mapState({
//    profiles: state => state.profiles.profiles.slice(1)
      profiles: state => state.profiles.profiles
    }),
    details() {
     if(this.$store.state.profiles.profiles[0])
      { 
       return this.$store.state.profiles.profiles[0].details; 
      }
     return false;
    },
  },
  methods: {
    showInfo(id, click)
     {
      if(this.isInfoVisible == false && this.infoProfileId == "")
       {
        this.isInfoVisible  = true;
        this.infoProfileId = id;
       }
      else if (this.isInfoVisible == true && this.infoProfileId != "")
       {
        if (this.infoProfileId == id && click)
         {
          this.isInfoVisible  = false;
          this.infoProfileId  = "";
         }
        else
         {
          this.isInfoVisible  = true;
          this.infoProfileId = id;
         }
       }
      else
       {
        this.isInfoVisible  = false;
	this.infoProfileId = "";
       }
     },
    closeInfo() 
     {
      this.isInfoVisible = false;
      this.infoProfileId = "";
     },
    getPriceForProfile(profile, source) {
//      console.log(profile.id);
      if (typeof profile === 'undefined' || profile === null)
       { return 0.0; }
      if ( ! this.isExisting(profile) ) 
       { return 0.0; }

      // console.log(this.getProductPrice(this.variation, profile, this.profiles) + ' - ' + this.getProductBasePrice(this.variation, this.profiles));
      // return this.getProductPrice(this.variation, profile, this.profiles) - this.getProductBasePrice(this.variation, this.profiles);

      let outPriceString = '';
      let outPriceValue   = 0; 

      if (this.profile !== null && (this.profile.id === profile.id))
       {
        //console.log('if: ' + this.getProductPrice(this.variation, profile, this.profiles) + ' - ' + this.getProductPrice(this.variation, profile));
        outPriceValue = this.getProductPrice(this.variation, profile, this.profiles, 0.0, null, null, null, source) - this.getProductPrice(this.variation, profile, null, 0.0, null, null, null, source);
	
	if (outPriceValue > 0)       { outPriceString = '+' + outPriceValue.toString();}
	else                         { outPriceString =       outPriceValue.toString();}

        outPriceString = 'Vybráno';
        return outPriceString;
       }
      else
       {
//console.log(this.variation.profiles);
//console.log(profile);
//console.log(this.profile);
//console.log(this.profiles);
	if(this.profile !== null)
	 {
          if((this.variation.discount[this.profile.id] === null || this.variation.profit[this.profile.id] === null) && this.profiles !== null)
           {
	    // let profileId  = Object.keys(this.variation.profiles)[0];
            this.profile   = profile; 
            outPriceString = 'Vybráno';
            return outPriceString;
	   }
         }
        else
         {
	  let profileId  = Object.keys(this.variation.profiles)[0];
          this.profile   = this.profiles[profileId]; 
          outPriceString = 'Vybráno';
          return outPriceString;
         }
        //console.log('else: ' + this.getProductPrice(this.variation, profile, this.profiles) + ' - ' + this.getProductPrice(this.variation, this.profile));
        outPriceValue = this.getProductPrice(this.variation, profile, this.profiles, 0.0, null, null, null, source) - this.getProductPrice(this.variation, this.profile, this.profiles, 0.0, null, null, null, source);
	if (outPriceValue > 0)       { outPriceString = '+' + outPriceValue.toString(); }
	else                         { outPriceString =       outPriceValue.toString(); }

        return outPriceString + " Kč <span class='bezDPH'>bez DPH</span>";
       }
      // console.log(outPriceValue + ' ' + outPriceString);
    },
    isExisting(profile) {
      if (typeof this.variation.profiles === "undefined" || this.variation.profiles === null) {
        return;
      }
      let profile_id = parseInt( profile.id );
      return typeof this.variation.profiles[profile_id] !== "undefined";
    },
    isEligible() {
      return this.width !== "" && this.height !== "";
    },
    isSelected(profile) {
      if (this.profile === null) {
        return false;
      }
      return this.profile.name === profile.name;
    },
    select(profile) {
      if ( this.isExisting(profile) ) {
        this.profile = profile;
      }
    },
    isConfirmReady() {
      return this.profile !== null;
    },
    confirm() {
      let element = document.getElementsByClassName('stickyButton')[0];
      if(element.classList.contains('disabled'))
       { 
        document.getElementById("errorText").innerHTML ="";
        this.errorMsg = true; 
        document.getElementById("errorText").innerHTML ="Vyberte profil";
       }
      if (this.isConfirmReady()) {
        this.$router.push({ name: "color" });
      }
    },
    closeError() {
      document.getElementById("errorText").innerHTML ="";
      this.errorMsg = false;
    },
    backButton() {
         this.profile = null;
         this.$router.push({ name: "variation" });
    },
    selectFirstProfile() {
      var profilesArray = [];
      if (this.profile === null) {

        if (typeof this.profiles === 'object') {
          profilesArray = Object.values(this.profiles);
        } else {
          profilesArray = this.profiles;
        }
        profilesArray.forEach(profile => {
          if ( profile.id == 1 ) {
            this.select(profile);
          }
        });
      }
     else
      {
       // if exist only one profile for product and it is not first profile
       let firstKey = Object.keys(this.variation.profiles)[0];
       if (typeof this.profiles === 'object') {
          profilesArray = Object.values(this.profiles);
        } else {
          profilesArray = this.profiles;
        }
       profilesArray.forEach(profile => { if(profile.id == firstKey) { this.select(profile); } });
      }
    }
  },
  mounted() {
    this.$store.dispatch("loadProfiles", () => {
      this.selectFirstProfile();
    });
  }
};
</script>

<style lang="scss">
</style>
