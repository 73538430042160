<template>
  <nav class="breadcrumbs"
  :class="[`breadcrumbs-step-${$route.meta.step}`, { 'expanded' : expanded }]"
    @click="expanded = !expanded; stepMenu();"
     v-if="$route.meta && $route.meta.generator !== true">
    <div class="container">
      <ul>
        <li id='mobileBreadcrumbsTop' class='mobileBreadcrumbsOff'>Kroky kalkulace</li>
        <li
        v-for="(step, index) in steps"
        v-bind:key="index"
        :class="[{ 'last' : $route.meta.step === index + 2,
        'done' : $route.meta.step > index + 1,
        'active' : $route.meta.step === index + 1 }]"
        @click="navigateTo(step, index)">
          <i class="icon check"></i><span class="label" v-html="step.name"></span>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
window.onscroll = function()
 {
  var scrolled = window.pageYOffset || document.documentElement.scrollTop;
  if (scrolled > 107) // menu
   {
    document.getElementById('menuBlock').style.position      = 'fixed';
    document.getElementById('menuBlock').style.top           = '-107px';
    document.getElementById('menuBlock').style.boxShadow     = '0px 3px 10px 0px rgba(0,0,0,0.2)';
    document.getElementById('contentBlock').style.marginTop  = '144px';
   }
  else
   {
    document.getElementById('menuBlock').style.position      = 'static';
    document.getElementById('menuBlock').style.top           = 'auto';
    document.getElementById('menuBlock').style.boxShadow     = 'none';
    document.getElementById('contentBlock').style.marginTop  = '0px';
   }
 }
export default {
  name: "Breadcrumbs",
  data() {
    return {
      expanded: false,
      steps: [
        {
          name: "1. Kategorie",
          path: "home"
        },
        {
          name: "2. Rozměry a typ",
          path: "size"
        },
        {
          name: "3. Profil",
          path: "profile"
        },
        {
          name: "4. Barva",
          path: "color"
        },
        {
          name: "5. Sklo",
          path: "glass"
        },
        {
          name: "6. Doplňky",
          path: "accessories"
        },
        {
          name: "7. Rekapitulace",
          path: "order"
        }
      ]
    };
  },
  methods: {
    navigateTo(step, index) {
      if (this.$route.meta.step > index) 
       {
//        console.log(step.path);

        if(step.path == 'home')
         {
	  location.href = '/';
	 }
	else
	 {
          this.$router.push({ name: step.path }).catch(()=>{});
	 }
/*
          console.log(this);
// accessories
	  if(this.accessories)
	   {
	    this.accessories.handle.front      = null;
	    this.accessories.handle.back       = null;
            this.accessories.inner             = null;
            this.accessories.outer             = null;
            this.accessories.blind             = null;
            this.accessories.insectnet         = null;
            this.accessories.cover.front       = null;
            this.accessories.cover.back        = null;
            this.accessories.coverdrain.front  = null;
            this.accessories.coverdrain.back   = null;
           }
// glass        
          this.selected = null;
// color
	  if(this.windowfaces)
	   {
            this.windowfaces.front = null;
            this.windowfaces.back = null;
           }
// variations
    	  this.variation = {}; // clear object
          this.variation.type   = "";
          this.variation.price  = "";
          this.variation.width  = "";
          this.variation.height = "";
//size
          this.width  = "";
          this.height = "";
*/
	


       }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";

$breadcrumbs-height: 40px;

.breadcrumbs {
  font-size: 11px;
  position: relative;
  background: $light;
  color: $grey;
  height: $breadcrumbs-height;
  line-height: $breadcrumbs-height;
}

.breadcrumbs:before {
  position: absolute;
  content: "";
  display: none;
  width: 50vw;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: $blue;
}

.breadcrumbs-step-1:before {
  display: block;
}

.breadcrumbs-step-7:before {
  display: block;
  left: auto;
  right: 0;
}

.breadcrumbs .container {
  position: relative;
}

.breadcrumbs ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;

  @media (max-width: $screen-sm-max) {
    flex-direction: column;
  }
}

.breadcrumbs ul li {
  flex: 1;
  background: $light;
  position: relative;
  padding-left: 40px;
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.breadcrumbs.expanded ul li {
  @media (max-width: $screen-sm-max) {
    display: block;
  }
}

.wrapper > .breadcrumbs.expanded {
  @media (max-width: $screen-sm-max) {
    max-height: 100vh;
    height: auto;
  }
}

.breadcrumbs ul li .icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid $grey;
  border-radius: 50%;
  z-index: 2;
}

.breadcrumbs ul li:after {
  content: "";
  display: none;
  width: $breadcrumbs-height * 0.7;
  height: $breadcrumbs-height * 0.7;
  position: absolute;
  top: 6px;
  right: 0;
  transform: rotate(45deg);
  background: $light;
  z-index: 2;
}

.breadcrumbs ul li .label {
  position: relative;
  z-index: 3;
}

.breadcrumbs ul li.done {
  cursor: pointer;
}

.breadcrumbs ul li.done:hover {
  text-decoration: underline;
}

.breadcrumbs ul li.active {
  cursor: auto;
}

.breadcrumbs ul li.active:hover {
  text-decoration: none;
}

.breadcrumbs ul li.active {
  background: $blue;
  color: $white;
  @media (max-width: $screen-sm-max) {
    display: block;
  }
}

.breadcrumbs ul li:before {
  content: "";
  display: block;
  width: 12px;
  height: 24px;
  background-image: url(../../assets/images/breadcrumbs_arrow.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 2;
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

// .breadcrumbs ul li.done:before,
.breadcrumbs ul li.last:before,
.breadcrumbs ul li:last-child:before {
  display: none;
}

.breadcrumbs ul li.last:after,
.breadcrumbs ul li.active:before {
  content: "";
  display: block;
  width: $breadcrumbs-height * 0.5;
  height: $breadcrumbs-height;
  position: absolute;
  top: 0;
  right: 0;
  background: $blue;
  transform: none;
  z-index: 0;
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.breadcrumbs ul li.active:before {
  background: $light;
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.breadcrumbs ul li.active:last-child:before {
  display: none;
}

.breadcrumbs ul li.last:before {
  content: "";
  display: block;
  width: $breadcrumbs-height * 0.7;
  height: $breadcrumbs-height * 0.7;
  position: absolute;
  top: 6px;
  right: 6px;
  background: $light;
  z-index: 1;
  transform: rotate(-45deg);
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.breadcrumbs ul li.done:last-child:after {
  background: $light;
  display: block;
  right: -14px;
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.breadcrumbs ul li.active:after {
  background: $blue;
  display: block;
  right: 6px;
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.breadcrumbs ul li.done .icon {
  border-color: $blue;
  background-color: $blue;
  background-image: url(../../assets/images/breadcrumbs_check_white.png);
  background-position: center center;
  background-size: 12px 9px;
  background-repeat: no-repeat;
}

.breadcrumbs ul li.active .icon {
  border-color: $white;
  background-image: url(../../assets/images/breadcrumbs_check_white.png);
  background-position: center center;
  background-size: 12px 9px;
  background-repeat: no-repeat;
}

.breadcrumbs ul li.active {
}

@media (max-width: 1185px) and (min-width: 1100px) {
  .breadcrumbs {
    font-size: 10px;
  }
}

@media (max-width: 1099px) and (min-width: 970px) {
  .breadcrumbs {
    font-size: 8px;
  }
}
</style>