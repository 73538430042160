<template>
  <div class="container">
    <div class      	    = "glassInfoIcon"
        @mousedown          = "showInfo('top', true)"
        @touchstart.prevent = "showInfo('top', true)"
        @mouseover          = "showInfo('top')"
        @mouseleave         = "closeInfo">                                                                    
        <div class="infoTitle">Nápověda <div class="infoTitleString">Sklo</div></div>
    </div>                
    <div class="glassInfo" v-show="isInfoVisible && infoProfileId == 'top'"
        @mouseover  = "showInfo('top')"
        @mouseleave = "closeInfo"
    > 
        <span @click="closeInfo" @touch="closeInfo" class="icon icon-infoClose"></span>
        <div  class="glassDetail" v-html="details"></div>
    </div>
    <div class="titleH1">
      <h2 class="headline--padded text-center" v-html="$t('glass.title')"></h2>
    </div>

    <div class="glassGroupSelect">
      <button @click="selectGroup('1')" class="btn btn--lg btn--primary btn--rounded" :class="[{ 'glassesGroupButtonOn' : isSelectedGroup1   }]" v-html="$t('glass.group1')"></button>
      <button @click="selectGroup('2')" class="btn btn--lg btn--primary btn--rounded" :class="[{ 'glassesGroupButtonOn' : isSelectedGroup2   }]" v-html="$t('glass.group2')"></button>
      <button @click="selectGroup('3')" class="btn btn--lg btn--primary btn--rounded" :class="[{ 'glassesGroupButtonOn' : isSelectedGroup3   }]" v-html="$t('glass.group3')"></button>
      <button @click="selectGroup('0')" class="btn btn--lg btn--primary btn--rounded" :class="[{ 'glassesGroupButtonOn' : isSelectedGroupAll }]" v-html="$t('groupAll')"></button>
    </div>

    <div class="cols padded--bottom movable">

     <div class="glassesGroupTitle" v-show="!isSelectedGroupAll">
       <div class="glassesGroupText"  v-show="isSelectedGroup1"><strong>DVOJSKLO</strong></div>
       <div class="glassesGroupPrice" v-show="isSelectedGroup1"><strong> Dvojsklo </strong></div>

       <div class="glassesGroupText"  v-show="isSelectedGroup2"><strong>TROJSKLO</strong></div>
       <div class="glassesGroupPrice" v-show="isSelectedGroup2"><strong> Trojsklo </strong></div>

       <div class="glassesGroupText"  v-show="isSelectedGroup3"><strong>PVC PANEL</strong></div>
       <div class="glassesGroupPrice" v-show="isSelectedGroup3"><strong> PVC Panel </strong></div>
     </div>

     <div class="glassesGroupTitle" v-show="isSelectedGroupAll">
       <div class="glassesGroupText"  ><strong>DVOJSKLO</strong></div>
       <div class="glassesGroupPrice" ><strong> Dvojsklo </strong></div>
     </div>

<!--  
      <div v-for="(glass, index) in glasses1" v-bind:key="index" class="glass_wrapper" @click="select(glass)"
      :style="[{ 'order' : index < movableOrder ? glasses.length + movableOrder + index: index + movableOrder }]">
-->
      <div v-for="(glass, index) in glasses1" v-bind:key="index" class="glass_wrapper" @click="select(glass)">

        <div class="glass" :class="[{ 'selected' : isSelected(glass) }]">
          <div class="profile__inner">
            <div class="profile__about">
              <div class="profile__title glass__title"> {{ glass.name }} </div>
              <div class="profile__image glass__image">
                <img :src="getStorageUrl(glass.image)">
              </div>
	      <div class="profile__price price price--novat" v-html="$t(`priceString`,{ value: getPriceForGlass(glass, 'wizardGlass') })"></div>
            </div>
            <div class="glass__info">
              <div class="profile__property glass_property">
                <div class="profile__icon"><i class="icon icon-prostup_tepla"></i></div>
                <div class="profile__label" v-html="$t('propustnost')"></div>
              </div>
              <div class="profile__property glass__property__value">
                <div class="profile__value" v-html="glass.propustnost"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

     <div class="glassesGroupTitle" v-show="isSelectedGroupAll">
       <div class="glassesGroupText"  ><strong>TROJSKLO</strong></div>
       <div class="glassesGroupPrice" ><strong> Trojsklo </strong></div>
     </div>
<!--
      <div v-for="(glass, index) in glasses2" v-bind:key="index" class="glass_wrapper" @click="select(glass)"
      :style="[{ 'order' : index < movableOrder ? glasses.length + movableOrder + index: index + movableOrder }]">
-->
      <div v-for="(glass, index) in glasses2" v-bind:key="index" class="glass_wrapper" @click="select(glass)">

        <div class="glass" :class="[{ 'selected' : isSelected(glass) }]">
          <div class="profile__inner">
            <div class="profile__about">
              <div class="profile__title glass__title"> {{ glass.name }} </div>
              <div class="profile__image glass__image">
                <img :src="getStorageUrl(glass.image)">
              </div>
	      <div class="profile__price price price--novat" v-html="$t(`priceString`,{ value: getPriceForGlass(glass, 'wizardGlass1') })"></div>
            </div>
            <div class="glass__info">
              <div class="profile__property glass_property">
                <div class="profile__icon"><i class="icon icon-prostup_tepla"></i></div>
                <div class="profile__label" v-html="$t('propustnost')"></div>
              </div>
              <div class="profile__property glass__property__value">
                <div class="profile__value" v-html="glass.propustnost"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

     <div class="glassesGroupTitle" v-show="isSelectedGroupAll">
       <div class="glassesGroupText"  ><strong>PVC PANEL</strong></div>
       <div class="glassesGroupPrice" ><strong> PVC panel </strong></div>
     </div>
<!--
      <div v-for="(glass, index) in glasses3" v-bind:key="index" class="glass_wrapper" @click="select(glass)"
      :style="[{ 'order' : index < movableOrder ? glasses.length + movableOrder + index: index + movableOrder }]">
-->
      <div v-for="(glass, index) in glasses3" v-bind:key="index" class="glass_wrapper" @click="select(glass)">

        <div class="glass" :class="[{ 'selected' : isSelected(glass) }]">
          <div class="profile__inner">
            <div class="profile__about">
              <div class="profile__title glass__title"> {{ glass.name }} </div>
              <div class="profile__image glass__image">
                <img :src="getStorageUrl(glass.image)">
              </div>
	      <div class="profile__price price price--novat" v-html="$t(`priceString`,{ value: getPriceForGlass(glass, 'wizardGlass') })"></div>
            </div>
            <div class="glass__info">
              <div class="profile__property glass_property">
                <div class="profile__icon"><i class="icon icon-prostup_tepla"></i></div>
                <div class="profile__label" v-html="$t('propustnost')"></div>
              </div>
              <div class="profile__property glass__property__value">
                <div class="profile__value" v-html="glass.propustnost"></div>
              </div>
            </div>
          </div>
        </div>
      </div>


<!--
      <div v-if="glasses.length > movableSize"
        class="movable__arrow movable__arrow--left">
        <button @click="movablePrev"
        :class="[{ 'disabled' : movableOrder === 0 }]">Prev</button>
      </div>

      <div v-for="(glass, index) in glasses"
      v-bind:key="index"
      class="glass_wrapper"
      @click="select(glass)"
      :style="[{ 'order' : index < movableOrder ?
      glasses.length + movableOrder + index
      : index + movableOrder }]">
        <div class="glass"
        :class="[{ 'selected' : isSelected(glass) }]">
          <div class="profile__inner">
            <div class="profile__about">
              <div class="profile__title glass__title">
                {{ glass.name }}
              </div>
              <div class="profile__image glass__image">
                <img :src="getStorageUrl(glass.image)">
              </div>
	      <div class="profile__price price price--novat" v-html="$t(`priceString`,{ value: getPriceForGlass(glass, 'wizardGlass2') })"></div>
            </div>
            <div class="glass__info">
              <div class="profile__property glass_property">
                <div class="profile__icon"><i class="icon icon-prostup_tepla"></i></div>
                <div class="profile__label" v-html="$t('propustnost')"></div>
              </div>
              <div class="profile__property glass__property__value">
                <div class="profile__value" v-html="glass.propustnost"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="glasses.length > movableSize"
        class="movable__arrow movable__arrow--right">
        <button @click="movableNext"
        :class="[{ 'disabled' : movableOrder === movableLimit - movableSize }]">Next</button>
      </div>
-->
    </div>

    <div class="stickyFooterAround">
      <div class="stickyFooter">
        <div class='helpFooter'>
<!--         <div class="helpImg" @click="helpText();"></div>
         <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div>
-->
          <div id="helpText" class="helpText" style="inline-block;"><span class="infoTel"><a href="tel:+420 776 327 222">+420 776 327 222</a></span> <br> <span class="infoMail"><a href="mailto:info@skladoken.cz">info@skladoken.cz</a></span></div>
        </div>

        <button
        @click="backButton"
        class="stickyButtonBack"
        v-html="$t('backButtonText')"></button>

        <button
        @click="confirm"
        class="stickyButton"
        :class="[{ 'disabled' : !isConfirmReady() }]"
        v-html="$t('confirmAndContinue')"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "getCurrentCartField",
  mutationType: "updateCurrentCartField"
});

export default {
  name: "WizardGlass",
  data() {
    return {
      movableOrder: 0,
      isInfoVisible: false,
      infoProfileId: "",
      isSelectedGroupAll: true,
      isSelectedGroup1:   false,
      isSelectedGroup2:   false,
      isSelectedGroup3:   false,
      baseGlasses:        this.$store.state.glass.glass.slice(1)
    };
  },
  watch: {
    glasses1(newGlasses) {
      return newGlasses;
    },
    glasses2(newGlasses) {
      return newGlasses;
    },
    glasses3(newGlasses) {
      return newGlasses;
    },
  },
  computed: {
    ...mapState({
      glass:       state => state.glass.glass.slice(1),
      glasses:     state => state.glass.glass.slice(1),
      glassweight: state => state.glassweight.glassweight,
      profiles:    state => state.profiles.profiles.slice(1)
    }),
    ...mapFields("cart", {
      "selected": "glass",
      "variation": "variation",
      "profile": "profile",
      "sk":"sk"
    }),
    movableLimit() {
      return this.glasses.length;
    },
    movableSize() {
      return 6;
    },
    details() {
     if(this.$store.state.glass.glass[0])
      { return this.$store.state.glass.glass[0].details; }
     return false;
    },
    glass() {
      return this.$store.state.glass.glass.slice(1);
    },
    glasses() {
      return this.$store.state.glass.glass.slice(1);
    },
    glassweight() {
      return this.$store.state.glassweight.glassweight;
    },
    glasses1() {
       if(this.baseGlasses)
        { 
         let glasses = this.baseGlasses.filter( element => parseInt(element.group1) === parseInt("1"));
         return glasses;
        }
       let inputGlasses = this.$store.state.glass.glass.slice(1);
       let glasses = inputGlasses.filter( element => parseInt(element.group1) === parseInt("1"));
       return glasses;
      },
    glasses2() {
       if(this.baseGlasses)
        { 
         let glasses = this.baseGlasses.filter( element => parseInt(element.group2) === parseInt("1"));
         return glasses;
        }
       let inputGlasses = this.$store.state.glass.glass.slice(1);
       let glasses = inputGlasses.filter( element => parseInt(element.group2) === parseInt("1"));
       return glasses;
      },
    glasses3() {
       if(this.baseGlasses)
        { 
         let glasses = this.baseGlasses.filter( element => parseInt(element.group3) === parseInt("1"));
         return glasses;
        }
       let inputGlasses = this.$store.state.glass.glass.slice(1);
       let glasses = inputGlasses.filter( element => parseInt(element.group3) === parseInt("1"));
       return glasses;
      },
  },
  methods: {
    selectGroup(sel) 
     {
      let inGlass  = this.$store.state.glass.glass.slice(1);
      let glass1 = inGlass.filter( element => parseInt(element.group1) === parseInt("1"));
      let glass2 = inGlass.filter( element => parseInt(element.group2) === parseInt("1"));
      let glass3 = inGlass.filter( element => parseInt(element.group3) === parseInt("1"));
      inGlass    = glass1.concat(glass2);
      inGlass    = inGlass.concat(glass3);
      let outGlass = inGlass;
      
      if(sel == "0")
        { this.isSelectedGroupAll = true;  this.isSelectedGroup1 = false; this.isSelectedGroup2 = false; this.isSelectedGroup3 = false;}
      else if(sel == "1")
        { 
         this.isSelectedGroupAll = false; this.isSelectedGroup1 = true;   this.isSelectedGroup2 = false; this.isSelectedGroup3 = false;
         outGlass = inGlass.filter( element => parseInt(element.group1) === parseInt("1"));
        }
      else if(sel == "2")
        { 
         this.isSelectedGroupAll = false; this.isSelectedGroup1 = false;  this.isSelectedGroup2 = true;  this.isSelectedGroup3 = false;
         outGlass = inGlass.slice(1).filter( element => parseInt(element.group2) === parseInt("1"));        
        }
      else if(sel == "3")
        { 
         this.isSelectedGroupAll = false; this.isSelectedGroup1 = false; this.isSelectedGroup2 = false;  this.isSelectedGroup3 = true;  
         outGlass = inGlass.slice(1).filter( element => parseInt(element.group3) === parseInt("1"));
        }
      else 
        { 
         this.isSelectedGroupAll = true;  this.isSelectedGroup1 = false; this.isSelectedGroup2 = false; this.isSelectedGroup3 = false;
        }
      this.baseGlasses = outGlass;
     },
    showInfo(id, click)
     {
      if(this.isInfoVisible == false && this.infoProfileId == "")
       {
        this.isInfoVisible  = true;
        this.infoProfileId = id;
       }
      else if (this.isInfoVisible == true && this.infoProfileId != "")
       {
        if (this.infoProfileId == id && click)
         {
          this.isInfoVisible  = false;
          this.infoProfileId  = "";
         }
        else
         {
          this.isInfoVisible  = true;
          this.infoProfileId = id;
         }
       }
      else
       {
        this.isInfoVisible  = false;
	this.infoProfileId = "";
       }
     },
    closeInfo() 
     {
      this.isInfoVisible = false;
      this.infoProfileId = "";
     },
    getPriceForGlass(glass, source)
     {
      let outPriceString = '';
      let outPriceValue   = 0;

      if(this.selected !== null)
       {
        if (this.selected.id === glass.id)
         {
	  outPriceString = 'Vybráno';
          return outPriceString;	
         }
        else
         {
	  outPriceValue = this.getProductPrice(this.variation, this.profile, this.profiles, glass.price, this.currentCartItem, null, null, source) - 
			  this.getProductPrice(this.variation, this.profile, this.profiles, this.selected.price, this.currentCartItem, null, null, source);

          if (outPriceValue > 0)       { outPriceString = '+' + outPriceValue.toString(); }
          else                         { outPriceString =       outPriceValue.toString(); }

          return outPriceString + " Kč <span class='bezDPH'>bez DPH</span>";
         }
       }
     },
    movablePrev() {
      if (this.movableOrder > 0) {
        this.movableOrder = this.movableOrder - 1;
      }
    },
    movableNext() {
      if (this.movableOrder < this.movableLimit - this.movableSize) {
        this.movableOrder = this.movableOrder + 1;
      }
    },
    isSelected(glass) {
      if (this.selected === null) {
        return false;
      }
      return this.selected.id === glass.id;
    },
    select(glass) {
      if (typeof glass === "undefined" || glass === null) {
        return;
      }

      this.selected = glass;
      
      if(typeof this.selected !== "undefined" && this.selected !== null) 
       {
         let productId   = this.variation.id;
         let selectedId  = this.selected.id;
         let valueSk     = "0";

         this.glassweight.forEach( function(gw) {
           if(parseInt(gw.productid) == parseInt(productId) && parseInt(gw.id) == parseInt(selectedId))
            { valueSk = gw.sk; }
          }); 
         this.sk = valueSk;
       }
    },
    isConfirmReady() {
      return this.selected !== null;
    },
    confirm() {
      if (this.isConfirmReady()) {
        this.$router.push({ name: "accessories" });
      }
    },
    backButton() {
        this.selected = null;
	this.$router.push({ name: "color" });
    },
    selectFirstGlass() {
      if (typeof this.glass[0] !== "undefined" && this.selected === null) {
        this.select(this.glass[0]);
      }
    }
  },
  mounted() {
    this.$store.dispatch("loadGlass", () => { this.selectGroup("0"); this.selectFirstGlass(); });
    this.$store.dispatch("loadGlassWeight");
    this.$store.dispatch("loadProfiles");
    this.$store.dispatch("loadCurrencies");
  }
};
</script>

<style lang="scss">
.glass__property__value {
  padding-left: 40px;
}

.glass__title {
  font-size: 14px;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 50px;
}

.glass_wrapper {
  max-width: 16.666%;
  min-width: 16.666%;
}

.glass__image {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
