import axios from 'axios';

// initial state
const state = {
  glassweight: [],
  selected: null
};

// getters
const getters = {};

// actions
const actions = {
  loadGlassWeight({ commit }, callback) {
    axios
     .get('/wizard/public/storage/glass/glassweight.json')
       .then(response => {
	commit('updateGlassWeight', response.data);
        if (typeof callback === 'function') {
          callback();
        }
      });
  },
  selectGlassWeight({ commit }, glassweight) {
    commit('selectGlassWeight', glassweight);
  }
};

// mutations
const mutations = {
  updateGlassWeight(state, glassweight) {
    if (typeof glassweight === 'object') {
      state.glassweight = Object.keys(glassweight).map(key => glassweight[key]);
    } else {
      state.glassweight = glassweight;
    }
  },
  setGlassWeightLoaded(state) {
    state.loaded = true;
  },
  selectGlassWeight(state, glassweight) {
      state.selected = glassweight;
  },
  resetGlassWeight(state) {
      state.selected = null;
  },
  setGlassWeight(state, glassweight) {
      state.selected = glassweight;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
