<template>
  <div class="container">
    <h2 class="headline--padded text-center" v-html="$t(`color.${face}.title`)"></h2>

    <div class="colorGroupSelect">
      <button @click="selectGroup('1')" class="btn btn--lg btn--primary btn--rounded" :class="[{ 'colorsGroupButtonOn' : isSelectedGroup1   }]" v-html="$t('group1')"></button>
      <button @click="selectGroup('2')" class="btn btn--lg btn--primary btn--rounded" :class="[{ 'colorsGroupButtonOn' : isSelectedGroup2   }]" v-html="$t('group2')"></button>
      <button @click="selectGroup('0')" class="btn btn--lg btn--primary btn--rounded" :class="[{ 'colorsGroupButtonOn' : isSelectedGroupAll }]" v-html="$t('groupAll')"></button>
    </div>

    <span @click="close" class="icon icon-close"></span>

    <div class="cols padded--bottom justify-center cols--color">

      <div class="colorsGroupTitle" v-show="!isSelectedGroupAll">
       <div class="colorsGroupText"  v-show="isSelectedGroup1"><strong>SKUPINA 1</strong> – základní skupina barev</div>
       <div class="colorsGroupPrice" v-show="isSelectedGroup1"><strong>Výroba od 1 ks</strong></div>

       <div class="colorsGroupText"  v-show="isSelectedGroup2"><strong>SKUPINA 2</strong> – příplatková skupina barev</div>
       <div class="colorsGroupPrice" v-show="isSelectedGroup2"><strong>Výroba od 15 ks nebo příplatek {{ colorsurcharge }} Kč bez DPH</strong></div>
      </div>
      
      <div class="colorsGroupTitle" v-show="isSelectedGroupAll">
       <div class="colorsGroupText"  ><strong>SKUPINA 1</strong> – základní skupina barev</div>
       <div class="colorsGroupPrice" ><strong>Výroba od 1 ks</strong></div>
      </div>

      <div v-for="(color, index) in colors1" v-bind:key="index" style="position: relative;" class="colorPalette">
       <div v-show="disabled(face, color.id)" class="disabledColorText">
          <div v-if="face =='back'">
	  Nelze kombinovat se zvolenou vnitřní barvou <strong>{{ currentSelectedColor(face) }}</strong>. 
	  Pro vnější barvu můžete zvolit <strong>Bílá</strong> nebo <strong>{{ currentSelectedColor(face) }}</strong>.
	  </div>
          <div v-else>
	  Nelze kombinovat se zvolenou vnější barvou <strong>{{ currentSelectedColor(face) }}</strong>. 
	  Pro vnitřní barvu můžete zvolit <strong>Bílá</strong> nebo <strong>{{ currentSelectedColor(face) }}</strong>.
	  </div>
       </div>
       <div 
           :class="[{ 'selected' : selected && selected.id === color.id }, { 'disabled' : disabled(face, color.id) }]"
           @click="select(color)">
        <h4 class="colorTitle">{{ color.name }}</h4>
        <h5 class="colorCode">{{ color.code }}</h5>
        <div class="colorInner">
          <Magnifier v-show="!disabled(face, color.id)" :src="getStorageUrl(color.image)" :src-large="getStorageUrl(color.image)" class="colorImage"></Magnifier>
	  <img       v-show=" disabled(face, color.id)" :src="getStorageUrl(color.image)" class="colorImage" style="height: 150px;"> 
          <div class="colorCheck"></div>
        </div>
       </div>
      </div>
      
      <div class="colorsGroupTitle" v-show="isSelectedGroupAll">
       <div class="colorsGroupText"  ><strong>SKUPINA 2</strong> – příplatková skupina barev</div>
       <div class="colorsGroupPrice" ><strong>Výroba od 15 ks nebo příplatek {{ colorsurcharge }}  Kč bez DPH</strong></div>
      </div>
      
      <div v-for="(color, index) in colors2" v-bind:key="index" style="position: relative;" class="colorPalette">
       <div v-show="disabled(face, color.id)" class="disabledColorText">
          <div v-if="face =='back'">
	  Nelze kombinovat se zvolenou vnitřní barvou <strong>{{ currentSelectedColor(face) }}</strong>. 
	  Pro vnější barvu můžete zvolit <strong>Bílá</strong> nebo <strong>{{ currentSelectedColor(face) }}</strong>.
	  </div>
          <div v-else>
	  Nelze kombinovat se zvolenou vnější barvou <strong>{{ currentSelectedColor(face) }}</strong>. 
	  Pro vnitřní barvu můžete zvolit <strong>Bílá</strong> nebo <strong>{{ currentSelectedColor(face) }}</strong>.
	  </div>
       </div>
       <div 
           :class="[{ 'selected' : selected && selected.id === color.id }, { 'disabled' : disabled(face, color.id) }]"
           @click="select(color)">
        <h4 class="colorTitle">{{ color.name }}</h4>
        <h5 class="colorCode">{{ color.code }}</h5>
        <div class="colorInner">
          <Magnifier v-show="!disabled(face, color.id)" :src="getStorageUrl(color.image)" :src-large="getStorageUrl(color.image)" class="colorImage"></Magnifier>
	  <img       v-show=" disabled(face, color.id)" :src="getStorageUrl(color.image)" class="colorImage" style="height: 150px;"> 
          <!-- <img :src="getStorageUrl(color.image)" class="color__image"> -->
          <!-- <div class="color__price price price--novat"
              v-html="$t(`priceNoVatPlus`, {
                value: currencyFormatNum(getPriceForColor(color, face)) })">
          </div> -->
          <div class="colorCheck"></div>
        </div>
       </div>
      </div>


    </div>
<!--
    <div class="form-row form-row--padded text-center">
      <button
          @click="confirm"
          class="btn btn--lg btn--primary btn--rounded"
          v-html="$t('color.picker.confirm')"></button>
    </div>
-->
    <div class="stickyFooterAround">
      <div class="stickyFooter">
	<div class='helpFooter'>
<!--	 <div class="helpImg" @click="helpText();"></div>
	 <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div>
-->
             <div id="helpText" class="helpText" style="inline-block;"><span class="infoTel"><a href="tel:+420 776 327 222">+420 776 327 222</a></span> <br> <span class="infoMail"><a href="mailto:info@skladoken.cz">info@skladoken.cz</a></span></div>
	</div>
        <button
         @click="confirm"
         class="stickyButton"
         v-html="$t('color.picker.confirm')"></button>
      </div>
    </div>

  </div>
</template>

<script>
  import Magnifier from './Magnifier';
  import { mapState } from "vuex";
  import { createHelpers } from "vuex-map-fields";

  const { mapFields } = createHelpers({
    getterType: "getCurrentCartField",
    mutationType: "updateCurrentCartField"
  });

  export default {
    name: "WizardColorPicker",
    components: {
      Magnifier
    },
    props: ["face"],
    computed: {
      ...mapState({
/*        colors: state => state.colors.colors.slice(1), */
        profiles: state => state.profiles.profiles.slice(1),
        colors_accessories: state => state.colors_accessories.colors_accessories,

//        accessoryProducts: state => state.accessories.products,
      }),
      ...mapFields("cart", [
        "variation",
        "profile",
        "windowfaces",
        "windowfaces.front",
        "windowfaces.back",
        
//        "accessories",
//        "accessories.handle",
//        "accessories.inner",
//        "accessories.outer",
      ]),
      selected() {
        return this[this.face];
      },
      colors1() {
       if(this.baseColors)
        { 
         let colors = this.baseColors.filter( element => parseInt(element.group1) === parseInt("1"));
         return colors;
        }
       let inputColors = this.$store.state.colors.colors.slice(1);
       let colors = inputColors.filter( element => parseInt(element.group1) === parseInt("1"));
       return colors;
      },
      colors2() {
       if(this.baseColors)
        { 
         let colors = this.baseColors.filter( element => parseInt(element.group2) === parseInt("1"));
         return colors;
        }
       let inputColors = this.$store.state.colors.colors.slice(1);
       let colors = inputColors.filter( element => parseInt(element.group2) === parseInt("1"));
       return colors;
      },
      colorsurcharge() {
       if (this.$store.state.currencies.currencies[1])
        { return this.$store.state.currencies.currencies[1].colorsurcharge; }
       return 0;
      }
    },
    data() {
      return {
        placeholder: "/static/colors/placeholder.png",
        availableFaces: ["front", "back"],
        isSelectedGroupAll: true,
        isSelectedGroup1:   false,
        isSelectedGroup2:   false,
        baseColors:	    this.$store.state.colors.colors.slice(1),

        accessoryTypes: [
         "inner",
         "outer",
         "handle"
        ],    
      };
    },
    watch: {
      colors1(newColors) {
         return newColors;
        },
      colors2(newColors) {
         return newColors;
        },      
    },
    methods: {
      selectGroup(sel) {
       let inColors  = this.$store.state.colors.colors.slice(1);
       let colors1 = inColors.filter( element => parseInt(element.group1) === parseInt("1"));
       let colors2 = inColors.filter( element => parseInt(element.group2) === parseInt("1"));
       inColors      = colors1.concat(colors2); 
       let outColors = inColors;

       if(sel == "0")
        { this.isSelectedGroupAll = true;  this.isSelectedGroup1 = false; this.isSelectedGroup2 = false; }
       else if(sel == "1")
        { 
         this.isSelectedGroupAll = false; this.isSelectedGroup1 = true;  this.isSelectedGroup2 = false; 
         outColors = inColors.filter( element => parseInt(element.group1) === parseInt("1"));
        }
       else if(sel == "2")
        { 
         this.isSelectedGroupAll = false; this.isSelectedGroup1 = false; this.isSelectedGroup2 = true;  
         outColors = inColors.slice(1).filter( element => parseInt(element.group2) === parseInt("1"));        
        }
       else 
        { 
         this.isSelectedGroupAll = true;  this.isSelectedGroup1 = false; this.isSelectedGroup2 = false;
        }
       this.baseColors = outColors;
      },
      //getPriceForColor(color, face) {
      //    return this.getProductPrice(this.variation, this.profile, this.profiles, 0.0, null, color, face, this.windowfaces.front ? this.windowfaces.front : null, this.windowfaces.back ? this.windowfaces.back : null) - this.getProductBasePrice(this.variation, this.profiles);
      //},
      close() {
        this.$router.push({ name: "color" });
      },
      select(color) {
        if ( this.disabled(this.face, color.id) )
        {
          return false;
        }
        this[this.face] = color;
        // console.log(this);
        // console.log(this.face);
        // console.log(this[this.face]);
	// console.log(this.colors_accessories);

        let colorsAccessoriesArray  = [];
        let selectedHandle          = null;
        let selectedCover           = null;
        let selectedCoverdrain      = null;
        
        if (typeof this.colors_accessories === 'object') { colorsAccessoriesArray = Object.values(this.colors_accessories); } 
        else 			                         { colorsAccessoriesArray = this.colors_accessories;                }
        colorsAccessoriesArray.forEach(colorAccessory => {
          if ( colorAccessory.id == this[this.face].handle.select    ) { selectedHandle     = colorAccessory; }
          if ( colorAccessory.id == this[this.face].cover.select     ) { selectedCover      = colorAccessory; }
          if ( colorAccessory.id == this[this.face].coverdrain.select) { selectedCoverdrain = colorAccessory; }
        });
 // console.log('selectedHandle =', selectedHandle);
       this.currentCartItem.accessories.handle[this.face]     = selectedHandle;
       this.currentCartItem.accessories.cover[this.face]      = selectedCover;
       this.currentCartItem.accessories.coverdrain[this.face] = selectedCoverdrain;

 // console.log('this.currentCartItem.accessories.handle[this.face]',     this.currentCartItem.accessories.handle[this.face]);
 // console.log('this.currentCartItem.accessories.cover[this.face]',      this.currentCartItem.accessories.cover[this.face]);
 // console.log('this.currentCartItem.accessories.coverdrain[this.face]', this.currentCartItem.accessories.coverdrain[this.face]);

//      let objHandle = this.accessoryProducts.handle.filter(item => { if(item.id == color.handle.select) {return true; } else {return false; } });
//      console.log(objHandle);
//	this.accessories.handle = objHandle[0];
      },
      confirm() {
        this.$router.push({ name: "color" });
      },
      disabled(face, colorId) {
        let oppositeFace = face === 'front' ? 'back' : 'front';
        let oppositeWindowFace = this.windowfaces[oppositeFace];

        if (oppositeWindowFace === null || typeof oppositeWindowFace == 'undefined') {
          return false;
        }

        if (oppositeWindowFace.id == 0) {
          return false;
        } else {
          if (colorId == 0 || colorId == oppositeWindowFace.id) {
            return false;
          }

          return true;
        }
      },
      currentSelectedColor(face) {
        let oppositeFace = face === 'front' ? 'back' : 'front';
        if(typeof this.currentCartItem.windowfaces !== 'undefined' && this.currentCartItem.windowfaces[oppositeFace] !== null)
         {
          // console.log(oppositeFace, this.currentCartItem.windowfaces); 
          return this.currentCartItem.windowfaces[oppositeFace].name; 
         }
        else
         { console.log('front && back => null!'); }
      },
//      selectFirstHandle() {
//      console.log(this.handle);
//        if (
//          this.accessoryProducts.handle &&
//          this.accessoryProducts.handle[1] &&
//          this.handle === null
//        ) {
//          const product = this.accessoryProducts.handle[1];
//          this.handle = {
//            color: product
//          };
//        }
//        else {
//          const product = this.accessoryProducts.handle[this.handle.id];
//          this.handle = {
//            color: product
//           };
//        }
//      }
    },
    mounted() {
//      this.$store.dispatch("loadAccessories", { type: "handle", callback: () => { this.selectFirstHandle(); }});
      this.$store.dispatch("loadColors", () => { this.selectGroup("0"); }); 
      this.$store.dispatch("loadProfiles");

      // Choose default color
      /*
        if (this.selected === null && typeof this.colors[0] !== "undefined") {
        this.select(this.colors[0]);
      }*/
    }
  }
</script>

<style lang="scss">
  @import "@/assets/styles/variables";

  .cols--color {
    flex-wrap: wrap;
  }
  .color__palette {
    max-width: 12.5%;
    min-width: 12.5%;
  }

  .color__palette {
    @media (max-width: $screen-xs-max) {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .color__title {
    text-align: center;
    cursor: pointer;
  }
  .color__price {
    margin-top: 6px;
    text-align: center;
  }
  .color__inner {
    margin-left: 10px;
    margin-right: 10px;
    padding: 7px 7px 0 7px;
    border: 1px solid $border-grey;
    position: relative;
  }
  .color__check {
    display: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJCAYAAAAGuM1UAAAAcElEQVQYlY3RMQrCUBAE0EmfwvPoZXI3GwsRxPJjp53ieayeTQKfHzUZ2GJmZ5mF6ZCVOCbZB2vmbsQk7LBZMuMWDCN5LZgLEjxa8Zd5OuhxrZYHnCp+qVPr+GKOc/tm+3P5Z0Y68x6eSd5Jtt/K+ABEACdDy8MulQAAAABJRU5ErkJggg==);
    background-position: 50%;
    background-size: 12px 9px;
    background-repeat: no-repeat;
    position: absolute;
    width: 20%;
    height: 20%;
    top: 35%;
    left: 40%;
    /* color: black; */
    background-color: #1dbcea;
  }
  .selected .color__inner {
    border: 1px solid $blue;
  }
  .selected .color__price {
    background-color: $blue;
    color: $white;
    margin-left: -7px;
    margin-right: -7px;
  }
  .selected .color__check {
    display: block;
  }
  .color__inner img,
  .color__image {
    width: 100%;
    height: auto;
    border: 1px solid $border-grey;

    .vue-magnifier-container .preview {
      width: 100%;
      height: 124px;
    }
  }
</style>
