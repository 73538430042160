import axios from 'axios';

// initial state
const state = {
  glass: [],
  selected: null
};

// getters
const getters = {};

// actions
const actions = {
  loadGlass({ commit }, callback) {
    axios
      .get(
        '/wizard/public/storage/glass/glass.json'
      )
      .then(response => {
        commit('updateGlass', response.data);
        if (typeof callback === 'function') {
          callback();
        }
      });
  },
  selectGlass({ commit }, glass) {
    commit('selectGlass', glass);
  }
};

// mutations
const mutations = {
  updateGlass(state, glass) {
    var glassArray = glass;

    if (typeof glassArray === 'object') {
      glassArray = Object.values(glass);
    }

    var glassByPosition = glassArray.slice(0);

    glassByPosition.sort(function(a,b) {
      let x = parseInt(a.position);
      let y = parseInt(b.position);

      return x < y ? -1 : x > y ? 1 : 0;
    });

    //state.glass = glass;
    state.glass = glassByPosition;
  },
  selectGlass(state, glass) {
    state.selected = glass;
  },
  resetGlass(state) {
    state.selected = null;
  },
  setGlass(state, glass) {
    state.selected = glass;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
