<template>
  <div class="container-wizard" ref="canvasContainer">
      <canvas v-if     ="$route.name == 'order' && parseInt(windowWidth) <= 1180" class="konfigurace" :width="900" :height="700"></canvas>
      <canvas v-else-if="$route.name == 'order'" class="konfigurace" :width="canvasWidth" :height="canvasHeight"></canvas>
      <canvas v-else class="konfigurace" width="1000" height="700"></canvas>
    <div class="textures">
      <img v-for="(color, index) in colors"
           v-bind:key="index"
           v-bind:class="['color-'+color.id,'color'+color.id]"
           v-bind:src="getStorageUrl(color.image)"
      />
    </div>
    <div class="antiFlicker konfigurace"></div>
  </div>
</template>

<script>
import { mapState }  from  "vuex";

export default {
  name: "Preview",
  mounted() { 
        const element  = this.$refs.canvasContainer.parentNode.parentNode;
        const observer = new ResizeObserver(() => { 
         // observe canvas parent size only for 'order' page
         if(this.$route.name == 'order')
          {
//           if (parseInt(window.innerWidth) >= 1180 && parseInt(window.innerWidth) < 1280) 
           if (parseInt(window.innerWidth) >= 1180) 
            { 
             this.updateScreenWidth();
            }
           else 
            {
             // if width < 1180 rewrite canvas only one time (stop rewrite flickering)
	     this.windowWidth = parseInt(window.innerWidth);
	     const query = matchMedia('(min-width: 1180px)');
	     query.addEventListener('change', () => { 
	      this.updateScreenWidth();
	     });
             return; 
            }
          }
         else { return; } 
        });
       observer.observe(element);

        /* use thicker line for the canvas image at the 'order' page, if the 'order' page have width > 1180 */
	if (this.$route.name == 'order' && parseInt(window.innerWidth) > 1180)
	 { this.thickness = 1; } else { this.thickness = 0; }
	this.$nextTick(() => { this.draw(this.thickness); });
  },
  computed: {
    ...mapState({ colors: state => state.colors.colors.slice(1) }),
    windowCount() { return this.okna.length; }
  },
  watch: {
      okna: { handler() 
              { 
               this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
               this.updateScreenWidth();
              },
      deep: true
    }
  },
  methods: {
   updateScreenWidth() {
     this.windowWidth = parseInt(window.innerWidth);
     if(typeof this.$refs.canvasContainer !== 'undefined')
      {
        const element     = this.$refs.canvasContainer.parentNode;
        const width       = element.offsetWidth;
        const height      = element.offsetHeight;

        /* canvas image resolution */
        this.canvasHeight = parseInt(height * 3);  
        this.canvasWidth  = parseInt(width * 4);  
        let thickness = 0;
      
        // console.log(width);
      
        /* use thicker line for the canvas image at the 'order' page, if the 'order' page have width > 1180 */
        if (this.$route.name == 'order' && (parseInt(window.innerWidth) > 1180)) 
         { thickness = 1; }
        else 
         { thickness = 0; }
        this.$nextTick(() => { 
          if (this.$route.name == 'order') { document.querySelectorAll('div[class^=antiFlicker]').forEach((elem) => elem.style.display="block"); }
	  this.draw(thickness); 
	});
      }
    },
// add()  { this.okna.push({ sirka_ramu: 14, startX: 100, startY: 0, sirka: 800, vyska: 1800, otvirani: { top: 1, right: 1, bottom: 0, left: 0 }, pocetMriziVertikalnich: 3, pocetMriziHorizontalnich: 2, sirkaMrizi: 4, sitka: 0, zaluzie: 0, pricka: 0 }); },
   draw(thickness = 1) {
      this.canvas = this.$el.querySelector(`.${this.canvasId}`); this.ctx = this.canvas.getContext("2d"); 
      // Zmerit velikost celeho okna
      if ( this.okna && this.okna[0] && this.okna[0].vyska && this.okna[0].sirka) 
        { this.otvor.width  = this.okna[0].sirka; this.otvor.height = this.okna[0].vyska; }
      // Umistit otvor v ramci platna
      if (this.canvas.width / this.otvor.width < this.canvas.height / this.otvor.height) 
        { /* Sirsi */ 
         const ratio = this.otvor.height / this.otvor.width; 
         this.otvor.width  = this.canvas.width; this.otvor.height = this.canvas.width * ratio; 
        }
      else 
        { /* Vyssi */ 
         const ratio = this.otvor.width / this.otvor.height; 
         this.otvor.height = this.canvas.height; this.otvor.width  = this.canvas.height * ratio; 
        }
      this.otvor.x = (this.canvas.width  - this.otvor.width)  / 2; 
      this.otvor.y = (this.canvas.height - this.otvor.height) / 2;
      // Vykreslit
      
      let index = 0;
      this.setupColors.borderColor = "#333";
      this.drawStavebniOtvor(thickness);
      
      let ratioWidth = 1;
      
/* stop  flickering canvas */
      if(!document.getElementById("imgLoaded"))
       {
        const newDiv = document.createElement("div");
        newDiv.id = "imgLoaded";
        newDiv.style.display = "none";
        newDiv.innerHTML = "0";
        document.body.appendChild(newDiv);
       }
      this.startImagesLoading(this.imagesLoaded, ratioWidth, index, this.okna, thickness);
      setTimeout(() => document.querySelectorAll('div[class^=antiFlicker]').forEach((elem) => elem.style.display="none"), 250);
      
    },
   startImagesLoading(callback, ratioWidth, index, okna, thickness = 1) {
      let imagesOk    = 0;
      let imagesCount = 0;
      let images      = [];
      let textureImage = "/media/show/photos/9ZTZtMc7J2Ov7rjTO2iVYIlGSFepxMyBS1JmO96I.jpeg"; // default white image
      
      if(this.$route.name == 'variation')
       {
        okna.forEach(element => {
          if (typeof element.texture === 'undefined')  { element.texture = 0; }
          if (typeof element.texture !== 'undefined')
           {
            var myImage            = new Image();
            images.push(myImage);	
            if (document.getElementById('imgLoaded'))
             {
              if (document.getElementById('imgLoaded').innerHTML == "0")
               { 
                myImage.onload = function(){ 
                 imagesOk++;
                 // if we've loaded all images, call the callback
                 if (imagesOk >= okna.length) 
                  { 
                   // console.log(images, ratioWidth, index, okna);
                   callback(images, ratioWidth, index, okna, thickness); 
                  }
                };
                // notify if there's an error
                myImage.onerror = function(){ console.log("image load failed: " + textureImage); }

	        myImage.id = "img"+ imagesCount;
	        myImage.style.display = "none";
                // set img properties
                if (element.textureImage !== null)
                 { myImage.src = element.textureImage; }
                else
                 { myImage.src = textureImage; }
               
/* stop  flickering canvas */
                if(!document.getElementById("img"+imagesCount))
                 { document.body.appendChild(myImage); }
               }
              else
               {
/* stop  flickering canvas */
                images = Array.from(document.querySelectorAll('img[id^=img]'));
                callback(images, ratioWidth, index, okna, thickness); 
               }
             } 
           }
         imagesCount++;
         });      
       }
      else
       {
        okna.forEach(element =>{
          if (typeof element.texture === 'undefined')  { element.texture = 0; }
          if (typeof element.texture !== 'undefined')
           {
            var myImage            = new Image();
            images.push(myImage);

            myImage.onload = function(){ 
             imagesOk++;
             // if we've loaded all images, call the callback
             if (imagesOk >= okna.length) 
              { 
                // console.log(images, ratioWidth, index, okna);
                callback(images, ratioWidth, index, okna, thickness); 
              }
            };
            // notify if there's an error
            myImage.onerror = function(){ console.log("image load failed: " + textureImage); }

	    myImage.id = "img"+ imagesCount;
            // set img properties
            if (element.textureImage !== null)
             { myImage.src = element.textureImage; }
            else
             { myImage.src = textureImage; }               
           }
         });      
       }
    },
   imagesLoaded (images, ratioWidth, index, okna, thickness = 1) {
    okna.forEach(element => { 
      var colorsArray = []; let color = null; let side = element.side; let sideInvert = false;
	// invert front - back for exterier view
	// if (element.variation.type.indexOf ("exteriér") !== -1)
      if(parseInt(element.variation.typenum) == 15 || parseInt(element.variation.typenum) == 16 ||
          parseInt(element.variation.typenum) == 17 || parseInt(element.variation.typenum) == 23 ||
          parseInt(element.variation.typenum) == 25 || parseInt(element.variation.typenum) == 26 
         )
        { 
         if (side == "front") { side = "back";  }
         else		      { side = "front"; }
         sideInvert = true;
        }
      if (element.variation.type.indexOf("2:1") !== -1) { 
	 if (ratioWidth == 0.8) { ratioWidth = 1.2; } 
	 else		        { ratioWidth = 0.8; }
       } 
      if (element.textureImage !== null) { 
         if (typeof this.colors === 'object') { colorsArray = Object.values(this.colors); }
         else                                 { colorsArray = this.colors; }
         colorsArray.forEach(color =>
          {
           var colorImage = this.getStorageUrl(color.image);
           if (colorImage == element.textureImage) { element.texture = color.id; }
          });
          this.setupColors.borderColor = "#000";
       }
      var canvasPattern        = document.createElement("canvas");
      var ctxPattern           = canvasPattern.getContext("2d");
      canvasPattern.width      = 100; canvasPattern.height     = 100;       
      var patternSrcX          = 0;   var patternSrcY          = 0; 
      var patternWidth         = 100; var patternHeight        = 100;
      var drawPatternOnCanvasX = 0;   var drawPatternOnCanvasY = 0;

      if (element.texture >= 1)
       {
	   if (element.texture == 12)
	    { this.setupColors.borderColor = "#fff"; }
           else 
            { if 
              (element.texture != 11 && element.texture != 20 && 
    	       element.texture != 27 && element.texture != 28 ) 
              { this.setupColors.borderColor = "#ddd"; }
             else
              { this.setupColors.borderColor = "#555"; }
            }
       }
      else { this.setupColors.borderColor = "#555"; }

      let textureImg = images.pop();

      if (textureImg)
       {
	// console.log(images);
	ctxPattern.drawImage(textureImg, patternSrcX, patternSrcY, patternWidth, patternHeight, drawPatternOnCanvasX, drawPatternOnCanvasY, patternWidth, patternHeight);

	// Check if we have second(next) element in array after doing pop
	let checkImages = images.pop();
	if (!Array.isArray(checkImages) || !checkImages.length)
         { images.push(textureImg); } // We havent next element in images array, and push textureImage in to emty image array
	else
         { images.push(checkImages); }  // We have, and push back popped checkImages
       }
      var pat = this.ctx.createPattern(canvasPattern, "repeat");
      color   = pat;

      // Nastavit spravne otevirani apod.
      if(this.$route.name == 'order')
       {
        if (element.cartItemNumber >= 0)
         { 
	  element.meta = this.getMeta(element, index, element.cartItemNumber); 
	 }
       }
      else
       { 
        element.meta = this.getMeta(element, index); 
       }
//      console.log('element Preview', element);
      
      this.drawSingle(element, index, color, side, sideInvert, ratioWidth, thickness);
      index ++;
     });
/* stop  flickering canvas */
     if (document.getElementById('imgLoaded') && this.$route.name == 'variation')
      { document.getElementById('imgLoaded').innerHTML = '1'; }
   },
  drawSingle(element, index, color, side, sideInvert = false, ratioWidth, thickness = 1) { // side = 'front' OR side = 'back'
      if (element.variation.type.indexOf("exteriér") !== -1) {
        if (side == 'back') {
          if (element.orientation == 'left') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * ratioWidth;
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth)) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
          else { // right
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth);
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * ratioWidth) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
         }
        else { // front
//          console.log('Alex ' + side + ' ' + element.orientation);
          if (element.orientation == 'left') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth);
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * ratioWidth) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
          if (element.orientation == 'right') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * ratioWidth;
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth)) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
          if (element.orientation == 'none-left') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth);
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * ratioWidth) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
          if (element.orientation == 'none-right') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * ratioWidth;
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth)) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
          if (element.orientation == 'none-none') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * ratioWidth;
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth)) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
         }
       }
      else // interier and other 
       {
        if (side == 'front') {
          if (element.orientation == 'left') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth);
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * ratioWidth) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
          else { //right none
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * ratioWidth;
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth)) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
         }
        else { // back
          if (element.orientation == 'right') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth);
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * ratioWidth) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
          if (element.orientation == 'left') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * ratioWidth;
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth)) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
          if (element.orientation == 'none-left') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * ratioWidth;
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth)) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
          if (element.orientation == 'none-right') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth);
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * ratioWidth) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
          if (element.orientation == 'none-none') {
            element.zobrazovaciSirka = ((this.otvor.width - 2) / this.windowCount) * (2 - ratioWidth);
            element.zobrazovaciVyska = (this.otvor.height - 2) / 1;

            if (ratioWidth > 1) { element.startX = this.otvor.x + (((this.otvor.width - 2) / this.windowCount) * ratioWidth) * index + 1; }
            else                { element.startX = this.otvor.x + element.zobrazovaciSirka * index + 1; }
           }
         }
       }
      element.startY = this.otvor.y + 1;      
      
      element.endX = element.startX + element.zobrazovaciSirka;  
      element.endY = element.startY + element.zobrazovaciVyska;
      this.drawSingleWindow({ x: element.startX, y: element.startY }, { x: element.endX, y: element.endY }, element, color, side, sideInvert, thickness );
     },
    drawSingleWindow(start, end, element, color, side, sideInvert = false, thickness = 1) // side = 'front' OR side = 'back'
     {
      // Calculate size
      var sirkaConst = 10;
      var sirkaOkraj = 16;
      
//    if (side == 'back') { sirkaConst = 16; sirkaOkraj = 14; }
//    else 		    { sirkaConst = 10; sirkaOkraj = 16; }

      if (side == 'back') { sirkaConst = 28; sirkaOkraj = 24; }
      else 		  { sirkaConst = 20; sirkaOkraj = 28; }

        let dimensions        = { width: end.x - start.x, height: end.y - start.y };
            dimensions.ram    = { 
        		          width:  (sirkaConst / dimensions.width ) * dimensions.width, 
        		          height: (sirkaConst / dimensions.height) * dimensions.height 
        		        };
            dimensions.kridlo = {
        			  width:  (sirkaOkraj / dimensions.width ) * dimensions.width, 
        			  height: (sirkaOkraj / dimensions.height) * dimensions.height 
        		        };     
 
      // Ram - Nejvic vnejsi cast === ram - sirka vzdy 3.75cm
      this.drawFill(start.x, start.y, dimensions.width, dimensions.height, color);

      // Kridlo - Vnejsi cast === kridlo - sirka okraje vzdy 8cm
      const kridloStartX  = start.x               + dimensions.ram.width;             
      const kridloStartY  = start.y               + dimensions.ram.height;
      const kridloWidth   = dimensions.width  - 2 * dimensions.ram.width; 
      const kridloHeight  = dimensions.height - 2 * dimensions.ram.height;
      this.drawBorderedFill( kridloStartX, kridloStartY, kridloWidth, kridloHeight, color, thickness );
 
      // Sklo - vnitrni cast, cely zbytek objemu
      const glassStartX = start.x                 + dimensions.ram.width      + dimensions.kridlo.width;
      const glassStartY = start.y                 + dimensions.ram.height     + dimensions.kridlo.height;
      const glassWidth  = dimensions.width    - 2 * dimensions.ram.width  - 2 * dimensions.kridlo.width;
      const glassHeight = dimensions.height   - 2 * dimensions.ram.height - 2 * dimensions.kridlo.height;
      
      if(!isNaN(glassStartX))
       {
        this.drawBorderedFillGradient( glassStartX, glassStartY, glassWidth, glassHeight, this.setupColors.glassColor, this.setupColors.glassColor2, thickness);
        if(sideInvert)
         {
          if(element.orientation === 'none') 
           { this.drawSmery ( glassStartX, glassStartY, glassWidth, glassHeight, dimensions, element ); }
          else 
           { this.drawSmery ( glassStartX, glassStartY, glassWidth, glassHeight,  dimensions, element, true ); }
         }
        else
         {
          if(element.orientation === 'none') 
           { this.drawSmery ( glassStartX, glassStartY, glassWidth, glassHeight, dimensions, element, true ); }
          else 
           { this.drawSmery ( glassStartX, glassStartY, glassWidth, glassHeight,  dimensions, element ); }
         }
        this.drawKliky ( kridloStartX, kridloStartY, kridloWidth, kridloHeight, dimensions, element, thickness );
        this.drawPanty ( kridloStartX, kridloStartY, kridloWidth, kridloHeight, dimensions, element, thickness );
        this.drawStulp ( start, end, dimensions, element, thickness );
       }
     },
    /* eslint-disable */
    drawPanty(kridloStartX, kridloStartY, kridloWidth, kridloHeight, dimensions, element, thickness = 1) 
     {
      /* eslint-enable */

      let coverColor = "#ffffff";
      if (element.side == "front")
       { coverColor = element.meta.covercolor.front; }
      if (element.side == "back")
       { coverColor = element.meta.covercolor.back; }

      // Bottom
      if (element.meta.panty.bottom) 
       { 
        const pantWidth  = dimensions.ram.width * 2; 
        const pantHeight = dimensions.ram.height / 1.5; // Levy pant
        this.drawBorderedFill( kridloStartX + kridloWidth / 4 - pantWidth / 2, kridloStartY + kridloHeight, pantWidth, pantHeight, coverColor, thickness );
        // Pravy pant
        this.drawBorderedFill( kridloStartX + (kridloWidth / 4) * 3 - pantWidth / 2, kridloStartY + kridloHeight, pantWidth, pantHeight, coverColor, thickness );
       }
      // Right
      if (element.meta.panty.right) 
       { 
        const pantWidth = dimensions.ram.width / 1.5; const pantHeight = dimensions.ram.height * 2;
        // Horni pant
        this.drawBorderedFill( kridloStartX + kridloWidth, kridloStartY + 2 /*+ kridloHeight / 4 - pantHeight / 2*/, pantWidth, pantHeight, coverColor, thickness );
        // Dolni pant
        this.drawBorderedFill( kridloStartX + kridloWidth, kridloStartY + kridloHeight - pantHeight - 2 /*+ (kridloHeight / 4) * 3 - pantHeight / 2*/, pantWidth, pantHeight, coverColor, thickness );
       }
      // Left
      if (element.meta.panty.left) {
        const pantWidth = dimensions.ram.width / 1.5; const pantHeight = dimensions.ram.height * 2;
        // Horni pant
        this.drawBorderedFill(kridloStartX - pantWidth, kridloStartY + 2 /* + kridloHeight / 4 - pantHeight / 2 */, pantWidth, pantHeight, coverColor, thickness);
        // Dolni pant
        this.drawBorderedFill(kridloStartX - pantWidth, kridloStartY + kridloHeight - pantHeight - 2 /* + (kridloHeight / 4) * 3 - pantHeight / 2 */, pantWidth, pantHeight, coverColor, thickness );
      }
      // Panty 2 Left
      if (element.meta.panty2.left) {
        const pantWidth = dimensions.ram.width / 1.5; const pantHeight = dimensions.ram.height * 2;
        let topPartPantWidth  = pantHeight/1.5; 
        let topPartPantHeight = pantWidth * 1.5;
        // Top pant
        this.drawBorderedFill(kridloStartX - pantWidth, kridloStartY + 4, pantWidth, pantHeight, coverColor, thickness);
        this.drawBorderedFill(kridloStartX - pantWidth, kridloStartY + 4, topPartPantWidth, topPartPantHeight, coverColor, thickness);
        // Middle pant
        this.drawBorderedFill(kridloStartX - pantWidth, kridloStartY + (kridloHeight/2) - pantHeight/2, pantWidth, pantHeight, coverColor, thickness );
        this.drawBorderedFill(kridloStartX - pantWidth, kridloStartY + (kridloHeight/2) - topPartPantHeight, topPartPantWidth, topPartPantHeight, coverColor, thickness);
        // Bottom pant
        this.drawBorderedFill(kridloStartX - pantWidth, kridloStartY + kridloHeight - pantHeight - 4, pantWidth, pantHeight, coverColor, thickness );
        this.drawBorderedFill(kridloStartX - pantWidth, kridloStartY + kridloHeight - pantHeight - 4, topPartPantWidth, topPartPantHeight, coverColor, thickness);
      }
      if (element.meta.panty2.right) {
        const pantWidth = dimensions.ram.width / 1.5; const pantHeight = dimensions.ram.height * 2;
        let topPartPantWidth  = pantHeight/1.5; 
        let topPartPantHeight = pantWidth * 1.5;
        // Top pant
        this.drawBorderedFill(kridloStartX + kridloWidth, kridloStartY + 4, pantWidth, pantHeight, coverColor, thickness);
        this.drawBorderedFill(kridloStartX + kridloWidth - topPartPantWidth + pantWidth, kridloStartY + 4, topPartPantWidth, topPartPantHeight, coverColor, thickness);
        // Middle pant
        this.drawBorderedFill(kridloStartX + kridloWidth, kridloStartY + (kridloHeight/2) - pantHeight/2, pantWidth, pantHeight, coverColor, thickness );
        this.drawBorderedFill(kridloStartX + kridloWidth - topPartPantWidth + pantWidth, kridloStartY + (kridloHeight/2) - topPartPantHeight, topPartPantWidth, topPartPantHeight, coverColor, thickness);
        // Bottom pant
        this.drawBorderedFill(kridloStartX + kridloWidth, kridloStartY + kridloHeight - pantHeight - 4, pantWidth,  pantHeight, coverColor, thickness );
        this.drawBorderedFill(kridloStartX + kridloWidth - topPartPantWidth + pantWidth, kridloStartY + kridloHeight - pantHeight - 4, topPartPantWidth, topPartPantHeight, coverColor, thickness);
      }
    },
    /* eslint-disable */
    
    drawKliky(kridloStartX, kridloStartY, kridloWidth, kridloHeight, dimensions, element, thickness = 1) {
      const klikaRate  = 1.7;
      //const radiusRate = 1.4;
      const radiusRate = 2.6;

      /* eslint-enable */
      // Top
      //console.log(element);
      
      let handleColor = "#ffffff";
      if (element.side == "front")
       { handleColor = element.meta.handlecolor.front; }
      if (element.side == "back")
       { handleColor = element.meta.handlecolor.back; }

      if (element.meta.kliky.top) {
        
        const klikaWidth  = dimensions.ram.width * 3; 
        const klikaHeight = dimensions.ram.height / klikaRate;
        
        const xPos   = kridloStartX + kridloWidth / 2 - klikaWidth / 2 + 1;
        const yPos   = kridloStartY + dimensions.kridlo.height / 2;
        const radius = dimensions.ram.height / (klikaHeight / 2) * radiusRate;
        this.drawCircle(xPos, yPos, radius, handleColor );
        this.drawBorderedFill( kridloStartX + kridloWidth  / 2 - klikaWidth  / 2, 
                               kridloStartY + dimensions.kridlo.height / 2 - klikaHeight / 2, 
                               klikaWidth, klikaHeight, handleColor, thickness );
      }
      // Bottom
      if (element.meta.kliky.bottom) {
        const klikaWidth = dimensions.ram.width * 3; const klikaHeight = dimensions.ram.height / klikaRate;
        const xPos   = kridloStartX + kridloWidth / 2 - klikaWidth / 2 + 1;
        const yPos   = kridloStartY + kridloHeight - dimensions.kridlo.height / 2;
        const radius = dimensions.ram.height / (klikaHeight / 2) * radiusRate;
        this.drawCircle(xPos, yPos, radius, handleColor );
        this.drawBorderedFill(kridloStartX + kridloWidth / 2 - klikaWidth / 2,
    			      kridloStartY + kridloHeight - dimensions.kridlo.height / 2 - klikaHeight / 2, 
    			      klikaWidth, klikaHeight, handleColor, thickness);
      }
      // Left
      if (element.meta.kliky.left) {
        let klikaWidth  = dimensions.ram.width / klikaRate; let klikaHeight = dimensions.ram.height * 3; 
        let klikaStartX = kridloStartX + dimensions.kridlo.width / 2 - klikaWidth / 2;

        let xPos   = kridloStartX + dimensions.kridlo.width / 2;
        let yPos   = kridloStartY + kridloHeight / 2 - klikaHeight / 2 + 1;
        let radius = (dimensions.ram.width / (klikaWidth / 2)) * radiusRate;
        if (element.meta.kliky.horizontal) 
         { 
           // klikaWidth = dimensions.width / 5; klikaHeight = dimensions.width / 25; // old
           // klikaHeight  = dimensions.ram.width / klikaRate; klikaWidth = dimensions.ram.height * 3; // my
	   klikaHeight  = 12; klikaWidth = 60; // fixed

           klikaStartX = kridloStartX + dimensions.kridlo.width / 2; 
           xPos	       = xPos + 2;
           yPos        = kridloStartY + kridloHeight / 2;
           radius      = klikaHeight / 1.2;
         }
        this.drawCircle(xPos, yPos, radius, handleColor );
        this.drawBorderedFill(klikaStartX , kridloStartY + kridloHeight / 2 - klikaHeight / 2, 
    			      klikaWidth, klikaHeight, handleColor, thickness);
      }
      // Right
      if (element.meta.kliky.right) {
        let klikaWidth  = dimensions.ram.width / klikaRate; let klikaHeight = dimensions.ram.height * 3;         
        let klikaStartX = kridloStartX + kridloWidth - dimensions.kridlo.width / 2 - klikaWidth / 2;
        
        let xPos   = kridloStartX + kridloWidth - dimensions.kridlo.width / 2;
        let yPos   = kridloStartY + kridloHeight / 2 - klikaHeight / 2 + 1;
        let radius = dimensions.ram.width / (klikaWidth / 2) * radiusRate;
        
        if (element.meta.kliky.horizontal) 
         { 
           // klikaWidth  = dimensions.width / 5; klikaHeight = dimensions.width / 25; // old
           // klikaHeight  = dimensions.ram.width / klikaRate; klikaWidth = dimensions.ram.height * 3; // my
	   klikaHeight  = 12; klikaWidth = 60; //fixed
           
           klikaStartX = kridloStartX + kridloWidth - dimensions.kridlo.width / 2 - klikaWidth;
           xPos	       = xPos - 2;
           yPos        = kridloStartY + kridloHeight / 2;
           radius      = klikaHeight / 1.2;
         }
        this.drawCircle(xPos, yPos, radius, handleColor );        
        this.drawBorderedFill(klikaStartX, kridloStartY + kridloHeight / 2 - klikaHeight / 2, 
    			      klikaWidth, klikaHeight, handleColor, thickness);
      }
      if (element.meta.kliky2.left) {
        let klikaWidth  = (dimensions.ram.width / klikaRate)/1.6; let klikaHeight = (dimensions.ram.height * 3)/1.6;
        let klikaStartX = kridloStartX + dimensions.kridlo.width / 2 - klikaWidth / 2;
        let xPos   = kridloStartX + dimensions.kridlo.width / 2;
        let yPos   = kridloStartY + kridloHeight / 2 - klikaHeight / 2 + 1;
        let radius = ((dimensions.ram.width / (klikaWidth / 2)) * radiusRate)/1.6;
        this.drawCircle(xPos, yPos, radius, handleColor );
        this.drawBorderedFill(klikaStartX , kridloStartY + kridloHeight / 2 - klikaHeight / 2, 
    			      klikaWidth, klikaHeight, handleColor, thickness);
      }
      // Right
      if (element.meta.kliky2.right) {
        let klikaWidth  = (dimensions.ram.width / klikaRate)/1.6; let klikaHeight = (dimensions.ram.height * 3)/1.6;
        let klikaStartX = kridloStartX + kridloWidth - dimensions.kridlo.width / 2 - klikaWidth / 2;
        let xPos   = kridloStartX + kridloWidth - dimensions.kridlo.width / 2;
        let yPos   = kridloStartY + kridloHeight / 2 - klikaHeight / 2 + 1;
        let radius = (dimensions.ram.width / (klikaWidth / 2) * radiusRate)/1.6;
        this.drawCircle(xPos, yPos, radius, handleColor );        
        this.drawBorderedFill(klikaStartX, kridloStartY + kridloHeight / 2 - klikaHeight / 2, 
    			      klikaWidth, klikaHeight, handleColor, thickness);
      }
    },
    // Nakreslit stavebni otvor pro sestavu oken 
    drawStavebniOtvor(thickness = 1) 
     { this.drawBorderedFill( this.otvor.x, this.otvor.y, this.otvor.width, this.otvor.height, null, thickness); },
    // Drawborder - helper function to draw border around rectangle
    drawBorder(xPos, yPos, width, height, thickness = 1) 
     { 
      this.ctx.fillStyle = this.setupColors.borderColor; 
      if(thickness > 0)
       { this.ctx.fillRect( xPos - thickness, yPos - thickness, width + thickness * 2, height + thickness * 3); }
      else
       { this.ctx.fillRect( xPos, yPos, width + thickness * 0, height + thickness * 0); }
     },
    // Drawborederedfill - helper function to draw fill and border around it
    drawBorderedFill(xPos, yPos, width, height, color, thickness = 1) 
     {
      this.drawBorder(xPos, yPos, width, height, thickness); 
      this.drawFill(xPos + 1, yPos + 1, width - 2, height - 2, color); 
     },
    // Drawborederedfill - helper function to draw fill gradient and border around it
    drawBorderedFillGradient(xPos, yPos, width, height, color, color2, thickness = 1) 
     {
      this.drawBorder(xPos, yPos, width, height, thickness); 
      this.drawFillGradient(xPos + 1, yPos + 1, width - 2, height - 2, color, color2); 
     },
    /* eslint-disable */
    drawStulp(start, end, dimensions, element) {
    /* eslint-enable */
      if (element.meta.stulp.right)
        { 
         this.ctx.beginPath();   this.ctx.setLineDash([5, 2]);    this.ctx.strokeStyle = this.setupColors.borderColor; 
         this.ctx.lineWidth = 2; this.ctx.moveTo(end.x - 2, start.y);   this.ctx.lineTo(end.x - 2, end.y); 
         this.ctx.stroke();      this.ctx.setLineDash([]); 
        }
      if (element.meta.stulp.left)
        { 
         this.ctx.beginPath();   this.ctx.setLineDash([5, 2]);    this.ctx.strokeStyle = this.setupColors.borderColor; 
         this.ctx.lineWidth = 2; this.ctx.moveTo(start.x + 2, start.y); this.ctx.lineTo(start.x + 2, end.y); 
         this.ctx.stroke();      this.ctx.setLineDash([]); 
        }
     },
    /* eslint-disable */
    drawSmery(glassStartX, glassStartY, glassWidth, glassHeight, dimensions, element, dash = false ) 
     {
      /* eslint-enable*/
      this.ctx.beginPath(); this.ctx.strokeStyle = this.setupColors.borderColor; this.ctx.lineWidth = 0.5;
      // Top
      if (element.meta.smery.top)    { this.ctx.moveTo(glassStartX, glassStartY + glassHeight); this.ctx.lineTo(glassStartX + glassWidth / 2, glassStartY);  this.ctx.lineTo(glassStartX + glassWidth, glassStartY + glassHeight); }
      // Right
      if (element.meta.smery.right)  { this.ctx.moveTo(glassStartX, glassStartY); this.ctx.lineTo(glassStartX + glassWidth,glassStartY + glassHeight / 2);   this.ctx.lineTo(glassStartX, glassStartY + glassHeight); }
      // Left
      if (element.meta.smery.left)   { this.ctx.moveTo(glassStartX + glassWidth, glassStartY); this.ctx.lineTo(glassStartX, glassStartY + glassHeight / 2);  this.ctx.lineTo(glassStartX + glassWidth, glassStartY + glassHeight); }
      // Bottom
      if (element.meta.smery.bottom) { this.ctx.moveTo(glassStartX, glassStartY); this.ctx.lineTo(glassStartX + glassWidth / 2, glassStartY + glassHeight ); this.ctx.lineTo(glassStartX + glassWidth, glassStartY); }
      // Posun - left
      if (element.meta.posun.left)   { 
        this.ctx.moveTo( glassStartX + glassWidth / 4, glassStartY + glassHeight / 2);  this.ctx.lineTo( glassStartX + (glassWidth / 4) * 3, glassStartY + glassHeight / 2);
        // Sipka 1. kridlo
        this.ctx.moveTo( glassStartX + glassWidth / 4, glassStartY + glassHeight / 2);  this.ctx.lineTo( glassStartX + glassWidth / 4 + 5, glassStartY + glassHeight / 2 - 5);
        // Sipka 2. kridlo
        this.ctx.moveTo( glassStartX + glassWidth / 4, glassStartY + glassHeight / 2 ); this.ctx.lineTo( glassStartX + glassWidth / 4 + 5, glassStartY + glassHeight / 2 + 5);
      }
      // Posun right
      if (element.meta.posun.right)  {
        this.ctx.moveTo( glassStartX + (glassWidth / 4) * 3, glassStartY + glassHeight / 2); this.ctx.lineTo( glassStartX + glassWidth / 4, glassStartY + glassHeight / 2);
        // Sipka 1. kridlo
        this.ctx.moveTo( glassStartX + (glassWidth / 4) * 3, glassStartY + glassHeight / 2); this.ctx.lineTo( glassStartX + (glassWidth / 4) * 3 - 5, glassStartY + glassHeight / 2 - 5);
        // Sipka 2. kridlo
        this.ctx.moveTo( glassStartX + (glassWidth / 4) * 3, glassStartY + glassHeight / 2); this.ctx.lineTo( glassStartX + (glassWidth / 4) * 3 - 5, glassStartY + glassHeight / 2 + 5);
      } 
      if(dash) { this.ctx.strokeStyle = this.setupColors.strokeColor; this.ctx.setLineDash([2, 3]); }
      else     { this.ctx.strokeStyle = this.setupColors.strokeColor; }
      this.ctx.stroke(); this.ctx.setLineDash([]);
    },
    drawCircle( xPos, yPos, radius, color )
     { 
      this.ctx.beginPath();
      this.ctx.arc(xPos, yPos, radius, 0, 2 * Math.PI, false);

      if (typeof color === "undefined") 
       { this.ctx.fillStyle = '#ffffff'; } 
      else 
       { this.ctx.fillStyle = color; }

      this.ctx.fill();
      this.ctx.lineWidth = 1;
      this.ctx.strokeStyle = this.setupColors.borderColor;
      this.ctx.stroke();
     },
    // Drawfill - helper function to draw fill    
    drawFill(xPos, yPos, width, height, color) 
     {
      if (typeof color === "undefined") 
       { this.ctx.fillStyle = this.setupColors.ramColor; } 
      else 
       { this.ctx.fillStyle = color; }
      this.ctx.fillRect(xPos, yPos, width, height);
     },
    // Drawfill gradient - helper function to draw fill with gradient
    drawFillGradient(xPos, yPos, width, height, color, color2) 
     {
      var gradient = this.ctx.createLinearGradient(xPos, 0, xPos + width, 0);
      gradient.addColorStop( 0,   color);
      gradient.addColorStop( 0.5, color2);
      gradient.addColorStop( 1,   color);
      this.ctx.fillStyle = gradient;
      this.ctx.fillRect(xPos, yPos, width, height);
     },
    
    // Get metadata
    /* eslint-disable */
/* 07.03.2023 was moved to main.js
    getMeta(element, index) 
     {
      
      return meta;
     },
*/
  },
  props: {
    okna: {
      type: Array,
      /* eslint-disable */
      default: () => {
        return [
          {
            variation: "", 
            sirka_ramu: 14, // px
            startX: 50,
            startY: 0,
            sirka: 1000,    // mm 
            vyska: 600,     // mm
         // otvirani: 'RT', // trbl
            otvirani: { top: 1, right: 1, bottom: 0, left: 0 },
            pocetMriziVertikalnich: 3, 
            pocetMriziHorizontalnich: 2,
            sirkaMrizi: 4,  // px
            sitka: 1,       // 0/1
            zaluzie: 1,     // 0/1
            pricka: 0,      // 0/1
            texture: 0,
            textureImage: null
          }
        ];
      }
      /* eslint-enable */
    },
    barvy: []
  },
  data() {
    return {
      windowWidth : window.innerWidth,
      canvasHeight: 0,
      canvasWidth:  0,
      canvasId: "konfigurace",
      ctx: null, canvas: null,
      settings: { velikost: 200 },
      otvor: { x: 0, y: 0, width: 0, height: 0 },
      setupColors: { borderColor: "#000", ramColor: "#f4f3f4", glassColor: "#bbeefc", glassColor2: "#fff", mrizeColor: "#f7f7f7", strokeColor: "#111", },
      textures: [ 
        { src: "/static/colors/00_bila.png",            id: "bila"            },
        { src: "/static/colors/01_zlaty_dub.png",       id: "zlaty-dub"       },
        { src: "/static/colors/02_tmavy_dub.png",       id: "tmavy-dub"       },
        { src: "/static/colors/03_orech.png",           id: "orech"           },
        { src: "/static/colors/04_cokoladova.png",      id: "cokoladova"      },
        { src: "/static/colors/05_mahagon.png",         id: "mahagon"         },
        { src: "/static/colors/06_svetle_seda.png",     id: "svetle-seda"     },
        { src: "/static/colors/07_antracit.png",        id: "antracit"        },
        { src: "/static/colors/08_kremova.png",         id: "kremova"         },
        { src: "/static/colors/09_cervena.png",         id: "cervena"         },
        { src: "/static/colors/10_svetle_modra.png",    id: "svetle-modra"    },
        { src: "/static/colors/11_tmave_modra.png",     id: "tmave-modra"     },
        { src: "/static/colors/12_zelena.png",          id: "zelena"          },
        { src: "/static/colors/13_seda_hladka.png",     id: "seda-hladka"     },
        { src: "/static/colors/14_oregon.png",          id: "oregon"          },
        { src: "/static/colors/15_winchester.png",      id: "winchester"      },
        { src: "/static/colors/16_tresen.png",          id: "tresen"          },
        { src: "/static/colors/17_antracit_hladky.png", id: "antracit-hladky" }
       ]
    };
  }
};
</script>

<style lang="scss">
.control {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  border-left: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

#konfigurace {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.control__group {
  display: flex;
  width: 100%;
  & > div {
    flex: 1;
  }
  padding-top: 10px;
  padding-bottom: 10px;
  .control__label {
    padding-right: 10px;
    font-weight: bold;
    min-width: 100px;
  }
  .control__help {
    max-width: 60px;
    padding-left: 10px;
    color: #ccc;
  }
  .control__value input {
    width: 100%;
  }
}

.control .btn {
  width: 100%;
  transition: all 0.3s;
  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.textures {
  display: block;
}

.control__okno {
  border: 1px solid #eee;
  padding: 0 10px 10px 10px;
  margin-bottom: 10px;
}

.control__textures {
  display: flex;
  flex-wrap: wrap;
  .texture {
    flex: 1;
    width: 20%;
    max-width: 20%;
    min-width: 20%;
    padding: 2px;
    border: 1px solid #eee;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
    }
    &:hover,
    &.active {
      border: 1px solid #999;
    }
  }
}
</style>
