import { getField, updateField } from 'vuex-map-fields';

// Local storage data
const userCartContents = JSON.parse(
  window.localStorage.getItem('cart_contents')
);

// Item layout
const defaultItemLayout = {
  default:       true,
  count:         1,
  size:        { width: '',   height: ''  },
  variation:   { width: '',   height: '', type: '', price: '', id: '', outprice: ''},
  orientation:   'none',
  windowfaces: { back: null,  front: null },
  accessories: { 
		 inner:       null, 
		 outer:       null, 
		 blind:       null, 
		 handle:      { front: null, back: null }, 
		 other:       null, 
		 insectnet:   null, 
		 cover:       {front: null, back: null}, 
		 coverdrain:  { front: null, back: null }
	       },
  profile:       null,
  glass:         null,
  sk:		 '0',
  vk:		 '0',
  mk:		 '0',
  group2:	 '0',
  weight:	 '0'
};

if (userCartContents) {
  userCartContents.map(item => {
    return { ...defaultItemLayout, ...item };
  });
}

// initial state
const state = {
  contents: userCartContents ? userCartContents || [] : [],
  currentIndex: 0,
  item: defaultItemLayout
};

// getters
const getters = {
  currentCartItem() {
    // If there is no item, return default
    return state.contents[state.currentIndex];
  },
  getCurrentCartField(state) {
    // If there is no item, return preset
    if (!state.contents[state.currentIndex] && state.currentIndex === 0) {
      const item = JSON.parse(JSON.stringify(state.item));
      item.default = false;
      state.contents[state.currentIndex] = item;
    }
    return getField(state.contents[state.currentIndex]);
  }
};

// actions
const actions = {
  addDefaultItem({ state, commit }) {
    const item = JSON.parse(JSON.stringify(state.item));
    item.default = false;
    commit('addCartItem', item);
  }
};

// mutations
const mutations = {
  addCartItem(state, item) {
    if (!item.count) {
      item.count = 1;
    }
    state.contents.push(item);
  },
  updateCurrentCartField(state, field) {
    updateField(state.contents[state.currentIndex], field);
  },
  updateCartItem(state, originalItem, item) {
    const key = state.contents.indexOf(originalItem);
    state.contents[key] = item;
  },
  setCurrentIndex(state, index) {
    state.currentIndex = parseInt(index, 10);
  },
  removeCartItem(state, removedItem) {
    state.contents = state.contents.filter((item) => {
      return item !== removedItem;
    });
  },
  removeCartItemAll() {
    state.contents.length = 0;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
