import axios from 'axios';

// initial state
const state = {
  variations: [],
  loaded: false
};

// getters
const getters = {};

// actions
const actions = {
  loadVariations({ commit }) {
    axios.get('/wizard/public/storage/helper/assets/all.json')
         .then(response => {
          commit('updateVariations', response.data);
          commit('setVariationsLoaded');
         })
	 .catch(function (error) {
	  console.log(error);
	 });
  }
};

// mutations
const mutations = {
  updateVariations(state, variations) {

//    Sort by ORDER DESC
//    if (typeof variations === 'object') {
//          variations = Object.keys(variations).map(key => variations[key]); 
//    }

//    variations = variations.reduce(function (carrier, element) {
//        carrier.unshift(element);
//        return carrier;
//    }, []);
//    state.variations = variations;

    if (typeof variations === 'object') {
      state.variations = Object.keys(variations).map(key => variations[key]);
    } else {
      state.variations = variations;
    }
  },

  setVariationsLoaded(state) {
    state.loaded = true;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
