<template>
  <div class="container">
    <div class      	    = "colorInfoIcon"
        @mousedown          = "showInfo('top', true)"
        @touchstart.prevent = "showInfo('top', true)"
        @mouseover          = "showInfo('top')"
        @mouseleave         = "closeInfo">                                                                    
        <div class="infoTitle">Nápověda <div class="infoTitleString">Barva</div></div>
    </div>                
    <div class="colorInfo" v-show="isInfoVisible && infoProfileId == 'top'"
        @mouseover  = "showInfo('top')"
        @mouseleave = "closeInfo"
    > 
        <span @click="closeInfo" @touch="closeInfo" class="icon icon-infoClose"></span>
        <div  class="colorDetail" v-html="details"></div>
    </div>    
    <div class="titleH1">
      <h2 class="headline--padded text-center" v-html="$t('colorTitle')"></h2>
    </div>
    
    <div class="cols padded--bottom justify-center wizard-colors-main">
      <div v-for="(face, index) in availableFaces" 
           v-bind:key="index" class="color_face" >
        <h3 class="text-center color__face__title" v-html="$t(`color.title.${face}`)"></h3>

        <div class="color_face__preview" v-if="selected(face)">
          <Preview
            :okna="getOkna({ 
        		    variation, 
        		    orientation : face === 'front' ? orientation : 'none-'+orientation,
        		    width, 
        		    height,
        		    canvasWidth: 4000, 
        		    texture, 
        		    textureImage: getStorageUrl(selected(face).image),
        		    from: orientation,
        		    side: face
        		  })"
	    :barvy="colors" >
          </Preview>
	</div>


        <div class="color_face__preview" v-else>
          <Preview
            :okna="getOkna({ 
        		    variation, 
        		    orientation, 
        		    width, 
        		    height,
        		    canvasWidth: 4000,
        		    from: orientation,
        		    side: face
        		  })"
            :barvy="colors">
          </Preview>
        </div>


<!--
          <div class="color_face__palette align-center justify-center accessory__color" v-if="selected(face) !== false && selected(face).name">
            <div class="color_face__palette__image accessory__color__preview" v-if="selected(accessory) && selected(face).name && selected(accessory).color.color && selected(accessory).color.color.image && selected(accessory).color.color.image.length">
              <img :src="getStorageUrl(selected(accessory).color.color.image)">
            </div>
            <div class="color_face__palette__image accessory__color__preview" v-else-if="selected(accessory) && selected(accessory).color && selected(accessory).color.image && selected(accessory).color.image.length">
              <img :src="getStorageUrl(selected(accessory).color.image)">
            </div>
            <div class="color_face__palette__about" v-if="selected(accessory) && selected(accessory).color">
              <span @click="removeAccessory(accessory)" class="icon icon-delete delete"></span>
              <h4 class="color_face__palette__title accessories_title" v-html="selected(accessory).color.name"></h4>
              <p class="color_face__palette__property accessories_property" v-if="selected(accessory).width"  v-html="buildSentence('width',  accessory)"></p>
              <p class="color_face__palette__property accessories_property" v-if="selected(accessory).height" v-html="buildSentence('height', accessory)"></p>
              <p class="color_face__palette__property accessories_property" v-if="selected(accessory).length" v-html="buildSentence('length', accessory)"></p>
              <p class="color_face__palette__property accessories_property" v-if="selected(accessory).depth"  v-html="buildSentence('depth',  accessory)"></p>
	      <p class="color_face__palette__property covers_property"      v-if="selected(accessory).color && selected(accessory).color.covers" v-html="$t(`accessories.property.covers`)"></p>
	      <div class="color_face__palette__price" v-if="accessory !== 'handle' && accessory !== 'blind'" v-html="$t(`priceNoVatPlus`, { value: currencyFormatNum(getPriceForInner(selected(accessory).color, selected(accessory).length)) })"></div>
              <div class="color_face__palette__price" v-else-if="accessory === 'handle'" v-html="$t(`priceNoVatPlus`, { value: currencyFormatNum(getInCzk(applyDiscount(selected(accessory).color.price, 'variations'), 'EUR')) } ) "></div>
              <div class="color_face__palette__price" v-else v-html="$t(`priceNoVatPlus`, { value: currencyFormatNum(getInCzk(selected(accessory).color.price, 'EUR')) })"></div>
            </div>
          </div>
-->
        <div class="colorFacePalette align-center justify-center" 
          :class="[{'accessory__color' : selected(face) && selected(face).id !== '0'}]" v-if="selected(face)">
          <div class="color_face__palette__image" @click="selectFace(face)">
            <img :src="getStorageUrl(selected(face).image)">
          </div>
          <div class="color_face__palette__about">
            <span v-if="selected(face) && selected(face).id !== '0'" @click.self="removeColor(face)" class="icon icon-delete delete"></span>
            <h4 class="color_face__palette__title"      v-html="selected(face).name"></h4>
            <h5 class="color_face__palette__code"       v-html="selected(face).code"></h5>
            
            <div class="accessoriesBlock">
             <div class="handleBlock" v-if="typeof selectedColorsAcc(selected(face), 'handle', face, false) !=='undefined'">
               <div class="color_face__palette__handle"     v-html="$t('color.handle')" ></div>
               <div class="handleValue" v-html="selectedColorsAcc(selected(face), 'handle', face)"></div>
             </div>
	     
	     <div class="coverBlock" v-if="typeof selectedColorsAcc(selected(face), 'cover', face, false) !=='undefined'">
	       <div class="color_face__palette__cover" v-html="$t('color.cover')" ></div>
	       <div class="coverValue" v-html="selectedColorsAcc(selected(face), 'cover', face)"></div>
	     </div>
	    
	     <div class="coverDrainBlock" v-if="typeof selectedColorsAcc(selected(face), 'coverdrain', face, false) !=='undefined'">
	       <div class="color_face__palette__coverdrain" v-html="$t('color.coverdrain')"></div>
	       <div class="coverDrainValue" v-html="selectedColorsAcc(selected(face), 'coverdrain', face)"></div>
	     </div>
             <!-- <div class="color_face__palette__price" v-html="$t(`priceNoVatPlus`, { value: currencyFormatNum(getPriceForColor(selected(face), face)) })"></div>   -->
	   </div>
          
          </div>
        </div>

        <button
         v-if="!selected(face)"
         type="button"
         @click="selectFace(face)"
         class="btn btn--lg btn--primary btn--rounded"
         v-html="$t(`color.choose.${face}`)"></button>
        <button
         v-if="selected(face)"
         type="button"
         @click="selectFace(face)"
         class="btn btn--lg btn--primary btn--rounded"
         v-html="$t(`color.edit.${face}`)"></button>
      </div>
    </div>

    <!-- Confirm and continue -->
    <div class="stickyFooterAround">
      <div class="stickyFooter">
	<div class='helpFooter'>
<!--	 <div class="helpImg" @click="helpText();"></div>
	 <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div>
-->
             <div id="helpText" class="helpText" style="inline-block;"><span class="infoTel"><a href="tel:+420 776 327 222">+420 776 327 222</a></span> <br> <span class="infoMail"><a href="mailto:info@skladoken.cz">info@skladoken.cz</a></span></div>
	</div>

        <button
         @click="backButton"
         class="stickyButtonBack"
         v-html="$t('backButtonText')"></button>

        <button
         @click="confirm"
         class="stickyButton"
         v-html="$t('confirmAndContinue')"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import Preview from "@/components/Preview";

const { mapFields } = createHelpers({
  getterType: "getCurrentCartField",
  mutationType: "updateCurrentCartField"
});

export default {
  name: "WizardColor",
  components: { Preview },
  data() {
    return {
      placeholder: "/static/colors/placeholder.png",
      availableFaces: ["front", "back"],
      isInfoVisible: false,
      infoProfileId: "",
      
//      accessoryTypes: [
//        "inner",
//        "outer",
//        "handle"
//        ],    
    };
  },
  computed: {
    ...mapState({
      colors: state => state.colors.colors.slice(1),
      profiles: state => state.profiles.profiles.slice(1),
      colors_accessories: state => state.colors_accessories.colors_accessories,
//      accessoryProducts: state => state.accessories.products,
    }),
    ...mapFields("cart", [
      "size.width",
      "size.height",
      "orientation",
      "variation",
      "profile",
      "category",
      "windowfaces",
      "windowfaces.front",
      "windowfaces.back",
      
//      "accessories",
//      "accessories.handle",
//      "accessories.inner",
//      "accessories.outer",
    ]),
    details() {
     if(this.$store.state.colors.colors[0])
      { return this.$store.state.colors.colors[0].details; }
     return false;
    },
  },
  methods: {
    showInfo(id, click)
     {
      if(this.isInfoVisible == false && this.infoProfileId == "")
       {
        this.isInfoVisible  = true;
        this.infoProfileId = id;
       }
      else if (this.isInfoVisible == true && this.infoProfileId != "")
       {
        if (this.infoProfileId == id && click)
         {
          this.isInfoVisible  = false;
          this.infoProfileId  = "";
         }
        else
         {
          this.isInfoVisible  = true;
          this.infoProfileId = id;
         }
       }
      else
       {
        this.isInfoVisible  = false;
	this.infoProfileId = "";
       }
     },
    closeInfo() 
     {
      this.isInfoVisible = false;
      this.infoProfileId = "";
     },
/*
    removeColor(type) {
      var colorsArray  = [];
      var defaultColor = null;
      
      if (typeof this.colors === 'object') { colorsArray = Object.values(this.colors); } 
      else 			           { colorsArray = this.colors;                }

      colorsArray.forEach(color => {
        if ( color.id == 0 ) { defaultColor = color; }
      });

     this.windowfaces[type] = defaultColor;
     //console.log(this.windowfaces[type]);
    },
*/    
    //getPriceForColor(color, face) {
    //  return this.getProductPrice(this.variation, this.profile, this.profiles, 0.0, null, color, face, this.windowfaces.front ? this.windowfaces.front : null, this.windowfaces.back ? this.windowfaces.back : null) - this.getProductBasePrice(this.variation, this.profiles);
    //},
    
    faceImageAlias(face) {
      if (face === "front") {
        return "inner";
      }
      return "outer";
    },
    selectFace(face) 
     { 
      document.querySelectorAll('img[id^=img]').forEach((elem) => elem.remove()); /* stop  flickering canvas (clear images) */
      document.getElementById('imgLoaded').innerHTML = "0";
      this.$router.push({ name: "colorPicker", params: { face } });   
     },
    selected(face)   { return this.windowfaces[face];				       },

    selectedColorsAcc(color, type, face, showComment = false) {
     if(showComment) { console.log(type); }
     if(typeof this.getMeta(this)[type] !== 'undefined')
      {
       if(showComment) { console.log(this.getMeta(this)[type]); }
       if (typeof this.getMeta(this)[type][face] !== 'undefined')
        {
         if(showComment) {  console.log(this.getMeta(this)[type][face]); }
         if(this.getMeta(this)[type][face])
          {
           if (typeof color !== 'undefined' && color !== null)
            {
             if(showComment) { console.log('-------', face, '-------'); }
             if (typeof color[type] !== 'undefined' && color[type] !== null)
              {
               if(showComment) { console.log(color[type].select); }
               let id = color[type].select;
               let out = this.colors_accessories.filter(item => { if(item.id == id && item.type == type) { return true; } });
               if (typeof out[0] !== 'undefined' && out[0] !== null) {
                if (typeof out[0].name !== 'undefined' && out[0].name !== null)
                 { return out[0].name; }
                }
              }
             else
              {
	       if(showComment) { console.log('color ' + type + ' empty!'); }
              }
            }
          }
         else
          {
	   if(showComment) { console.log('color' + ' empty!!'); }
          }
        }
      }
     else
      {
       if(showComment) { console.log(type + ' empty!!!'); }
      }
    },
    confirm()        { this.$router.push({ name: "glass"});    			       },
    backButton()     
     { 
      // this.windowfaces.front = null;
      // this.windowfaces.back = null;
      this.$router.push({ name: "profile" });
     },
    selectFirstColor() {
      var colorsArray  = [];
      var defaultColor = null;

      if (typeof this.colors === 'object') { colorsArray = Object.values(this.colors); } 
      else 			           { colorsArray = this.colors;                }

      colorsArray.forEach(color => {
        if ( color.id == 0 ) { defaultColor = color; }
      });
      if (typeof defaultColor !== "undefined") 
       {
        this.availableFaces.forEach(face => {
          if (
            typeof this.selected(face) === "undefined" ||
            this.selected(face) === null
          ) {
             this.windowfaces[face] = defaultColor;
    	     // this.$store.commit("selectColor", { color: defaultColor, face });
          }
        });
      }
    },
//    selectFirstHandle() {
//      if (
//        this.accessoryProducts.handle &&
//        this.accessoryProducts.handle[1] &&
//        this.handle === null
//      ) {
//        const product = this.accessoryProducts.handle[1];
//        this.handle = {
//          color: product
//        };
//      }
//      else {
//        const product = this.accessoryProducts.handle[this.handle.id];
//        this.handle = {
//          color: product
//         };
//       }
//    }
  },
  mounted() {
//  this.$store.dispatch("loadAccessories", { type: "handle", callback: () => { this.selectFirstHandle(); }});
    this.$store.dispatch("loadColors", () => { this.selectFirstColor(); });
    this.$store.dispatch("loadColorsAccessories");
    this.$store.dispatch("loadCurrencies");
    this.$store.dispatch("loadProfiles");
  }
};
</script>

<style lang="scss">
.color_face {
  max-width: 350px;
  text-align: center;
  margin: 0 25px;
}

.color_face__preview img {
  max-width: 100%;
  height: auto;
}

.color_face__palette {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  text-align: left;
  & > * {
    flex: 1;
  }
}

.color_face__palette__image {
  max-width: 90px;

  img {
    width: 100%;
    height: auto;
  }
}

.color_face__palette__about {
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
  max-width: 140px;
  h4,
  strong {
    margin: 0;
    font-weight: 400;
  }
  h4 {
    margin-bottom: 20px;
  }
}

.color__face__title {
  font-weight: 400;
  margin-top: 5px;
}
</style>
