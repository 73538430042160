<template>
  <div class="categoryInfo" @click="close">
<!--    <h2 class="headline--padded text-center" v-html="$t('categoryInfoTitle')"></h2> -->

    <span @click="showInfo" class="icon icon-close"></span>

    <div class="profile__detail" v-html="category.details"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WizardCategoryInfo",
  computed: {
    ...mapState({
      categories: state => state.categories.categories
    }),
    category() {
      let categoryObject = {};
      for (var categoryKey in this.categories) {
        if (this.categories[categoryKey].id === this.infoCategoryId) {
          categoryObject = this.categories[categoryKey];
        }
      }
      return categoryObject;
    }
  },
  methods: {
    close() {
      this.isInfoVisible = false;
    }
  }
};
</script>

<style>

</style>
