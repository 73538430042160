/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["off"] */
/* eslint no-unused-vars: ["off"] */
import axios from 'axios';

// initial state
const state = {
  variations: 0,
  glass: 0
};

// getters
const getters = {};

// actions
const actions = {
  loadDiscounts({ commit }) {
    axios
      .get('/wizard/public/storage/discounts.json')
      .then(response => {
        commit('updateDiscounts', { data: response.data });
      });
  }
};

// mutations
const mutations = {
  updateDiscounts(state, { data }) {
    Object.keys(data).forEach(key => {
      state[key] = data[key];
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
