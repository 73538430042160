<template>
  <div class="container">
    <div v-show="errorMsg" class="errorAround" @click="closeError"><div class='errorShadow'></div><div class="errorWindow"><div class="cartItemDelete" @click="closeError"></div><div id="errorText" class="errorText"></div></div></div>
      <div class      	    = "sizeInfoIcon"
        @mousedown          = "showInfo('top', true)"
        @touchstart.prevent = "showInfo('top', true)"
        @mouseover          = "showInfo('top')"
        @mouseleave         = "closeInfo">
        <div class="infoTitle">Nápověda <div class="infoTitleString">Rozměry</div></div>
      </div>
      <div class="sizeInfo" v-show="isInfoVisible && infoProfileId == 'top'"
        @mouseover  = "showInfo('top')"
        @mouseleave = "closeInfo"
      >
	<span @click="closeInfo" @touch="closeInfo" class="icon icon-infoClose"></span>
	<div  class="sizeDetail" v-html="details"></div>
      </div>

    <div class="titleH1">
      <h2 class="headline--padded text-center" v-html="$t('sizeTitle')"></h2>
    </div>
    <div class="cols" v-if="variations.length > 0">
      <div class="bumper-left"></div>

      <div class="form--mini">
        <div class="form-row form-row--padded cols">
          <div class="form--mini__cell">
            <!-- Complex field -->
            <div class="complex-field complex-field--compact">
              <div class="complex-field__icon">
		<span class="sizeWidthIcon"></span>
              </div>
              <div class="complex-field__field">
                <label for="width" v-html="$t('widthLabel')"></label>
                <div class="input-group input-group--lg">
            	  <div class='helpWidth'>Zadejte šířku</div>
                  <div class="input-group__suffix">mm</div>
                  <input v-model="width" placeholder="1150" type="number" class="input input--lg input--rounded" id="width">
<!--                  <input placeholder="1150" type="text" class="input input--lg input--rounded" id="width"> -->
                </div>
                <p class="help-block" v-html="$t('widthHelp', { min: widthMin, max: widthMax })" ></p>
              </div>
            </div>
          </div>
          <div class="form--mini__cell">
            <!-- Complex field -->
            <div class="complex-field complex-field--compact">
              <div class="complex-field__icon">
		<span class="sizeHeightIcon"></span>
              </div>
              <div class="complex-field__field">
                <label for="height" v-html="$t('heightLabel')"></label>
                <div class="input-group input-group--lg">
                  <div class="input-group__suffix">mm</div>
            	  <div class='helpHeight'>Zadejte výšku</div>
                  <input v-model="height" placeholder="1150" type="number" class="input input--lg input--rounded" id="height">
<!--              <input placeholder="1150" type="text" class="input input--lg input--rounded" id="height"> -->
                </div>
                <p class="help-block" v-html="$t('heightHelp', { min: heightMin, max: heightMax })"></p>
              </div>
            </div>
            <div class='heightHelpText'>Výšku zadejte bez podkladního profilu 30 mm. Podkladní profil je součástí dodávky.</div>
          </div>
        </div>
    
        <div class="stickyFooterAround">
          <div class="stickyFooter">
	    <div class='helpFooter'>
<!--	     <div class="helpImg" @click="helpText();"></div>
	     <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div>
-->
             <div id="helpText" class="helpText" style="inline-block;"><span class="infoTel"><a href="tel:+420 776 327 222">+420 776 327 222</a></span> <br> <span class="infoMail"><a href="mailto:info@skladoken.cz">info@skladoken.cz</a></span></div>
	    </div>

            <button
            @click="backButton"
            class="stickyButtonBack"
            v-html="$t('backButtonText')"></button>

            <button
            @click="confirm()"
            class="stickyButton"
            :class="[{ 'disabled' : variationsLoaded && !isConfirmReady() }]"
            v-html="$t('confirmAndContinue')"></button>
          </div>
	</div>
      </div>
      <div class="bumper-right">
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "getCurrentCartField",
  mutationType: "updateCurrentCartField"
});

export default {
  name: "WizardSize",
  data() { 
    return { isInfoVisible: false, infoProfileId: "", errorMsg: false };
  },
  computed: {
    ...mapState("cart", ["contents"]),
    ...mapState({
      variationsLoaded: state 	=> state.variations.loaded,
      variations: 	state 	=> state.variations.variations.slice(1),
      profiles:         state   => state.profiles.profiles.slice(1),
      catminmax:        state   => state.catminmax.catminmax
    }),
    ...mapFields("cart", [
       "size.width", 
       "size.height", 
       "category",
       
       "variation",
       "profile",
       "mk",
       "vk"
    ]),
    details() {
     // console.log(this.$store.state.variations.variations[0]);
     if(this.$store.state.variations.variations[0])
      { return this.$store.state.variations.variations[0].details_size; }
     return false;
    },
    heightMin() {
      if (!this.variationsLoaded) {
        return 0;
      }
      if (typeof this.currentCartItem === "undefined" || this.currentCartItem === null) {
        return 0;
      }
      return this.propertyLimitCat(this.catminmax, "height", "min", this.currentCartItem.category);
    },
    heightMax() {
      if (!this.variationsLoaded) {
        return 0;
      }
      if (typeof this.currentCartItem === "undefined" || this.currentCartItem === null) {
        return 0;
      }
      return this.propertyLimitCat(this.catminmax, "height", "max", this.currentCartItem.category);
    },
    widthMin() {
      if (!this.variationsLoaded) {
        return 0;
      }
      if (typeof this.currentCartItem === "undefined" || this.currentCartItem === null) {
        return 0;
      }
      return this.propertyLimitCat(this.catminmax, "width", "min", this.currentCartItem.category);
    },
    widthMax() {
      if (!this.variationsLoaded) {
        return 0;
      }
      if (typeof this.currentCartItem === "undefined" || this.currentCartItem === null) {
        return 0;
      }
      return this.propertyLimitCat(this.catminmax, "width", "max", this.currentCartItem.category);
    }
  },
  // IPHONE PROBLEM POSITION FIXED
  beforeDestroy() {
    if(this.iPhone()) { window.location.reload(); }
  },
  methods: {
    select(variation) {
      // Select variation
      if (typeof variation !== 'undefined')
       {
        this.variation 		       = variation;
        this.mk	     		       = variation.mk;
        this.vk	     		       = variation.vk;
       }
    },
    isSelected(variation) {
      if (typeof this.variation === 'undefined' || this.variation === null) {
        return false;
      }
      if (typeof variation!== 'undefined')
       {
        return (
          this.variation.height === variation.height &&
          this.variation.width  === variation.width  &&
          this.variation.type   === variation.type   &&
          this.variation.price  === variation.price
         );
       }
      return false;
    },

    showInfo(id, click)
     {
      if(this.isInfoVisible == false && this.infoProfileId == "")
       {
        this.isInfoVisible  = true;
        this.infoProfileId = id;
       }
      else if (this.isInfoVisible == true && this.infoProfileId != "")
       {
        if (this.infoProfileId == id && click)
         {
          this.isInfoVisible  = false;
          this.infoProfileId  = "";
         }
        else
         {
          this.isInfoVisible  = true;
          this.infoProfileId = id;
         }
       }
      else
       {
        this.isInfoVisible  = false;
	this.infoProfileId = "";
       }
     },
    closeInfo() 
     {
      this.isInfoVisible = false;
      this.infoProfileId = "";
     },
    isConfirmReady() {
      if (
        this.width  >= this.widthMin  && this.width  <= this.widthMax &&
        this.height >= this.heightMin && this.height <= this.heightMax
      ) {
        return true;
      }
      return false;
    },
    getPageMaxScroll() {
      // Cross browser page height detection is ugly
        return Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
           ) - window.innerHeight; // Subtract viewport height
     },
    confirm() {
      let element = document.getElementsByClassName('stickyButton')[0];
      if(element.classList.contains('disabled'))
       { 
        document.getElementById("errorText").innerHTML ="";
        this.errorMsg = true; 
        if (!this.width && !this.height)
         { document.getElementById("errorText").innerHTML ="Zadejte šířku a výšku produktu v milimetrech"; }
	if (!this.width && this.height)
         { document.getElementById("errorText").innerHTML ="Zadejte šířku produktu v milimetrech";  }
	if (this.width && !this.height)
         { document.getElementById("errorText").innerHTML ="Zadejte výšku produktu v milimetrech";  }

	if (parseInt(this.width) < parseInt(this.widthMin) || parseInt(this.width) > parseInt(this.widthMax))
         { document.getElementById("errorText").innerHTML ="Zadejte šířku produktu ve výrobním rozsahu <br>"+ this.widthMin + " - " + this.widthMax  +" v milimetrech"; }
	if (parseInt(this.height) < parseInt(this.heightMin) || parseInt(this.height) > parseInt(this.heightMax))
         { document.getElementById("errorText").innerHTML ="Zadejte výšku produktu ve výrobním rozsahu <br>"+ this.heightMin + " - " + this.heightMax  +" v milimetrech"; }
       }
      if (this.isConfirmReady()) {
        let top = 1000000; // Value larger than maximum scroll IPHONE PROBLEM POSITION FIXED
        const maxScroll = this.getPageMaxScroll();
        // Fix for bug on iOS devices
        // When top was larger than maximum page scroll
        // "getBoundingClientRect" would take that value into calculations
        if (top > maxScroll) { top = maxScroll; }          
        // Scroll the window to the new position
        window.scrollTo(0, top);

        this.$router.push({ name: "variation" });
      }
    },
    closeError() {
      document.getElementById("errorText").innerHTML ="";
      this.errorMsg = false;
    },
    backButton() {
     this.variation = {}; // clear object
     this.variation.type   = "";
     this.variation.price  = "";
     this.variation.width  = "";
     this.variation.height = "";

     this.width = "";
     this.height = "";
     this.$router.push({ name: "home" });
    },

/* old
     width() { 
       if(this.isConfirmReady())
         {    
          if(typeof this.currentCartItem.variation !== 'undefined' && this.currentCartItem !== null) 
           {     
            let current = this.currentCartItem;
            if (typeof current === "undefined") { current = { count: 1 }; }
            document.getElementById('headerCurrentItemPrice').innerHTML = this.currencyFormat(this.getItemPrice('size-width',current) * (parseInt(current.count, 10) > 10 ? parseInt(current.count, 10) : current.count));
           } 
         }
       },
     height() { 
       if(this.isConfirmReady())
        {
         if(typeof this.currentCartItem.variation !== 'undefined' && this.currentCartItem !== null) 
          {     
           let current = this.currentCartItem;
           if (typeof current === "undefined") { current = { count: 1 }; }
           document.getElementById('headerCurrentItemPrice').innerHTML = this.currencyFormat(this.getItemPrice('size-height',current) * (parseInt(current.count, 10) > 10 ? parseInt(current.count, 10) : current.count));
          } 
        }
      }
*/
    },

   watch: { 
      width: {
        handler (value, oldValue) {
          if (value !== oldValue) {
//           if (value == "") { value = 0; }
//	   let oldCartItem = this.currentCartItem;	    
//           if (typeof this.variation !== "undefined")
//            { this.variation.width = parseInt(value); }
//           this.width = parseInt(value);
//           console.log(oldCartItem, this.currentCartItem);
           
           if (typeof this.variation !== "undefined")
            { 
	     let tmpVariations = this.availableVariations();
             if( tmpVariations !== false)
              {
               this.select(tmpVariations.find(element => element.id === this.variation.id));
////           this.$store.commit("cart/updateCartItem", oldValue, value);
////	       this.isSelected(this.availableVariations().find(element => element.id === this.variation.id));
////             this.$forceUpdate();        
////             this.select(this.availableVariations.find(element => element.id === this.variation.id && element.width === this.variation.width && element.height === this.variation.height));
              }
            }
          }
        },
       deep: true
      },
      height: {
        handler (value, oldValue) {
          if (value !== oldValue) {
//           if (value == "") { value = 0; }
//           if (typeof this.variation !== "undefined")
//            { this.variation.height = parseInt(value); }
//           this.height = parseInt(value);
           if (typeof this.variation !== "undefined")
            { 
	     let tmpVariations = this.availableVariations();
             if( tmpVariations !== false)
              {
               this.select(tmpVariations.find(element => element.id === this.variation.id));
              }
            }
          }
        },
       deep: true,
      },

//       variation(val, old) {
//         console.log('watched for variation');
//         if(val !== old)
//          {
//           console.log('variation changed');
//           console.log(val, old);
//           this.select(this.availableVariations.find(element => element.id === val.id)); 
//          }
//        }
    },

  mounted() { 
    this.$store.dispatch("loadCategories");
    this.$store.dispatch("loadProfiles");
    this.$store.dispatch("loadCatMinMax");
  }
};
</script>