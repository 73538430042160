import axios from 'axios';

// initial state
const state = {
  categories: [],
  selected: null
};

// getters
const getters = {};

// actions
const actions = {
  loadCategories({ commit }, callback) {
    axios
      .get(
        '/wizard/public/storage/helper/assets/categories.json'
      )
      .then(response => {
        commit('updateCategories', response.data);
        if (typeof callback === 'function') {
          callback();
        }
      });
  },
  selectCategory({ commit }, category) {
    commit('selectCategory', category);
  }
};

// mutations
const mutations = {
  updateCategories(state, categories) {
    var categoriesArray = categories;

    if (typeof categoriesArray === 'object') {
      categoriesArray = Object.values(categories);
    }

    var categoriesByPosition = categoriesArray.slice(0);

    categoriesByPosition.sort(function(a,b) {
      let x = parseInt(a.position);
      let y = parseInt(b.position);

      return x < y ? -1 : x > y ? 1 : 0;
    });

    //state.categories = categories;
    state.categories = categoriesByPosition;
  },
  selectCategory(state, category) {
    state.selected = category;
  },
  resetCategories(state) {
    state.selected = null;
  },
  setCategories(state, category) {
    state.selected = category;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
