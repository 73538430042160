import Vue from "vue";
import querystring from "querystring";
Vue.config.devtools = true

import vuexI18n from "vuex-i18n";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { mapGetters, mapState } from "vuex";
import "./gtm.js";

Vue.config.productionTip = false;

Vue.use(vuexI18n.plugin, store);

// Localization
const translationsCs = {
  remove: 		   "Odebrat",
  headerTitle: 		   "Sklad oken",
  headerHeadline: 	   "Konfigurátor oken a dveří",
  heightTooltip: 	   "Výška se udává bez podokenního profilu 30mm.",
  confirmCategory: 	   "Potvrdit kategorii",
  confirmSize: 		   "Potvrdit rozměry",
  widthLabelCat: 	   "Šířka (mm)",
  widthLabel: 		   "Šířka",
  widthHelp: 		   "({min} - {max} mm)",
  heightLabelCat:	   "Výška (mm)",
  heightLabel: 		   "Výška",
  heightHelp: 		   "({min} - {max} mm)",
  lengthLabel: 		   "Délka",
  lengthHelp: 		   "({min} - {max} mm)",
  depthLabel: 		   "Hloubka",
  depthHelp: 		   "({min} - {max} mm)",
  categoryTitle: 	   "Vyberte kategorii",
  sizeTitle: 		   "Zadejte rozměry",
  variationTitle: 	   "Vyberte produkt",
  variationSize: 	   "{width} &times; {height} mm",
  priceString: 		   "{value}",
  priceVat: 		   "{value} Kč",
  priceNoVat: 		   "{value} Kč <span class='bezDPH'>bez DPH</span>",
  priceNoVatPlus: 	   "<strong> + {value} Kč</strong> <span class='bezDPH'>bez DPH</span>",
  priceNoVatStrong: 	   "<strong> {value} Kč</strong> <span class='bezDPH'>bez DPH</span>",
  confirmVariation: 	   "Potvrdit výběr",
  orientation: 		   "Otevírání",
  orientationLeftSymbol:   "Levé",
  orientationRightSymbol:  "Pravé",
  send:			   "ODESLAT",
  sendMail:		   "POSLAT NA E-MAIL",
  titleEmailSend:	   "POSLAT NA E-MAIL",
  subTitleEmailSend:	   "Obdržíte konfiguraci oken a dveří na Váš e-mail. Konfiguraci si můžete později znovu otevřít pomocí odkazu.",
  emailPlaceholder:	   "Váš e-mail",
  namePlaceholder:	   "Jméno a příjmení",
  textPlaceholder:	   "Poznámka",
  summaryLabelName: 	   "Název:",
  summaryLabelWidth: 	   "Šířka:",
  summaryLabelHeight: 	   "Výška:",
  summaryLabelOrientation: "Otevírání:",
  summary: {
    value: {
      left: 		   "Levé",
      right: 		   "Pravé",
      none: 		   "Fixní (neotvíratelné)"
    }
  },
  changeVariation: 	   "Upravit výběr",
  stepNum: 		   "Krok {number}",
  profileTitle: 	   "Vyberte profil",
  propustnost: 		   "Koeficient prostupu tepla",
  hloubka: 		   "Stavební hloubka",
  komory: 		   "Počet komor (rám / křídlo)",
  profileTooltipLabel: 	   "Kompletní info o profilu",
  profileInfoTitle: 	   "Kompletní info o profilu",
  categoryTooltipLabel:    "info o kategorii",
  categoryInfoTitle: 	   "Kompletní info o kategorii",

  accessoryInfoTitle: 	   "Kompletní informace k doplňku",
  colorTitle: 		   "Vyberte barvu",
  profileLabel: 	   "Profil:",
  color: {
    title:  { front: 	   "Vnitřní pohled", back: "Venkovní pohled" },
    label:  { front: 	   "Vnitřní barva:", back: "Venkovní barva:" },
    front:  { title: 	   "Vnitřní barva",  handle: "Vnitřní"},
    back:   { title: 	   "Vnější barva",   handle: "Vnější"},
    choose: { front: 	   "Vybrat vnitřní barvu",  back: "Vybrat venkovní barvu"  },
    edit:   { front: 	   "Upravit vnitřní barvu", back: "Upravit venkovní barvu" },
    picker: { confirm: 	   "Potvrdit" },
    handle:		   "Klika: ",
    cover: 		   "Krytky pantů: ",
    coverdrain:		   "Krytky odtokových drážek: "
  },
  groupAll:		   "Vše",
  group1:		   "Skupina 1",
  group2:		   "Skupina 2",
  glass: { 
     title:    		   "Vyberte sklo", 
     group1:		   "DVOJSKLO",
     group2:		   "TROJSKLO",
     group3:		   "PVC PANEL"
   },
  accessories: {
    title: 		   "Vyberte doplňky",
    button: 		   "Vybrat doplněk",
    button_change: 	   "Upravit doplněk",
    inner: 		   "Vnitřní plastový parapet",
    outer: 		   "Vnější hliníkový parapet",
    blind: 		   "Hliníkové žaluzie",
    handle: 		   "Hliníkové kliky",
    other: {		   
      insectnet:	   "Sítě proti hmyzu",
      blind:		   "Horizontální žaluzie",
    },
    property: {
      length: 		   "Délka: {value} mm",
      depth: 		   "Hloubka: {value} mm",
      width: 		   "Šířka: {value} mm",
      height: 		   "Výška: {value} mm",
/*    covers: 		   "Krytky: +{value} Kč <span class='bezDPH'>bez DPH</span>" */
      covers:		   "PVC krytky v ceně"
    },
    shorts: {
      inner: 		   "Vnitřní parapet:",
      outer: 		   "Vnější parapet:",
      blind: 		   "Žaluzie:",
      handle: 		   "Klika:",
      inner_covers: 	   "Vnitřní parapet - krytky",
      outer_covers: 	   "Vnější parapet - krytky"
    }
  },
  accessoryTooltipLabel:   "Kompletní informace k doplňku",
  glassLabel: 		   "Sklo:",
  productWeight:	   "Hmotnost (Kg):",
  orderTitle: 		   "Rekapitulace a objednávka",
  table: {
    head: {
      productNumber: 	   "Výrobek",
      image: 		   "Obrázek",
      description: 	   "Popis",
      available:	   "Dostupnost",
      itemPrice: 	   "Jednotková cena",
      count: 		   "Počet",
      price: 		   "Celkem"
    },
    body: {
      productNumber: 	   "Výrobek #{value}",
      available:	   "Na objednávku 2-3 týdny"
    },
    summary: {
      price: 		   "Celkem zboží bez DPH",
      vatPrice:		   "Celkem DPH",
      fullPrice:	   "CELKEM"
    }
  },
  orderButton: 		   "Přidat do košíku",
  addAnother: 		   "Přidat další výrobek",
  addItem:		   "Přidat výrobek",
  edit: 		   "Upravit",
  delete: 		   "Smazat",
  variationTooltip:
/* '1/ P = pravé otevírání, L = levé otevírání. <br>2/ */
  "Počet kusů kalkulovaného výrobku si definujete v posledním kroku kalkulace “Rekapitulace”",
/*confirmAndContinue: '<div class="buttonText">POKRAČOVAT &nbsp;<span class="arrowRightStyle">&#9658;</span></div>', */
  confirmAndContinue: '<div class="buttonText">POKRAČOVAT &nbsp;<img class="arrowRightStyle" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAOJJREFUeNrs3EEKgzAQQNHEE3mk9ia5id6sRxktLkpBBBdtJvAeiNuQr5ONWAoAAAAAAAAAfIuItl+vOLzvza70i7HEucXu/D/GHNdE6TCqQpTDNMg6H96UPCPL+Ep0qIsiCqKIgiiiIIooooiCKKIgiiiIIooooiCKKIgiCqKIIooo3Iwy27FcUVrvtU5yYWSJ4VAXAzHEQAwxEEMMxBADMcQQQwwxxBADMcRADDEQQwzEEMOO/TaGX/wlC9LE+BjlU9K11vr0+OYYWcZUokNdjM5niZ/xAwAAAAAAAJDFJsAA2qBS4sqMJj4AAAAASUVORK5CYII="></div>',
  noItemsInCart: 'V košíku nemáte žádné výrobky, tlačítkem Přidat další výrobek přidejte výrobek',
  emptyCart: 'Nemáte žádné vybrané výrobky',
  backButtonText: '<div class="buttonText"><img class="arrowLeftStyle" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAOJJREFUeNrs3EEKgzAQQNHEE3mk9ia5id6sRxktLkpBBBdtJvAeiNuQr5ONWAoAAAAAAAAAfIuItl+vOLzvza70i7HEucXu/D/GHNdE6TCqQpTDNMg6H96UPCPL+Ep0qIsiCqKIgiiiIIooooiCKKIgiiiIIooooiCKKIgiCqKIIooo3Iwy27FcUVrvtU5yYWSJ4VAXAzHEQAwxEEMMxBADMcQQQwwxxBADMcRADDEQQwzEEMOO/TaGX/wlC9LE+BjlU9K11vr0+OYYWcZUokNdjM5niZ/xAwAAAAAAAJDFJsAA2qBS4sqMJj4AAAAASUVORK5CYII=">&nbsp;Zpět</div>'
/*  backButtonText: '<div class="buttonText"><span class="arrowLeftStyle">&#9658;</span>&nbsp;Zpět</div>' */
};

Vue.i18n.add("cs", translationsCs);
Vue.i18n.set("cs");

Vue.mixin({
  computed: {
    ...mapState(["started"]),
    ...mapState("cart", ["contents"]),
    ...mapGetters({
      currentCartItem: "cart/currentCartItem"
    }),
    discounts() {
      return this.$store.state.discounts;
    },
    currencies() {
      return this.$store.state.currencies.currencies;
    },
    colors_accessories() {
      if (this.$store.state.colors_accessories.colors_accessories)
       { return this.$store.state.colors_accessories.colors_accessories; }
      else
       { 
        if (this.$store.state.colors_accessories)
         { return this.$store.state.colors_accessories; }
       }
      return 0;
    },    
    colorsurcharge() {
      if (this.$store.state.currencies.currencies[1])
       { return this.$store.state.currencies.currencies[1].colorsurcharge; }
      return 0;
    },
    surchargebeforediscount() {
      if (this.$store.state.currencies.currencies[2])
       { return this.$store.state.currencies.currencies[2].surchargebeforediscount; }
      return 0;
    },
    profiles() {
      return this.$store.state.profiles.profiles;
    },
    categories() {
      return this.$store.state.categories;
    },
    catminmax() {
      return this.$store.state.catminmax;
    },

/* added 23.08.2022 from WizardOrder.vue */
    filteredFullCart() {
      return this.fullCart.filter(item => { return item && item.variation !== null && item.variation.type !== ""; });
    },
    fullCart() {
      return this.contents;
    },
    colorSurcharge() {
     let arQuantityFrontEnd = [];
     /* let arTmp = */
     this.filteredFullCart.filter(item => { 
       if ((item.windowfaces.front && item.windowfaces.front.group2 == "1") 
        || (item.windowfaces.back  && item.windowfaces.back.group2  == "1")) 
        {
         item.group2 = "1";
         if(item.profile !== null)
          { arQuantityFrontEnd.push([parseInt(item.profile.id), parseInt(item.windowfaces.front.id), parseInt(item.windowfaces.back.id), parseInt(item.count)]); }
         return true; 
        }
       else
        {
         item.group2 = "0";
         return false; 
        }
     });
//   console.log(arQuantityFrontEnd);

     arQuantityFrontEnd.sort();
     var out  = [];
  
     arQuantityFrontEnd.reduce(function (prev, current) {
      var p = prev.slice(0,-1); var c = current.slice(0,-1);
       if(JSON.stringify(p) == JSON.stringify(c))
        {
         current.forEach(function (curr, indx) {
          if(indx == 3)
	   { prev[indx] = (prev[indx] || 0) + curr; }
          });
        }
       else
        {
         out.push(current);
         return current;
        }
       out.push(prev);
       return prev;
      }, []);
     arQuantityFrontEnd = out.filter(function(value, index, self) { return self.indexOf(value) === index; });
     
     var surchargeQuantity = 0;
     arQuantityFrontEnd.filter(function(element) {
      if(element[3] < 15)
       { surchargeQuantity++; }
     });
//     console.log('Surcharge quantity:'+surchargeQuantity);

/*
     let arTmpCountAll = arTmp.reduce(function(sum, current) { return sum + current.count; }, 0);

     if (arTmpCountAll > 0 && arTmpCountAll < 15)
      { return true; }
     else
      { return false; }
*/
     return(surchargeQuantity);
    },
    colorSurchargeValue() {
       if (this.$store.state.currencies.currencies[1])
        { return this.$store.state.currencies.currencies[1].colorsurcharge; }
       return 0;
    },
    surchargeBeforeDiscountValue() {
       if (this.$store.state.currencies.currencies[2])
        { return this.$store.state.currencies.currencies[2].surchargebeforediscount; }
       return 0;
    },
/* -------------------------------------- */  
  },
//  watch: {
//    filteredFullCart: {
//      deep: true,
//      handler(value, oldValue) {
//        console.log('Update cartItem', oldValue, value);
//        this.$store.commit("cart/updateCartItem", oldValue, value);
//      }
//    },
//  },
  methods: {
    detailsText(type) {
      if(this.$store.state.accessories.products[type])
       {
        let out = this.$store.state.accessories.products[type];
        return out[0].details;
       }
      return "Please select";
    },
    accessoryTitle(type) {
      if(this.$store.state.accessories.products[type])
       {
        let out = this.$store.state.accessories.products[type];
        return out[0].title;
       }
    },
    accessoryImage(type) {
      if(this.$store.state.accessories.products[type])
       {
        let out = this.$store.state.accessories.products[type];
        return out[0].image;
       }
    },
    /* Preview */
    getOkna({ variation, orientation, width, height, canvasWidth, texture, textureImage, from, side, cartItemNumber }) 
     {
      let localTexture = texture;
      if (typeof texture !== "undefined" && texture !== null) { localTexture = texture; } 

      const window = {
        variation, 
        sirka_ramu:               14, // px
        startX:                   0, 
        startY:                   0,
        sirka:                    parseInt(width, 10), 
        vyska:                    parseInt(height, 10), // mm
        orientation:              orientation,
        pocetMriziVertikalnich:   0, 
        pocetMriziHorizontalnich: 0, 
        sirkaMrizi:               4, // px
        sitka:                    1, 
        zaluzie:                  0, 
        pricka:                   0, // 0/1
        texture:                  localTexture, 
        textureImage, 
        canvasWidth,
        from,
        side,
        cartItemNumber
       };
      if ( variation.type.indexOf ("trojkřídlé"  )      !== -1)    
           { return [window, window, window]; }
      if ( variation.type.indexOf ("dvojkřídlé"  )      !== -1 || 
	   variation.type.indexOf ("dvoukřídlé"  )      !== -1 || 
	   variation.type.indexOf ("dvoudílné"   )      !== -1 ||
	   variation.type.indexOf ("sklopně posuvné")   !== -1 ||
	   variation.type.indexOf ("sklopně - posuvné") !== -1 ||
	   variation.type.indexOf ("+ okno pevné")      !== -1 )   
	   { return [window, window];         }

//      console.log(" ====== getOkna Begin ====== ");
//	console.log(window);
//      console.log(" ====== getOkna End ====== ");
      
      return [window];
     },
    hasOrientation(variation) {
      return !(parseInt(variation.typenum, 10) === 0 || parseInt(variation.typenum, 10) === 1);
    },
    hasHandle(variation) {
      return !(parseInt(variation.typenum, 10) === 0);
    },
    /**
     * Currency
     */
    currencyFormat(number) {
      return `${this.currencyFormatNum(number)} Kč`;
    },
    currencyFormatNum(number) {
      return `${Math.ceil(parseFloat(number))}`;
    },
    /**
     * Money rules
     */
    countWithVat(input) {
      return input * 1.21;
    },
    countVat(input) {
      return input * 0.21;
    },
    // price with first profile (standart)

    getProductBasePrice(variation, profiles = null, source) {
      let firstKey = Object.keys(variation.profiles)[0];
      let profile_obj = profiles[firstKey];
      if (typeof profile_obj === "undefined") {
        return 0;
      }
      return this.getProductPrice(variation, profile_obj, profiles, 0.0, null, null, null, source);
    },

    getProductMinPrice(variation, profiles = null, minWidth = null, minHeight = null, source) {
      var profile_obj;
      let keys = Object.keys(variation.profiles);
      let key  = keys[0];
      profile_obj = profiles[key-1]; 

      if (typeof profile_obj === "undefined") {
        return 0;
      }
/*   return this.getProductPrice(variation, profile_obj, profiles, 0.0, null, null, null, null, null, minWidth, minHeight); */
     return this.getProductPrice(variation, profile_obj, profiles, 0.0, null, minWidth, minHeight, source);
    },
/* OLD, was used for getPriceForColor function in wizardColor.vue and wizardPickColor.vue
    getProductPrice(variation, profile = null, profiles = null, def_variable_v = 0.0, cartItem = null, color = null, face = null, cartWindowFaceFront = null, cartWindowFaceBack = null, minWidth = null, minHeight = null) 
*/
    getProductPrice(variation, profile = null, profiles = null, def_variable_v = 0.0, cartItem = null, minWidth = null, minHeight = null) 
     {
//    console.log(variation, profile, profiles, def_variable_v, cartItem, minWidth, minHeight, source);
//    if(typeof variation !== 'undefined')
//	{ return 0.0; }

      let variable_v = 0.0;
      if (def_variable_v !== null) { variable_v = this.getRealNumber( def_variable_v ); }

      let variable_s          = 0.0;
      let variable_c          = 0.0;
      let variable_d          = 1.0;
      let profile_price       = 0.0;
      let profile_price_ratio = 1.0;
      let profile_id          = 0;
      let profile_obj         = profile;

      if ((typeof profile_obj == 'undefined' || profile_obj === null) && profiles !== null) 
       {
	if (typeof variation.profiles !== 'undefined')
	 {
	  // set profile_object and profile_price according profile.id (was error products price with only 1 profile, and if this profile.id!=1 in list profiles)
          let firstKey  = Object.keys(variation.profiles)[0];
          profiles.forEach(profile => { if(profile.id == firstKey) { profile_obj = profile; } });
          profile_price = this.getRealNumber( variation.profiles[firstKey].price );
	 }
	else
	 { return 0.0; }
       } 
      else 
       {
        if(profile !== null)
         {
          profile_id = profile.id;

    	  /* for variation id=15 exist only one profile id=4 BEGIN */
          if((variation.discount[profile_id] === null || variation.profit[profile_id] === null) && profiles !== null)
           {
	    profile_id  = Object.keys(variation.profiles)[0];
            profile_obj = profiles[profile_id];
	   }
    	  /* for variation id=15 exist only one profile id=4 END */

	  profile_price_ratio = this.getRealNumber( typeof variation !== "undefined" && typeof variation.ratio    !== "undefined" && typeof variation.ratio[profile_id]    !== "undefined" ? variation.ratio[profile_id].replace(/\s/g, '') : 1 );
          profile_price       = this.getRealNumber( typeof variation !== "undefined" && typeof variation.profiles !== "undefined" && typeof variation.profiles[profile_id] !== "undefined" ? variation.profiles[profile_id].price 	  : 0 );
          profile_price       = profile_price * profile_price_ratio;
	 }
	else
	 { return 0.0;}
       }
      if (typeof profile_obj === "undefined" || typeof profile_obj.variables === "undefined")
       { return 0.0; }

//      console.log(" ====== profile_obj begin ====== ");
//      console.log(profile_obj);
//      console.log(profile_price);
//      console.log(" ====== profile_obj end ====== ");

      if (typeof cartItem    === "undefined" || cartItem === null)     			    
       { variable_d = this.getRealNumber(profile_obj.variables.D1s1); } 
      else 
       {
        if ( typeof cartItem.windowfaces !== "undefined" 
                 && cartItem.windowfaces !== null 
                 && typeof cartItem.windowfaces.front !== "undefined" 
                 && cartItem.windowfaces.front !== null 
                 && typeof cartItem.windowfaces.back !== "undefined" 
                 && cartItem.windowfaces.back !== null ) 
	 {
          if      (cartItem.windowfaces.front.id == 0 && cartItem.windowfaces.back.id == 0) 
           { 
            if(cartItem.windowfaces.front.group1 == "1" || cartItem.windowfaces.back.group1 == "1")
             { variable_d = this.getRealNumber(profile_obj.variables.D1s1); }
            if(cartItem.windowfaces.front.group2 == "1" || cartItem.windowfaces.back.group2 == "1")
             { variable_d = this.getRealNumber(profile_obj.variables.D1s2); }
//            console.log('-1');
           } 
	  else if (cartItem.windowfaces.front.id == 0 && cartItem.windowfaces.back.id != 0) 
	   { 
            if(cartItem.windowfaces.front.group1 == "1" || cartItem.windowfaces.back.group1 == "1")
	     { variable_d = this.getRealNumber(profile_obj.variables.D2s1); }
            if(cartItem.windowfaces.front.group2 == "1" || cartItem.windowfaces.back.group2 == "1")
	     { variable_d = this.getRealNumber(profile_obj.variables.D2s2); }
//	    console.log('-2');
	   }
          else if (cartItem.windowfaces.front.id != 0 && cartItem.windowfaces.back.id == 0) 
           { 
            if(cartItem.windowfaces.front.group1 == "1" || cartItem.windowfaces.back.group1 == "1")
             { variable_d = this.getRealNumber(profile_obj.variables.D3s1); 
//               console.log(profile_obj);
//               console.log('D3s1 ' + profile_obj.variables.D3s1);
             }
            if(cartItem.windowfaces.front.group2 == "1" || cartItem.windowfaces.back.group2 == "1")
             { variable_d = this.getRealNumber(profile_obj.variables.D3s2); 
//               console.log(profile_obj);
//               console.log('D3s2 ' + profile_obj.variables.D3s2);
             }
//            console.log('-3'); 
           } 
	  else if (cartItem.windowfaces.front.id != 0 && cartItem.windowfaces.back.id != 0) 
	   { 
            if(cartItem.windowfaces.front.group1 == "1" || cartItem.windowfaces.back.group1 == "1")
	     { variable_d = this.getRealNumber(profile_obj.variables.D4s1); }
            if(cartItem.windowfaces.front.group2 == "1" || cartItem.windowfaces.back.group2 == "1")
	     { variable_d = this.getRealNumber(profile_obj.variables.D4s2); }	     
//	    console.log('-4');
	   } 
	  else 
	   { 
            if(cartItem.windowfaces.front.group1 == "1" || cartItem.windowfaces.back.group1 == "1")
	     { variable_d = this.getRealNumber(profile_obj.variables.D1s1); }
            if(cartItem.windowfaces.front.group2 == "1" || cartItem.windowfaces.back.group2 == "1")
	     { variable_d = this.getRealNumber(profile_obj.variables.D1s2); }
//	    console.log('+1');
	   }
         }
	else 
         { 
          variable_d = this.getRealNumber(profile_obj.variables.D1s1);
//          console.log('+2');
         }
       }

//      console.log('--- variable_D BEGIN ---');
//        console.log(variable_d);
//      console.log('--- variable_D END ---');

/* OLD, was used for getPriceForColor function in wizardColor.vue and wizardPickColor.vue
      if (color !== null && face !== null) 
       {
//        console.log('--- color & face BEGIN ---');
//         console.log(color);
//         console.log(face);
//        console.log('--- color & face END ---');

          if (face == 'back') {
            if (color.id == 0) {
              if (cartWindowFaceFront) {
                if (cartWindowFaceFront.id == 0)  { variable_d = this.getRealNumber(profile_obj.variables.D1); } 
	        else                              { variable_d = this.getRealNumber(profile_obj.variables.D3); }
               }
              else 				
               { variable_d = this.getRealNumber(profile_obj.variables.D1); }
             }
            else {
              if (cartWindowFaceFront) {
                if (cartWindowFaceFront.id == 0)  { variable_d = this.getRealNumber(profile_obj.variables.D2); }
                else 				  { variable_d = this.getRealNumber(profile_obj.variables.D4); }
               }
	      else 				
	       { variable_d = this.getRealNumber(profile_obj.variables.D1); }
             }
           }
          else {
            if (color.id == 0) {
              if (cartWindowFaceBack) {
                if (cartWindowFaceBack.id == 0)   { variable_d = this.getRealNumber(profile_obj.variables.D1); }
	        else 			          { variable_d = this.getRealNumber(profile_obj.variables.D2); }
               }
              else 			        
               { variable_d = this.getRealNumber(profile_obj.variables.D1); }
            }
	   else {
             if (cartWindowFaceBack) {
               if (cartWindowFaceBack.id == 0) 	  { variable_d = this.getRealNumber(profile_obj.variables.D3); }
	       else 				  { variable_d = this.getRealNumber(profile_obj.variables.D4); }
              }
	     else 				
	      { variable_d = this.getRealNumber(profile_obj.variables.D1); }
            }
          }
      }
*/
     let currentWidth  = 0;
     let currentHeight = 0;      
     
     if (typeof this.currentCartItem !== "undefined" && this.currentCartItem.size !== null)
      {
       currentWidth  = this.currentCartItem.size.width ;
       currentHeight = this.currentCartItem.size.height;
      }
     if (minWidth !== null && minHeight !== null)
      {
       currentWidth  = parseInt(minWidth);
       currentHeight = parseInt(minHeight);
      }

//console.log('currentWidth', currentWidth);
//console.log('currentHeight', currentHeight);
      
//console.log('variation.formula_area = ' + variation.formula_area);
      if (variation.formula_area == "1") {
        let variable_aa = this.getRealNumber(currentWidth ) - (2 * (this.getRealNumber(profile_obj.variables.H1) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb = this.getRealNumber(currentHeight) - (2 * (this.getRealNumber(profile_obj.variables.H1) - this.getRealNumber(profile_obj.variables.Z)));
        variable_s = variable_aa * variable_bb / 1000000;

//console.log ( '----- formula_area 1 -----')
//	console.log ('variable_aa = ' + this.getRealNumber(currentWidth  ) + ' - ' + '(2*(H1=' + this.getRealNumber(profile_obj.variables.H1) + ' - Z=' + this.getRealNumber(profile_obj.variables.Z) + '))' );
//	console.log ('variable_bb = ' + this.getRealNumber(currentHeight ) + ' - ' + '(2*H1=(' + this.getRealNumber(profile_obj.variables.H1) + ' - Z=' + this.getRealNumber(profile_obj.variables.Z) + '))' );
//	console.log ('variable_s  = '  + variable_aa + ' * ' + variable_bb + ' / 1000000 =' + variable_s);

      } else if (variation.formula_area == "2") {

        let variable_aa = this.getRealNumber(currentWidth )  - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb = this.getRealNumber(currentHeight) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));        
        variable_s = variable_aa * variable_bb / 1000000;

      } else if (variation.formula_area == "3") {
        let variable_aa = this.getRealNumber(currentWidth )  - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z))) 
			        - this.getRealNumber(profile_obj.variables.H4) - (2 * (this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb = this.getRealNumber(currentHeight) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        variable_s = variable_aa * variable_bb / 1000000;

//console.log ( '----- formula_area 3 -----')
//	console.log ('variable_aa = ' + this.getRealNumber(currentWidth  ) + ' - ' + '(2*(H1=' + this.getRealNumber(profile_obj.variables.H1) + ' + H2=' + this.getRealNumber(profile_obj.variables.H2) 
//				     + ' - Z=' + this.getRealNumber(profile_obj.variables.Z) + '))' 
//                                   + ' - H4=' + this.getRealNumber(profile_obj.variables.H4) + ' - (2*(H2=' + this.getRealNumber(profile_obj.variables.H2) + ' - Z=' + this.getRealNumber(profile_obj.variables.Z) + '))');
//	console.log ('variable_bb = ' + this.getRealNumber(currentHeight ) + ' - ' + '(2*H1=(' + this.getRealNumber(profile_obj.variables.H1) + ' + H2=' + this.getRealNumber(profile_obj.variables.H2) 
//				     + ' - Z=' + this.getRealNumber(profile_obj.variables.Z) + '))' );
//	console.log ('variable_s  = '  + variable_aa + ' * ' + variable_bb + ' / 1000000' + ' => ' + variable_s);

      } else if (variation.formula_area == "4") {
        let variable_aa = this.getRealNumber(currentWidth   ) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) 
			    - this.getRealNumber(profile_obj.variables.Z))     ) - this.getRealNumber(profile_obj.variables.H3) 
			    - (2 * (this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb = this.getRealNumber(currentHeight  ) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        variable_s = variable_aa * variable_bb / 1000000;

      } else if (variation.formula_area == "5") {
        let variable_aa = this.getRealNumber(currentWidth) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z))) 
                            - this.getRealNumber(profile_obj.variables.H3) - this.getRealNumber(profile_obj.variables.H4) - (4 * (this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb = this.getRealNumber(currentHeight) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        variable_s = variable_aa * variable_bb / 1000000;

      } else if (variation.formula_area == "6") {
        let variable_aa = this.getRealNumber(currentWidth) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) 
			    - this.getRealNumber(profile_obj.variables.Z))) 
			    - (2 *this.getRealNumber(profile_obj.variables.H3)) - (4 * (this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb = this.getRealNumber(currentHeight) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        variable_s = variable_aa * variable_bb / 1000000;

      } else if (variation.formula_area == "7") {
        let variable_aa = this.getRealNumber(currentWidth) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb = this.getRealNumber(currentHeight) - this.getRealNumber(profile_obj.variables.H1) - this.getRealNumber(profile_obj.variables.H2) 
			    - this.getRealNumber(profile_obj.variables.H5) + (2 * this.getRealNumber(profile_obj.variables.Z));
        variable_s = variable_aa * variable_bb / 1000000;

      } else if (variation.formula_area == "8") {
        let variable_aa = this.getRealNumber(currentWidth) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) 
                            - this.getRealNumber(profile_obj.variables.Z))) - this.getRealNumber(profile_obj.variables.H4) 
                            - (2 * (this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb = this.getRealNumber(currentHeight) - this.getRealNumber(profile_obj.variables.H1) - this.getRealNumber(profile_obj.variables.H2) 
                            - this.getRealNumber(profile_obj.variables.H5) + (2 * this.getRealNumber(profile_obj.variables.Z));
        variable_s = variable_aa * variable_bb / 1000000;

//console.log ( '----- formula_area 8 -----')
//	console.log ('variable_aa = ' + this.getRealNumber(currentWidth  ) + ' - ' + '(2*(H1=' + this.getRealNumber(profile_obj.variables.H1) + ' + H2=' + this.getRealNumber(profile_obj.variables.H2) 
//				     + ' - Z=' + this.getRealNumber(profile_obj.variables.Z) + '))' + ' - H4=' + this.getRealNumber(profile_obj.variables.H4) 
//				     + ' - (2*(H2=' + this.getRealNumber(profile_obj.variables.H2) + ' - Z=' + this.getRealNumber(profile_obj.variables.Z) + '))');
//	console.log ('variable_bb = ' + this.getRealNumber(currentHeight ) + ' - ' + 'H1=' + this.getRealNumber(profile_obj.variables.H1) + ' - H2=' + this.getRealNumber(profile_obj.variables.H2) 
//				     + ' - H5= ' + this.getRealNumber(profile_obj.variables.H5) + ' + (2 * Z=' + this.getRealNumber(profile_obj.variables.Z) + ')' );
//	console.log ('variable_s  = '  + variable_aa + ' * ' + variable_bb + ' / 1000000' + ' => ' + variable_s);

      } else if (variation.formula_area == "9") {
        let variable_aa = this.getRealNumber(currentWidth) - (2 * (this.getRealNumber(profile_obj.variables.H6) + this.getRealNumber(profile_obj.variables.H7) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb = this.getRealNumber(currentHeight) - this.getRealNumber(profile_obj.variables.H6) 
                            - this.getRealNumber(profile_obj.variables.H7) - this.getRealNumber(profile_obj.variables.H8) 
                            + (2 * this.getRealNumber(profile_obj.variables.Z));
        variable_s = variable_aa * variable_bb / 1000000;

      } else if (variation.formula_area == "10") {
        let variable_aa = this.getRealNumber(currentWidth) - (2 * (this.getRealNumber(profile_obj.variables.H6) + this.getRealNumber(profile_obj.variables.H7) 
                           - this.getRealNumber(profile_obj.variables.Z))) - this.getRealNumber(profile_obj.variables.H9) 
                           - (2 * (this.getRealNumber(profile_obj.variables.H7) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb = this.getRealNumber(currentHeight) - this.getRealNumber(profile_obj.variables.H6) - this.getRealNumber(profile_obj.variables.H7) 
			   - this.getRealNumber(profile_obj.variables.H8) + (2 * this.getRealNumber(profile_obj.variables.Z));
        variable_s = variable_aa * variable_bb / 1000000;

//console.log ( '----- formula_area 10 -----')
//	console.log ('variable_aa = ' + this.getRealNumber(currentWidth  ) + ' - ' + '(2*(H6=' + this.getRealNumber(profile_obj.variables.H6) + ' + H7=' + this.getRealNumber(profile_obj.variables.H7) 
//				     + ' - Z=' + this.getRealNumber(profile_obj.variables.Z) + '))' 
//                                   + ' - H9=' + this.getRealNumber(profile_obj.variables.H9) + ' - (2*(H7=' + this.getRealNumber(profile_obj.variables.H7) + ' - Z=' + this.getRealNumber(profile_obj.variables.Z) + '))');
//	console.log ('variable_bb = ' + this.getRealNumber(currentHeight ) + ' - ' + 'H6=(' + this.getRealNumber(profile_obj.variables.H6) + ' - H7=' + this.getRealNumber(profile_obj.variables.H7) 
//				    + ' - H8=' + this.getRealNumber(profile_obj.variables.H8) + ' + 2*Z=' + this.getRealNumber(profile_obj.variables.Z) + ')' );
//	console.log ('variable_s  = '  + variable_aa + ' * ' + variable_bb + ' / 1000000' + ' => ' + variable_s);

      } else if (variation.formula_area == "11") {
        let variable_aa1 = (this.getRealNumber(currentWidth) / 2) - this.getRealNumber(profile_obj.variables.H1) - (this.getRealNumber(profile_obj.variables.H3) / 2) 
				+ (2 * this.getRealNumber(profile_obj.variables.Z));
        
	let variable_bb1 = this.getRealNumber(currentHeight) - (2 * (this.getRealNumber(profile_obj.variables.H1) - this.getRealNumber(profile_obj.variables.Z)));
        
	let variable_aa2 = (this.getRealNumber(currentWidth) / 2) - this.getRealNumber(profile_obj.variables.H1) - (this.getRealNumber(profile_obj.variables.H3) / 2) 
				- (2 * (this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb2 = this.getRealNumber(currentHeight) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        
	variable_s = ((variable_aa1 * variable_bb1) + (variable_aa2 * variable_bb2)) / 1000000;

      } else if (variation.formula_area == "12") {
        let variable_aa1 = (this.getRealNumber(currentWidth) / 2) - this.getRealNumber(profile_obj.variables.H1) - (this.getRealNumber(profile_obj.variables.H3) / 2) 
			    + (2 * this.getRealNumber(profile_obj.variables.Z));
        let variable_bb1 = this.getRealNumber(currentHeight) - (2 * (this.getRealNumber(profile_obj.variables.H1) - this.getRealNumber(profile_obj.variables.Z)));

        let variable_aa2 = (this.getRealNumber(currentWidth) / 2) - this.getRealNumber(profile_obj.variables.H1) - (this.getRealNumber(profile_obj.variables.H3) / 2) 
			    - (2 * (this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        let variable_bb2 = this.getRealNumber(currentHeight) - (2 * (this.getRealNumber(profile_obj.variables.H1) + this.getRealNumber(profile_obj.variables.H2) - this.getRealNumber(profile_obj.variables.Z)));
        variable_s = ((variable_aa1 * variable_bb1) + (variable_aa2 * variable_bb2)) / 1000000;
      }
//console.log('variation.formula_price = ' + variation.formula_price);
      if (variation.formula_price == "1") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) + this.getRealNumber(profile_obj.variables.T);
//console.log('------- formula price 1 ---------');
//	  console.log('PRED SLEVOU variable_c = ((' + 'profile_price=' + profile_price + ' * variable_d=' + this.getRealNumber(variable_d) + ') + (P=' + this.getRealNumber(profile_obj.variables.P) + ' * ' 
//				     + this.getRealNumber(currentWidth) + ' / 1000)' + ' + (variable_v=' + variable_v + ' * variable_s=' + variable_s + ') + T=' 
//				     + this.getRealNumber(profile_obj.variables.T) + ') => ' + variable_c + '; SLEVA + MARZE => ' + this.applyDiscount(this.applySingleDiscountProfit(variable_c, variation, profile, profile_obj), "variations") 
//				     + '; CZK => ' + this.getNumber(this.getInCzk(this.applyDiscount(this.applySingleDiscountProfit(variable_c, variation, profile, profile_obj), "variations"), "EUR"))
//				     + ' * (surchargeBeforeDiscount='+ parseFloat(this.surchargebeforediscount) +'+100)/100' );

      } else if (variation.formula_price == "2") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) + this.getRealNumber(profile_obj.variables.K) + this.getRealNumber(profile_obj.variables.T);

      } else if (variation.formula_price == "3") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) + this.getRealNumber(profile_obj.variables.K) + this.getRealNumber(profile_obj.variables.T);

      } else if (variation.formula_price == "4") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) + this.getRealNumber(profile_obj.variables.K) + this.getRealNumber(profile_obj.variables.T);

//console.log('------- formula price 4 ---------');
//	  console.log('PRED SLEVOU variable_c = ((' + 'profile_price=' + profile_price + ' * variable_d=' + this.getRealNumber(variable_d) + ') + (P=' + this.getRealNumber(profile_obj.variables.P) + ' * ' 
//				     + this.getRealNumber(currentWidth) + ' / 1000)' + ' + (variable_v=' + variable_v + ' * variable_s=' + variable_s + ') + K=' + this.getRealNumber(profile_obj.variables.K) + ' + T=' 
//				     + this.getRealNumber(profile_obj.variables.T) + ') => ' + variable_c + '; SLEVA + MARZE => ' + this.applyDiscount(this.applySingleDiscountProfit(variable_c, variation, profile, profile_obj), "variations") 
//				     + '; CZK => ' + this.getNumber(this.getInCzk(this.applyDiscount(this.applySingleDiscountProfit(variable_c, variation, profile, profile_obj), "variations"), "EUR"))
//				     + ' * (surchargeBeforeDiscount='+ parseFloat(this.surchargebeforediscount) +'+100)/100' );

      } else if (variation.formula_price == "5") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) + (2 * this.getRealNumber(profile_obj.variables.K)) + this.getRealNumber(profile_obj.variables.T);

      } else if (variation.formula_price == "6") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) + this.getRealNumber(profile_obj.variables.OSS) + this.getRealNumber(profile_obj.variables.ST) + (2 * this.getRealNumber(profile_obj.variables.K)) + this.getRealNumber(profile_obj.variables.T);

      } else if (variation.formula_price == "7") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) + (2 * this.getRealNumber(profile_obj.variables.OSS)) + (3 * this.getRealNumber(profile_obj.variables.K)) + this.getRealNumber(profile_obj.variables.T);

      } else if (variation.formula_price == "8") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) 
+ this.getRealNumber(profile_obj.variables.K) + this.getRealNumber(profile_obj.variables.T) + this.getRealNumber(profile_obj.variables.M) + this.getRealNumber(profile_obj.variables.OS);

      } else if (variation.formula_price == "9") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) 
+ this.getRealNumber(profile_obj.variables.K) + this.getRealNumber(profile_obj.variables.T) + this.getRealNumber(profile_obj.variables.M) + this.getRealNumber(profile_obj.variables.OS);

      } else if (variation.formula_price == "10") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (profile_price * this.getRealNumber(profile_obj.variables.VZ)) 
+ (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) + this.getRealNumber(profile_obj.variables.T);

      } else if (variation.formula_price == "11") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.AL) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) 
+ this.getRealNumber(profile_obj.variables.T) + this.getRealNumber(profile_obj.variables.OK);

      } else if (variation.formula_price == "12") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.AL) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) 
+ this.getRealNumber(profile_obj.variables.T) + this.getRealNumber(profile_obj.variables.OKD);

      } else if (variation.formula_price == "13") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (variable_v * variable_s) + this.getRealNumber(profile_obj.variables.T);

      } else if (variation.formula_price == "14") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (variable_v * variable_s) + this.getRealNumber(profile_obj.variables.T);
	  
//console.log('------- formula price 14 ---------');
//	  console.log('PRED SLEVOU variable_c = ((' + 'profile_price=' + profile_price + ' * variable_d=' + this.getRealNumber(variable_d) + ') + T=' + this.getRealNumber(profile_obj.variables.T)
//				     + ') => ' + variable_c + '; SLEVA + MARZE => ' + this.applyDiscount(this.applySingleDiscountProfit(variable_c, variation, profile, profile_obj), "variations") 
//				     + '; CZK => ' + this.getNumber(this.getInCzk(this.applyDiscount(this.applySingleDiscountProfit(variable_c, variation, profile, profile_obj), "variations"), "EUR"))
//				     + ' * (surchargeBeforeDiscount='+ parseFloat(this.surchargebeforediscount) +'+100)/100' );
      
      } else if (variation.formula_price == "15") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) 
+ this.getRealNumber(profile_obj.variables.OK) + this.getRealNumber(profile_obj.variables.T) + this.getRealNumber(profile_obj.variables.OS);
      
      } else if (variation.formula_price == "16") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.P) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) 
+ this.getRealNumber(profile_obj.variables.OKD) + this.getRealNumber(profile_obj.variables.T) + this.getRealNumber(profile_obj.variables.OS);
      
      } else if (variation.formula_price == "17") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.ALP) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) 
+ this.getRealNumber(profile_obj.variables.OK) + this.getRealNumber(profile_obj.variables.T) + 3 * this.getRealNumber(profile_obj.variables.JJ) + this.getRealNumber(profile_obj.variables.KD);
      
      } else if (variation.formula_price == "18") {
        variable_c = (profile_price * this.getRealNumber(variable_d)) + (this.getRealNumber(profile_obj.variables.ALP) * this.getRealNumber(currentWidth) / 1000) + (variable_v * variable_s) 
+ this.getRealNumber(profile_obj.variables.OKD) + this.getRealNumber(profile_obj.variables.T) + 6 * this.getRealNumber(profile_obj.variables.JJ) + this.getRealNumber(profile_obj.variables.KD);


//console.log('------- formula price 18 ---------');
//	  console.log('PRED SLEVOU variable_c = ((' + 'profile_price=' + profile_price + ' * variable_d=' + this.getRealNumber(variable_d) + ') + (ALP=' + this.getRealNumber(profile_obj.variables.ALP) + ' * ' 
//				     + this.getRealNumber(currentWidth) + ' / 1000)' + ' + (variable_v=' + variable_v + ' * variable_s=' + variable_s + ') + OKD=' + this.getRealNumber(profile_obj.variables.OKD) 
//				     + ' + T=' + this.getRealNumber(profile_obj.variables.T) + ' + 6 * JJ=' + this.getRealNumber(profile_obj.variables.JJ) + ' + OKD=' + this.getRealNumber(profile_obj.variables.OKD) 
//				     + ') => ' + variable_c + '; SLEVA + MARZE => ' + this.applyDiscount(this.applySingleDiscountProfit(variable_c, variation, profile, profile_obj), "variations") 
//				     + '; CZK => ' + this.getNumber(this.getInCzk(this.applyDiscount(this.applySingleDiscountProfit(variable_c, variation, profile, profile_obj), "variations"), "EUR"))
//				     + ' * (surchargeBeforeDiscount='+ parseFloat(this.surchargebeforediscount) +'+100)/100' );

      }
      variable_c = variable_c * ((parseFloat(this.surchargebeforediscount) + 100)/100);
//      console.log('outPrice =>' + this.getNumber(this.getInCzk(this.applyDiscount(this.applySingleDiscountProfit(variable_c, variation, profile, profile_obj), "variations"), "EUR")))
      return this.getNumber(this.getInCzk(this.applyDiscount(this.applySingleDiscountProfit(variable_c, variation, profile, profile_obj), "variations"), "EUR"));
     },

    applySingleDiscountProfit(price, variation, profile, profile_obj) {
      let idProfile = profile_obj.id;
//      if (typeof profile == 'undefined' || profile === null) 
//       { idProfile = 1; } 
//      else 
//       { idProfile = parseInt( profile.id ); }
      
/* if no profile with Id=1 for this variation */
//      console.log("--- variation discount profit start ---");
//       console.log(variation.discount[idProfile]);
//       console.log(variation.profit[idProfile]);       
//      console.log("--- variation discount profit end ---");

//      if(variation.discount[idProfile] === null || variation.profit[idProfile] === null)
//       {
//        /* set idProfile array to position = array profiles (index + 1) */
//        //old 17.02.2023 -  idProfile = profile_obj.position - 1;         
//        idProfile = profile_obj.position;
//       }

//      console.log("======== Discount Profit Begin ======== ");     
//       console.log(profile);
//       console.log(profile_obj);
//       console.log(idProfile);
//       console.log(variation.discount);
//       console.log(variation.profit);	
//      console.log("======== Discount Profit End ======== ");

      let discountPrice = 0;
      discountPrice = this.getRealNumber( variation.discount[idProfile] );
      let profitPrice = 0;
      profitPrice   = this.getRealNumber( variation.profit[idProfile] );

      const discountRatio = (100 - discountPrice) * 0.01;      
      const profitRatio   = (100 + profitPrice  ) * 0.01;

//      console.log('('+ price +' * '+ discountRatio + ') * ' + profitRatio +' = '+ (price * discountRatio) * profitRatio);
      return (price * discountRatio) * profitRatio;
    },
    applyDiscount(price, type) {
      let discount = 0;
      if (typeof this.discounts[type] !== "undefined") {
        discount = parseFloat(this.discounts[type]);
      }
      const ratio = (100 - discount) * 0.01;
      return price * ratio;
    },

    getVariationPrice(variation) {
      let basePrice = 0;
/*
      // Special rules
      if (variation.typenum === 6) {
        basePrice =
          variation && variation.price
            ? this.getNumber(
                this.getInCzk(
                  this.applyDiscount(variation.price, "variations"),
                  "EUR"
                ) + this.getInCzk(19.76, "EUR")
              )
            : 0;
      } else if (variation.typenum === 7) {
        basePrice =
          variation && variation.price
            ? this.getNumber(
                this.getInCzk(
                  this.applyDiscount(variation.price, "variations"),
                  "EUR"
                ) +
                  this.getInCzk(19.76, "EUR") +
                  this.getInCzk(170, "PLN")
              )
            : 0;
      } else if (variation.typenum === 8) {
        basePrice =
          variation && variation.price
            ? this.getNumber(
                this.getInCzk(
                  this.applyDiscount(variation.price, "variations"),
                  "EUR"
                ) + this.getInCzk(19.76, "EUR")
              )
            : 0;
      } else {
*/
        basePrice = variation && variation.price 
	? this.getNumber(this.getInCzk(this.applyDiscount(this.applySingleDiscountProfit(variation.price, variation), "variations"), "EUR")) : 0;
//    }
      return basePrice;
    },
    getInCzk(value, currencyCode) {
      const currency = this.currencies.find(
        element => element.currency === currencyCode
      );
      if (currency && currency.rate) {

	 // console.log( parseFloat(String(value).replace(/,/, ".")) + ' * ' + currency.rate);

	 return (
          parseFloat(String(value).replace(/,/, ".")) *
          parseFloat(String(currency.rate).replace(/,/, "."))
        );
      }
      return 0;
    },
    getNumberOfHandles(item) {
      let numberOfHandles = 1;

      // If no item is selected
      if (!item) {
        return numberOfHandles;
      }

      // If no variation is selected
      if (!item.variation) {
        return numberOfHandles;
      }

      if      (item.variation.typenum === 0) { numberOfHandles = 0; } 
      else if (item.variation.typenum === 4) { numberOfHandles = 2; } 
      else if (item.variation.typenum === 6) { numberOfHandles = 2; } 
      else if (item.variation.typenum === 7) { numberOfHandles = 3; } 
      return numberOfHandles;
    },
    
    getHandlePrice(item) {
      if (
        item &&
        item.accessories &&
        item.accessories.handle &&
        item.accessories.handle.color &&
        item.accessories.handle.color.price
      ) {
        const numberOfHandles = this.getNumberOfHandles(item);

        return (
          this.getInCzk(
            this.applyDiscount(
              item.accessories.handle.color.price,
              "variations"
            ),
            "EUR"
          ) * numberOfHandles
        );
      }
      return 0;
    },
    getItemPriceHeader(source, cartItem, quantity) {
      if (typeof cartItem === "undefined" || cartItem === null) {
        return 0;
      }
//      console.log('source', source);
      const basePrice = this.getProductPrice(cartItem.variation, 
					     cartItem.profile, 
					     this.profiles.length > 0 ? this.profiles : null, 
					     cartItem.glass ? cartItem.glass.price : 0, 
					     cartItem,  
                                             cartItem.size.width  ? cartItem.size.width  : cartItem.width,
                                    	     cartItem.size.height ? cartItem.size.height : cartItem.height
					     );
      const blindPrice =
        cartItem.accessories &&
        cartItem.accessories.blind &&
        cartItem.accessories.blind.color &&
        cartItem.accessories.blind.color.price
          ? this.getInCzk(
              this.applyDiscount(
                cartItem.accessories.blind.color.price,
                "variations"
              ),
              "EUR"
            )
          : 0;

      const handlePrice = this.getHandlePrice(cartItem);
      
      //console.log(cartItem);
      
      const innerHandlePrice =
        cartItem.accessories &&
        cartItem.accessories.inner &&
        cartItem.accessories.inner.color &&
        cartItem.accessories.inner.color.covers
          ? this.getPriceForInner(cartItem.accessories.inner.color, cartItem.accessories.inner.length)
          : 0;
      const outerHandlePrice =
        cartItem.accessories &&
        cartItem.accessories.outer &&
        cartItem.accessories.outer.color &&
        cartItem.accessories.outer.color.covers
          ? this.getPriceForInner(cartItem.accessories.outer.color, cartItem.accessories.outer.length)
          : 0;

      // console.log(basePrice, blindPrice,  handlePrice, innerHandlePrice,  outerHandlePrice);

      const fullPrice =
        basePrice  +
        blindPrice +
        handlePrice +
        innerHandlePrice +
        outerHandlePrice;

      return fullPrice * (quantity || 1);
    },

    getItemPriceOrder(cartItem, quantity, itemWidth, itemHeight, source) {
      if (typeof cartItem === "undefined" || cartItem === null) {
        return 0;
      }
      const basePrice = Math.ceil(this.getProductPrice(cartItem.variation, 
					     cartItem.profile, 
					     this.profiles.length > 0 ? this.profiles : null, 
					     cartItem.glass ? cartItem.glass.price : 0, 
					     cartItem,  
					     itemWidth, 
					     itemHeight,
					     source));
      const blindPrice =
        cartItem.accessories &&
        cartItem.accessories.blind &&
        cartItem.accessories.blind.color &&
        cartItem.accessories.blind.color.price
          ? this.getInCzk(
              this.applyDiscount(
                cartItem.accessories.blind.color.price,
                "variations"
              ),
              "EUR"
            )
          : 0;

      const handlePrice = this.getHandlePrice(cartItem);
      const innerHandlePrice =
        cartItem.accessories &&
        cartItem.accessories.inner &&
        cartItem.accessories.inner.color &&
        cartItem.accessories.inner.color.covers
          ? this.getPriceForInner(cartItem.accessories.inner.color, cartItem.accessories.inner.length)
          : 0;
      const outerHandlePrice =
        cartItem.accessories &&
        cartItem.accessories.outer &&
        cartItem.accessories.outer.color &&
        cartItem.accessories.outer.color.covers
          ? this.getPriceForInner(cartItem.accessories.outer.color, cartItem.accessories.outer.length)
          : 0;

      const fullPrice =
        basePrice  +
        blindPrice +
        handlePrice +
        innerHandlePrice +
        outerHandlePrice;
        
      return Math.ceil(fullPrice) * (quantity || 1);
    },

    getItemWeight(cartItem, quantity) {
     // console.log(cartItem);
      if (!quantity) { quantity = 1; }
      
      if (!cartItem.size)    { return false; }
      if (!cartItem.profile) { return false; }
       
      let productVolume = parseFloat(cartItem.size.width)/1000 * parseFloat(cartItem.size.height)/1000 * parseFloat(cartItem.profile.hloubka)/1000;

      if(parseFloat(cartItem.vk.replace(",", ".")) != 0) // check for division by zero
       {
        let productWeight = ((productVolume * parseFloat(cartItem.mk.replace(",", ".")))/parseFloat(cartItem.vk.replace(",", "."))) * (parseFloat(cartItem.sk.replace(",", ".")) + 100) / 100;
      
        if(productWeight > 0)
         { 
          // return Math.round((productWeight + Number.EPSILON) *100) / 100; 
          // Nize variant tipa okrugliaet (do 2 chisel posle zapiatoi) bez oshibok
          
          let resultWeight = Math.round(Number((Math.abs(productWeight)*100).toPrecision(15)))/100*Math.sign(productWeight) * quantity;
          cartItem.weight = resultWeight;
	  // console.log(cartItem);
          return resultWeight;
         }
        else
         { return false; }
       }
      else
       { return false; }
    },


    getItemPrice(source, cartItem, quantity) {
//    console.log(cartItem);

      if (typeof this === "undefined" || this === null) {
        return 0;
      }
      if (typeof this.profiles === "undefined") {
        return 0;
      }
      if (typeof cartItem === "undefined" || cartItem === null) {
        return 0;
      }
      if (typeof cartItem.size === "undefined" || cartItem.size === null) {
        return 0;
      }
//  console.log('source', source);
      const basePrice = this.getProductPrice(cartItem.variation, 
					     cartItem.profile, 
					     this.profiles.length > 0 ? this.profiles : null, 
					     cartItem.glass ? cartItem.glass.price : 0, 
					     cartItem,
                                             cartItem.size.width  ? cartItem.size.width  : cartItem.width,
                                    	     cartItem.size.height ? cartItem.size.height : cartItem.height,
					     source);
//      console.log(cartItem.variation, 
//		  cartItem.profile, 
//		  this.profiles.length > 0 ? this.profiles : null, 
//		  cartItem.glass ? cartItem.glass.price : 0, 
//		  cartItem,
//                cartItem.size.width  ? cartItem.size.width  : cartItem.width,
//                cartItem.size.height ? cartItem.size.height : cartItem.height );

      const blindPrice =
        cartItem.accessories &&
        cartItem.accessories.blind &&
        cartItem.accessories.blind.color &&
        cartItem.accessories.blind.color.price
          ? this.getInCzk(
              this.applyDiscount(
                cartItem.accessories.blind.color.price,
                "variations"
              ),
              "EUR"
            )
          : 0;

      const handlePrice = this.getHandlePrice(cartItem);



      const innerHandlePrice =
        cartItem.accessories &&
        cartItem.accessories.inner &&
        cartItem.accessories.inner.color &&
        cartItem.accessories.inner.color.covers
          ? this.getPriceForInner(cartItem.accessories.inner.color, cartItem.accessories.inner.length)
          : 0;
      const outerHandlePrice =
        cartItem.accessories &&
        cartItem.accessories.outer &&
        cartItem.accessories.outer.color &&
        cartItem.accessories.outer.color.covers
          ? this.getPriceForInner(cartItem.accessories.outer.color, cartItem.accessories.outer.length)
          : 0;



      const fullPrice =
        basePrice +
        blindPrice +
        handlePrice +
        innerHandlePrice +
        outerHandlePrice;

      return fullPrice * (quantity || 1);
    },
    
    getCurrentDecorationPrice(decoration, face) {
      let ratio = 0;
      let type = "inner";
      if (face === "front") {
        type = "inner";
      } else if (face === "back") {
        type = "outer";
      }
      if (decoration && decoration[`${type}_ratio`]) {
        ratio = decoration[`${type}_ratio`] * 0.01;
      }

      return this.variation && this.variation.price
        ? this.getNumber(
            this.getInCzk(
              this.applyDiscount(this.variation.price, "variations"),
              "EUR"
            )
          ) * ratio
        : 0;
    },
    getDecorationPrice(item, face) {
      let ratio = 0;
      let type = "inner";
      if (face === "front") {
        type = "inner";
      } else if (face === "back") {
        type = "outer";
      }
      if (
        item.windowfaces &&
        item.windowfaces[face] &&
        item.windowfaces[face][`${type}_ratio`]
      ) {
        ratio = item.windowfaces[face][`${type}_ratio`] * 0.01;
      }

      return item.variation && item.variation.price
        ? this.getNumber(
            this.getInCzk(
              this.applyDiscount(item.variation.price, "variations"),
              "EUR"
            )
          ) * ratio
        : 0;
    },
    getGlassPriceOfItem(variation, pricePer) {
      const widthInMeters  = variation.width  / 1000;
      const heightInMeters = variation.height / 1000;
      const size = widthInMeters * heightInMeters;
      return this.applyDiscount(size * pricePer, "glass");
//    return this.applyDiscount(this.applySingleDiscountProfit(size * pricePer, variation), "glass");  
    },
    getGlassPrice(pricePer) {
      return this.currentCartItem ? this.getGlassPriceOfItem(this.currentCartItem.variation, pricePer) : 0;
    },
    /**
     * Numbers
     */

    propertyLimit(collection, property, type) 
     {
      if (typeof collection !== "undefined" && collection !== null) 
       {
        const item = collection.reduce((prev, curr) => 
	 {
          if (type === "min")
	   {
            return parseInt(prev[property]) < parseInt(curr[property]) ? prev : curr;
           }
          return parseInt(prev[property]) > parseInt(curr[property]) ? prev : curr;
         });
        if (item && item[property]) 
	 {
          return item[property];
         }
       }
      return 0;
     },
    findMin(arr, prop)
     {
      if(Array.isArray(arr) && arr.length !== 0)
       {
        let min = arr[0];
        let len = arr.length;

        for (let i = 1; i < len; i++) 
         {
          let v = arr[i];
          min = (v[prop] < min[prop]) ? v : min;
          // console.log(min);
         }
        return min[prop];
       }
     },
    findMax(arr, prop)
     {
      if(Array.isArray(arr) && arr.length !== 0)
       {
        let max = arr[0];
        let len = arr.length;

        for (let i = 1; i < len; i++) 
         {
          let v = arr[i];
          max = (v[prop] > max[prop]) ? v : max;
          //console.log(max);
         }
        return max[prop];
       }
     },
    propertyLimitCat(collection, property, type, category) 
     {
      let minWidth  = 0; let maxWidth  = 0;
      let minHeight = 0; let maxHeight = 0;

      if ((typeof collection !== "undefined" && collection !== null) && (typeof category !=='undefined' && category!== null)) 
       {
	let categoryId = category.id;
	let output = collection.filter(function(m) { return m["category"] == categoryId; });

        output.forEach(
	  function(arr)
           {
	    if(arr.category == categoryId)
	     {
	      if (property === 'width')
               {
                if (type === "min") { minWidth = parseInt(arr.minWidth); }
	        maxWidth = parseInt(arr.maxWidth);
	       }
	      if (property === 'height')
               {
                if (type === "min") { minHeight = parseInt(arr.minHeight); }
	        maxHeight = parseInt(arr.maxHeight);
	       }
	     }
	   }
	 );
        if(minWidth  != 0) { return minWidth; } if(maxWidth  != 0) { return maxWidth; }
        if(minHeight != 0) { return minHeight;} if(maxHeight != 0) { return maxHeight;}
       }
     },
    roundToHundred(value) {
      return Math.floor(value / 100) * 100;
    },
    getNumber(number) {
      return Math.ceil(parseFloat(String(number).replace(/,/, ".")));
//      return Math.round(parseFloat(String(number).replace(/,/, ".")));
    },
    getRealNumber(number) {
//      return (parseFloat(String(number).replace(/,/, ".")));
      return parseFloat(parseFloat((String(number).replace(/,/, "."))).toFixed(2));
    },
    /**
     * URL
     */
    getStorageUrl(url) {
      var url_string = String(url);
      if (url_string.indexOf("http") !== -1) {
        return url_string;
      }

      return url_string.replace(
        "/static/",
        "/wizard/public/storage/"
      );
    },
    helpText() {
     if(document.getElementById('helpText').style.display == 'inline-block')
      { document.getElementById('helpText').style.display = 'none';  }
     else
      { document.getElementById('helpText').style.display = 'inline-block'; }
    },
    stepMenu() {
     var breadCrumbs = document.getElementsByClassName('breadcrumbs')[0];
     if( document.getElementById('switchBreadcrumbs').classList.contains('on'))
      {
       breadCrumbs.classList.add('expanded');
       document.getElementById('mobileBreadcrumbsTop').classList.add('mobileBreadcrumbsOn');
       document.getElementById('mobileBreadcrumbsTop').classList.remove('mobileBreadcrumbsOff');
       document.getElementById('switchBreadcrumbs').classList.remove('on');
       document.getElementById('switchBreadcrumbs').classList.add   ('off');

       document.getElementById('switchBreadcrumbs').innerHTML        = '<span class="arrowUpStyle">&#9650;</span>'; 
       document.getElementById('switchBreadcrumbs').style.lineHeight = '44px';
      }
     else				  
      {
       breadCrumbs.classList.remove('expanded');
       document.getElementById('mobileBreadcrumbsTop').classList.remove('mobileBreadcrumbsOn');
       document.getElementById('mobileBreadcrumbsTop').classList.add('mobileBreadcrumbsOff');
       document.getElementById('switchBreadcrumbs').classList.remove('off');
       document.getElementById('switchBreadcrumbs').classList.add   ('on');

       document.getElementById('switchBreadcrumbs').innerHTML        = '<span class="arrowDownStyle">&#9660;</span>';
       document.getElementById('switchBreadcrumbs').style.lineHeight = '40px';
      }
    },
   getPriceForInner(color, accessoryLength)
    {
      let ppp = color.surcharge ? color.surcharge : 0;
      let ppd = this.length ? this.length : accessoryLength;
      let ppc = color.price;
      let ppk = color.covers;
      //console.log('('+ ppd +'/1000) *' + ppc + ' + ' + ppk +') * (' + ppp + ' + 100) / 100');
      return Math.round((parseFloat(ppd/1000) * parseFloat(ppc) + parseFloat(ppk)) * (parseFloat(ppp) + 100)/100);
    },

   getAccColor(type, side, name, cartItemNumber) {
    // let accColor = 'Bílá';
    let accColor = '#ffffff'; 
    //console.log('getAccColor cartItemNumber', cartItemNumber);   
    if (typeof cartItemNumber === 'undefined')
     {
      const itemIndex = this.fullCart.indexOf(this.currentCartItem);
      cartItemNumber = itemIndex;
      // cartItemNumber  = this.contents.length-1; 
     }

    // console.log('cartItemNumber', cartItemNumber);
    
    if(typeof this.contents[cartItemNumber] !== "undefined" && this.contents[cartItemNumber].windowfaces[side] !== null)
     {
      if (typeof this.contents[cartItemNumber].windowfaces[side][type] !== 'undefined')
         {
          let id = this.contents[cartItemNumber].windowfaces[side][type].select;
          let out = this.colors_accessories.filter(item => { if (item.id == id && item.type == type) { return true; }});
              
          if (typeof out !== 'undefined' && out !== null)
           { 
            if(typeof out[0] !== 'undefined')
             { 
              // console.log(out[0][name]);
              accColor = out[0][name];
              if(this.$route.name == 'color')
               {
                if(typeof this.currentCartItem.accessories[type] !== 'undefined' && this.currentCartItem.accessories[type] !== null)
                 {
                  this.currentCartItem.accessories[type][side] = out[0];
                 }
               }
             }
           } 
         }
       }
      return accColor;
    },
   getOther(type, cartItem) {
      if (typeof cartItem === "undefined" || cartItem === null) {
        return false;
      }
     if(cartItem.accessories[type] !== null ) { return true; }
     return false;
    },
/* added 07.03.2023 from Preview.vue BEGIN */  
    getMeta(element, index, cartItemNumber) 
     {
      // console.log('getMeta element', element);
      // if (typeof cartItemNumber === 'undefined') { cartItemNumber = 0; }
      const meta = { 
    		    kliky:  	      { left:  false, right: false, top: false, bottom: false, horizontal: false },
    		    kliky2: 	      { left:  false, right: false, top: false, bottom: false, horizontal: false },
    		    smery:  	      { left:  false, right: false, top: false, bottom: false },
    		    panty: 	      { left:  false, right: false, top: false, bottom: false },
    		    panty2:	      { left:  false, right: false, top: false, bottom: false },
    		    posun:  	      { left:  false, right: false },
    		    stulp:  	      { left:  false, right: false },
    		    handle: 	      { front: false, back:  false },
    		    cover:  	      { front: false, back:  false },
    		    coverdrain:       { front: false, back:  false },
    		    handlecolorname:  { front: this.getAccColor('handle',     'front', 'name',     cartItemNumber), back: this.getAccColor('handle','back', 'name',     cartItemNumber) },
    		    handlecolor:      { front: this.getAccColor('handle',     'front', 'colorRGB', cartItemNumber), back: this.getAccColor('handle','back', 'colorRGB', cartItemNumber) },
    		    covercolor:       { front: this.getAccColor('cover',      'front', 'colorRGB', cartItemNumber), back: this.getAccColor('cover',      'back', 'colorRGB', cartItemNumber) },
    		    coverdraincolor:  { front: this.getAccColor('coverdrain', 'front', 'colorRGB', cartItemNumber), back: this.getAccColor('coverdrain', 'back', 'colorRGB', cartItemNumber) }
    		   };
      const oppositeOrientation = element.orientation === "left" ? "right" : "left";

      /* eslint-enable */
      switch (element.variation.typenum) 
       {
        case 0: // Plastové okno pevné (neotvíratelné)

          meta.handle.front 	= false;
          meta.handle.back  	= false;
          meta.cover.front  	= false;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;
          return meta; 
        case 1: // Plastové okno jednokřídlé sklopné
          
          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;
          
          if(element.orientation.indexOf('-') !== -1) { meta.smery.top = true; meta.kliky.top = false; meta.panty.bottom = false; return meta; }
          else                                        { meta.smery.top = true; meta.kliky.top = true;  meta.panty.bottom = true;  return meta; } 
        case 2: // Plastové okno jednokřídlé otvíravé i sklopné
          meta.smery.top = true; 
       
          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;
    
          if (element.orientation)           { meta.smery[oppositeOrientation] = true; } 
	  meta.kliky[oppositeOrientation] = true; 
	  meta.panty[element.orientation] = true; 
          if (element.orientation.indexOf("none") !== -1) 
           { 
            if(element.from == "right")      { meta.smery.right = true;  meta.kliky.right = false; meta.smery.left = false; meta.kliky.left = false; }
	    else			     { meta.smery.right = false; meta.kliky.right = false; meta.smery.left = true;  meta.kliky.left = false; }
	   }
          return meta; 
        case 3: // Plastové okno otvíravé i sklopné + okno pevné (neotvíratelné)

          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (index === 0 && element.orientation === 'right') { 
            meta.smery.top    = false; 
    	    if (element.orientation) { meta.smery.left = false; } meta.kliky.right = false; meta.panty.left = false; return meta; 
	   }
	  else if (index === 1 && element.orientation === 'right') {
            meta.smery.top   = true; 
	    if (element.orientation) { meta.smery.left = true; }  meta.kliky.left  = true; meta.panty.right = true;  return meta; 
	   }
          
          if (index === 0 && element.orientation === 'left') { 
            meta.smery.top   = true; 
	    if (element.orientation) { meta.smery.right = true; } meta.kliky.right = true; meta.panty.left = true;  return meta; 
	   }
	  else if (index === 1 && element.orientation === 'left') {
            meta.smery.top    = false; 
    	    if (element.orientation) { meta.smery.right = false; } meta.kliky.left = false; meta.panty.right = false; return meta; 
	   }
	  
	  if(element.from === 'left') {
            if (index === 0) {
              if (element.orientation.indexOf("none") !== -1) { 
    		meta.smery.top   = false; 
	        if (element.orientation) { meta.smery.right = false; } return meta; 
	       }
	     }
	    else {
	      if (element.orientation.indexOf("none") !== -1) {
                meta.smery.top    = true; 
    	        if (element.orientation) { meta.smery.left = true; }   return meta; 
	       }
	     }
	   }
	  else { 
            if (index === 0) {
              if (element.orientation.indexOf("none") !== -1) { 
                meta.smery.top    = true; 
    	        if (element.orientation) { meta.smery.right = true; }  return meta; 
	       }
	     }
	    else {
	      if (element.orientation.indexOf("none") !== -1) {
                meta.smery.top   = false; 
	        if (element.orientation) { meta.smery.left = false; }  return meta; 
	       }
	     }
	   }
	  return meta;
        case 4:
          // Plastové okno dvoukřídlé s pevným středovým sloupkem
          // Plastové okno dvoukřídlé s pevným středovým sloupkem; jedno křídlo otvíravé i sklopné, druhé křídlo otvíravé

          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (index === 0) {
            if (element.orientation === "right")     { meta.smery.right = true; meta.kliky.right = true; meta.panty.left  = true; return meta; }
            else if (element.orientation === "left") { meta.smery.top   = true; meta.smery.right = true; meta.kliky.right = true; meta.panty.left  = true; return meta; } 
            else {
              if (element.from === 'right') 	     { meta.smery.top   = true; meta.smery.right = true; meta.kliky.right = false; meta.panty.left = false; return meta; }
              else 				     { meta.smery.right = true; return meta; }
             }
           } 
          else {
            if (element.orientation === "right")     { meta.smery.top   = true; meta.smery.left  = true; meta.kliky.left  = true; meta.panty.right = true; return meta; }
            else if (element.orientation === "left") { meta.smery.left  = true; meta.kliky.left  = true; meta.panty.right = true; return meta; }
    	    else { 
              if (element.from === 'left') 	     { meta.smery.top   = true; meta.smery.left  = true; meta.kliky.left  = false; meta.panty.right = false; return meta; }
    	      else 				     { meta.smery.left   = true; return meta }
    	     }
           }
        case 5:
          // Plastové okno dvoukřídlé bez středového sloupku (se štulpem)

          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (index === 0) {
            if (element.orientation === "right")     { meta.smery.right = true; meta.panty.left  = true; meta.stulp.right = true; /* right view */ return meta; } 
            else if (element.orientation === "left") { meta.smery.top   = true; meta.smery.right = true; meta.kliky.right = true; meta.panty.left  = true; return meta;  }
            else {
              if (element.from === 'right') 	     { meta.smery.top   = true; meta.smery.right = true; meta.kliky.right = false; meta.panty.left = false; return meta; }
              else 				     { meta.stulp.right = true; meta.smery.right = true; return meta; }
	     }
           } 
          else {
            if (element.orientation === "right")     { meta.smery.top   = true; meta.smery.left  = true; meta.kliky.left  = true; meta.panty.right = true; return meta; }
            else if (element.orientation === "left") { meta.stulp.left  = true; meta.smery.left  = true; meta.panty.right = true; return meta; }
            else { 
              if (element.from === 'left') 	     { meta.smery.top   = true; meta.smery.left  = true; meta.kliky.left  = false; meta.panty.right = false; return meta; }
    	      else 				     { meta.stulp.left  = true; meta.smery.left  = true; return meta }
             }
           }
        case 6:
          // Plastové okno trojkřídlé s jedním pevným sloupkem a se štulpem

          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (element.orientation === "right") {
            if (index === 0) { meta.smery.right = true; meta.smery.top   = true; meta.kliky.right = true; meta.panty.left  = true; return meta; }
            if (index === 1) { meta.smery.right = true; meta.panty.left  = true; meta.stulp.right = true; /* right view */ return meta; }
            if (index === 2) { meta.smery.left  = true; meta.smery.top   = true; meta.kliky.left  = true; meta.panty.right = true; return meta; }
           } 
          else if (element.orientation === "left") { 
            if (index === 0) { meta.smery.right = true; meta.smery.top   = true; meta.kliky.right = true; meta.panty.left  = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.panty.right = true; meta.stulp.left = true; /* left view */ return meta; }
            if (index === 2) { meta.smery.left  = true; meta.smery.top   = true; meta.kliky.left  = true; meta.panty.right = true; return meta; }
           }
          else
           {
            if (element.orientation === "right") {
              if (index === 0) { meta.smery.right = true; meta.smery.top   = true; meta.kliky.right = true; meta.panty.left = true;  return meta; }
              if (index === 1) { meta.smery.left  = true; meta.panty.right = true; return meta; }
              if (index === 2) { meta.smery.left  = true; meta.smery.top   = true; meta.kliky.left  = true; meta.panty.right = true; return meta; }                                    
             }
            else if (element.orientation === "left") {
              if (index === 0) { meta.smery.right = true; meta.smery.top   = true; meta.kliky.right = true; meta.panty.left  = true; meta.stulp.right = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.panty.right = true; return meta; }
              if (index === 2) { meta.smery.left  = true; meta.smery.top   = true; meta.kliky.left  = true; meta.panty.right = true; return meta; }
             }
            else
             {
              if (element.from === "right") {
                if (index === 0) { meta.smery.right = true; meta.smery.top = true; return meta; }
                if (index === 1) { meta.stulp.left  = true; /* right view */ meta.smery.left  = true; return meta; }
                if (index === 2) { meta.smery.left  = true; meta.smery.top = true; return meta; }
               }
              else {
                if (index === 0) { meta.smery.right = true; meta.smery.top = true; return meta; }
                if (index === 1) { meta.stulp.right = true; /* left view */ meta.smery.right = true; return meta;  }
                if (index === 2) { meta.smery.left  = true; meta.smery.top = true; return meta; }
               }
             }             
           }
          break;
        case 7:
          // Plastové okno trojkřídlé se dvěma pevnými sloupky

          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (element.orientation === "right") {
            if (index === 0) { meta.smery.top = true; meta.smery.right = true; meta.kliky.right = true; meta.panty.left  = true; return meta; }
            if (index === 1) { meta.smery.top = true; meta.smery.left  = true; meta.kliky.left  = true; meta.panty.right = true; return meta; }
            if (index === 2) { meta.smery.top = true; meta.smery.left  = true; meta.kliky.left  = true; meta.panty.right = true; return meta; }
           } 
          else if (element.orientation === "left") {
            if (index === 0) { meta.smery.top = true; meta.smery.right = true; meta.kliky.right = true; meta.panty.left  = true; return meta; }
            if (index === 1) { meta.smery.top = true; meta.smery.right = true; meta.kliky.right = true; meta.panty.left  = true; return meta; }
            if (index === 2) { meta.smery.top = true; meta.smery.left  = true; meta.kliky.left  = true; meta.panty.right = true; return meta; }
           }
          else  
           {
            if (element.from === "right") {
              if (index === 0) { meta.smery.top = true; meta.smery.right = true; return meta; }
              if (index === 1) { meta.smery.top = true; meta.smery.right = true; return meta; }
              if (index === 2) { meta.smery.top = true; meta.smery.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.top = true; meta.smery.right = true; return meta; }
              if (index === 1) { meta.smery.top = true; meta.smery.left  = true; return meta; }
              if (index === 2) { meta.smery.top = true; meta.smery.left  = true; return meta; }
             }
           }
          break;
        case 8:
          // Plastové jednokřídlé balkonové dveře otvíravé i sklopné

          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (element.orientation === "right")     { meta.smery.left = true; meta.smery.top  = true; meta.kliky.left = true; meta.panty.right = true; return meta; } 
          else if (element.orientation === "left") { meta.smery.right = true; meta.smery.top = true; meta.kliky.right = true; meta.panty.left = true; return meta; }
          else {
            if (element.from === "right") { meta.smery.right = true; meta.smery.top = true; return meta; }
            else			  { meta.smery.left  = true; meta.smery.top = true; return meta; }
           }
        case 9:
          // Plastové jednokřídlé vedlejší vchodové dveře, otevíravé do interiéru

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "right")     { meta.smery.left  = true; meta.kliky.left  = true; meta.panty.right = true; return meta; } 
          else if (element.orientation === "left") { meta.smery.right = true; meta.kliky.right = true; meta.panty.left  = true; return meta; }
          else { 
            if (element.from === "right") { meta.smery.right = true; meta.kliky.right = true; return meta; }
            else		    	  { meta.smery.left  = true; meta.kliky.left = true; return meta; }
    	   }
        case 10:
          // Plastové dvoukřídlé balkonové dveře bez středového sloupku (se štulpem)

          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (element.orientation === "right")  {
             if (index === 0) { meta.smery.right = true; meta.panty.left = true; meta.stulp.right = true; return meta; }
             if (index === 1) { meta.smery.left  = true; meta.smery.top  = true; meta.panty.right = true; meta.kliky.left = true; return meta; }
           } 
          else if (element.orientation === "left") {
             if (index === 0) { meta.smery.top  = true; meta.smery.right = true; meta.panty.left = true; meta.kliky.right = true; return meta; }
             if (index === 1) { meta.smery.left = true; meta.panty.right = true; meta.stulp.left = true; return meta; }
           }
          else {
            if (element.from === "right") {
              if (index === 0) { meta.smery.top  = true; meta.smery.right = true; return meta; }
              if (index === 1) { meta.smery.left = true; meta.stulp.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; }
              if (index === 1) { meta.smery.top   = true;  meta.smery.left = true; return meta; }
             }
           }
          break;
        case 11:
          // Plastové sklopně - posuvné dveře PSK (schéma A) + pevná část v rámu F (neotevíravá)

          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= false;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;
          
          if (element.orientation === "right") {
            if (index === 0) { return meta; }
            if (index === 1) { meta.smery.top = true; meta.posun.left  = true; meta.kliky.right = true; return meta; }
           }
          else if (element.orientation === "left") {
            if (index === 0) { meta.smery.top = true; meta.posun.right = true; meta.kliky.left  = true; return meta; }
            if (index === 1) { return meta; }
           }
          else {
            if (element.from === "right") {
              if (index === 0) { meta.smery.top = true; meta.posun.right = true; return meta; }
              if (index === 1) { return meta; }
	     }
	    else {
              if (index === 0) { return meta; }
              if (index === 1) { meta.smery.top = true; meta.posun.left  = true; return meta; }
	     }
           }
          break;
        case 12:
          // Plastové jednokřídlé hlavní vchodové dveře, otevíravé do interiéru, nízký ALU práh

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "right")     { meta.smery.left = true; meta.panty2.right = true; meta.kliky.left = true;  return meta; } 
          else if (element.orientation === "left") { meta.smery.right = true; meta.panty2.left = true; meta.kliky.right = true; return meta; }
          else { 
            if (element.from === "right") { meta.smery.right = true; meta.kliky.right = true; return meta; }
            else 			  { meta.smery.left = true; meta.kliky.left = true; return meta;   }
           }
        case 13:
	  // Plastové dvoukřídlé hlavní vchodové dveře se štulpem, obě křídla otevíravá, otevíravé do interiéru, nízký ALU práh
	  
          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "right")  {
            if (index === 0) { meta.smery.right = true; meta.panty2.left  = true; meta.stulp.right = true; return meta; } 
            else 	     { meta.smery.left  = true; meta.panty2.right = true; meta.kliky.left  = true; return meta; }
           } 
          else if (element.orientation === "left")  {
            if (index === 0) { meta.smery.right = true; meta.panty2.left  = true; meta.kliky.right = true; return meta; } 
            else 	     { meta.smery.left  = true; meta.panty2.right = true; meta.stulp.left  = true; return meta; }
           }          
          else {
            if (element.from === "right") {
              if (index === 0){ meta.smery.right = true; meta.kliky.right = true; return meta; } 
              else 	      { meta.smery.left  = true; meta.stulp.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; } 
              else 	       { meta.smery.left  = true; meta.kliky.left  = true; return meta; }
	     }
           }
        case 14:
          // Plastové dvoukřídlé vedlejší vchodové dveře bez středového sloupku (se štulpem); obě křídla otevíravé

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "right") {
            if (index === 0) { meta.smery.right = true; meta.panty.left  = true; meta.stulp.right = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.panty.right = true; meta.kliky.left  = true; return meta; }
           } 
          else if (element.orientation === "left") {
            if (index === 0) { meta.smery.right = true; meta.panty.left  = true; meta.kliky.right = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.panty.right = true; meta.stulp.left  = true; return meta; }
           }
          else {
            if (element.from === "right") {
              if (index === 0) { meta.smery.right = true; meta.kliky.right = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.stulp.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.kliky.left  = true; return meta; }
             }
           }
        break;
        case 15:
          // Plastové jednokřídlé vedlejší vchodové dveře, otevíravé do exteriéru

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= false;
          meta.cover.back   	= true;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "left")    { meta.smery.left  = true; meta.kliky.left  = true; return meta; } 
          else
           { 
            if (element.orientation === "right") { meta.smery.right = true; meta.kliky.right = true; return meta; }
            else 
             { 
              if (element.from === "left") 	 { meta.smery.right = true; meta.kliky.right = true; meta.panty2.left  = true; return meta; }
              else		    		 { meta.smery.left  = true; meta.kliky.left  = true; meta.panty2.right = true; return meta; }
    	     }
	   }
        case 16:
          // Plastové jednokřídlé hlavní vchodové dveře, otevíravé do exteriéru

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= false;
          meta.cover.back   	= true;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "left")    { meta.smery.left  = true; meta.kliky.left  = true; return meta; } 
          else
           { 
            if (element.orientation === "right") { meta.smery.right = true; meta.kliky.right = true; return meta; }
            else 
             { 
              if (element.from === "left") 	 { meta.smery.right = true; meta.kliky.right = true; meta.panty2.left  = true; return meta; }
              else		    		 { meta.smery.left  = true; meta.kliky.left  = true; meta.panty2.right = true; return meta; }
    	     }
	   }
        case 17:
          // Plastové dvoukřídlé vedlejší vchodové dveře se štulpem, obě křídla otevíravá, otevíravé do exteriéru

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= false;
          meta.cover.back   	= true;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "right") {
            if (index === 0) { meta.smery.right = true; meta.kliky.right  = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.stulp.left = true; return meta; }
           } 
          else if (element.orientation === "left") {
            if (index === 0) { meta.smery.right = true; meta.stulp.right  = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.kliky.left = true; return meta; }
           }
          else {
            if (element.from === "right") {
              if (index === 0) { meta.smery.right = true; meta.panty2.left  = true; meta.stulp.right  = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.panty2.right = true; meta.kliky.left = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.panty2.left = true; meta.kliky.right  = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.panty2.right  = true; meta.stulp.left = true; return meta; }
             }
           }
        break;
        case 18:
	  // Plastové dvoukřídlé hlavní vchodové dveře se štulpem, obě křídla otevíravá, otevíravé do interiéru, nízký ALU práh, dělení křídel 2:1
	  
          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "right")  {
            if (index === 0) { meta.smery.right = true; meta.panty2.left  = true; meta.stulp.right = true; return meta; } 
            else 	     { meta.smery.left  = true; meta.panty2.right = true; meta.kliky.left  = true; return meta; }
           } 
          else if (element.orientation === "left")  {
            if (index === 0) { meta.smery.right = true; meta.panty2.left  = true; meta.kliky.right = true; return meta; } 
            else 	     { meta.smery.left  = true; meta.panty2.right = true; meta.stulp.left  = true; return meta; }
           }          
          else {
            if (element.from === "right") {
              if (index === 0){ meta.smery.right = true; meta.kliky.right = true; return meta; } 
              else 	      { meta.smery.left  = true; meta.stulp.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; } 
              else 	       { meta.smery.left  = true; meta.kliky.left  = true; return meta; }
	     }
           }
        case 19: 
          // Plastové okno jednokřídlé otvíravé

          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (element.orientation)           { meta.smery[oppositeOrientation] = true; } 
	  meta.kliky[oppositeOrientation] = true; meta.panty[element.orientation] = true; 

          if (element.orientation.indexOf('none') !== -1) 
           { 
            if(element.from == "right")      { meta.smery.right = true;  meta.kliky.right = false; meta.smery.left = false; meta.kliky.left = false; }
	    else			     { meta.smery.right = false; meta.kliky.right = false; meta.smery.left = true;  meta.kliky.left = false; }
	   }
	break;
        case 20:
          // Plastové jednokřídlé balkonové dveře otvíravé - sklopné s oboustrannou klikou a zámkem FAB

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (element.orientation === "right")     { meta.smery.left = true; meta.smery.top  = true; meta.kliky.left = true; meta.panty.right = true; return meta; } 
          else if (element.orientation === "left") { meta.smery.right = true; meta.smery.top = true; meta.kliky.right = true; meta.panty.left = true; return meta; }
          else {
            if (element.from === "right") { meta.smery.right = true; meta.smery.top = true; meta.kliky2.right = true; return meta; }
            else			  { meta.smery.left  = true; meta.smery.top = true; meta.kliky2.left  = true; return meta; }
           }
        case 21:
          // Plastové dvoukřídlé balkonové dveře bez středového sloupku (se štulpem)

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (element.orientation === "right")  {
             if (index === 0) { meta.smery.right = true; meta.panty.left = true; meta.stulp.right = true; return meta; }
             if (index === 1) { meta.smery.left  = true; meta.smery.top  = true; meta.panty.right = true; meta.kliky.left = true; return meta; }
           } 
          else if (element.orientation === "left") {
             if (index === 0) { meta.smery.top  = true; meta.smery.right = true; meta.panty.left = true; meta.kliky.right = true; return meta; }
             if (index === 1) { meta.smery.left = true; meta.panty.right = true; meta.stulp.left = true; return meta; }
           }
          else {
            if (element.from === "right") {
              if (index === 0) { meta.smery.top  = true; meta.smery.right = true; meta.kliky2.right = true; return meta; }
              if (index === 1) { meta.smery.left = true; meta.stulp.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; }
              if (index === 1) { meta.smery.top   = true;  meta.smery.left = true; meta.kliky2.left = true; return meta; }
             }
           }
        break;
        case 22:
          // Plastové dvoukřídlé balkonové dveře se štulpem, jedno křídlo otevíravě - sklopné, druhé křídlo otevíravé, dělení křídel 2:1

          meta.handle.front 	= true;
          meta.handle.back  	= false;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (element.orientation === "right")  {
             if (index === 0) { meta.smery.right = true; meta.panty.left = true; meta.stulp.right = true; return meta; }
             if (index === 1) { meta.smery.left  = true; meta.smery.top  = true; meta.panty.right = true; meta.kliky.left = true; return meta; }
           } 
          else if (element.orientation === "left") {
             if (index === 0) { meta.smery.top  = true; meta.smery.right = true; meta.panty.left = true; meta.kliky.right = true; return meta; }
             if (index === 1) { meta.smery.left = true; meta.panty.right = true; meta.stulp.left = true; return meta; }
           }
          else {
            if (element.from === "right") {
              if (index === 0) { meta.smery.top  = true; meta.smery.right = true; return meta; }
              if (index === 1) { meta.smery.left = true; meta.stulp.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; }
              if (index === 1) { meta.smery.top   = true;  meta.smery.left = true; return meta; }
             }
           }
        break;
        case 23:
          // Plastové dvoukřídlé hlavní vchodové dveře se štulpem, obě křídla otevíravá, otevíravé do exteriéru

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= false;
          meta.cover.back   	= true;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "right") {
            if (index === 0) { meta.smery.right = true; meta.kliky.right = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.stulp.left  = true; return meta; }
           } 
          else if (element.orientation === "left") {
            if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.kliky.left  = true; return meta; }
           }
          else {
            if (element.from === "right") {
              if (index === 0) { meta.smery.right = true; meta.panty2.left  = true; meta.stulp.right = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.panty2.right = true; meta.kliky.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.panty2.left  = true; meta.kliky.right = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.panty2.right = true; meta.stulp.left  = true; return meta; }
             }
           }
        break;
        case 24:
          // Plastové dvoukřídlé vedlejší vchodové dveře se štulpem, obě křídla otevíravá, otevíravé do interiéru, nízký ALU práh, dělení křídel 2:1

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "right") {
            if (index === 0) { meta.smery.right = true; meta.panty.left  = true; meta.stulp.right = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.panty.right = true; meta.kliky.left  = true; return meta; }
           } 
          else if (element.orientation === "left") {
            if (index === 0) { meta.smery.right = true; meta.panty.left  = true; meta.kliky.right = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.panty.right = true; meta.stulp.left  = true; return meta; }
           }
          else {
            if (element.from === "right") {
              if (index === 0) { meta.smery.right = true; meta.kliky.right = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.stulp.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.kliky.left  = true; return meta; }
             }
           }
        break;
        case 25:
          // Plastové dvoukřídlé vedlejší vchodové dveře se štulpem, obě křídla otevíravá, otevíravé do exteriéru, nízký ALU práh, dělení křídel 2:1

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= false;
          meta.cover.back   	= true;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "right") 
           {
            if (index === 0) { meta.smery.right = true; meta.kliky.right = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.stulp.left  = true; return meta; }
           } 
          else if (element.orientation === "left") {
            if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.kliky.left  = true; return meta; }
           }
          else 
           {
            if (element.from === "right") {
              if (index === 0) { meta.smery.right = true; meta.panty2.left  = true; meta.stulp.right  = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.panty2.right = true; meta.kliky.left = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.panty2.left = true; meta.kliky.right  = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.panty2.right  = true; meta.stulp.left = true; return meta; }
             }
           }
        break;
        case 26:
          // Plastové dvoukřídlé hlavní vchodové dveře se štulpem, obě křídla otevíravá, otevíravé do exteriéru, nízký ALU práh, dělení křídel 2:1

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= false;
          meta.cover.back   	= true;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = false;

          meta.kliky.horizontal = true;
          if (element.orientation === "right") {
            if (index === 0) { meta.smery.right = true; meta.kliky.right = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.stulp.left  = true; return meta; }
           } 
          else if (element.orientation === "left") {
            if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; }
            if (index === 1) { meta.smery.left  = true; meta.kliky.left  = true; return meta; }
           }
          else {
            if (element.from === "right") {
              if (index === 0) { meta.smery.right = true; meta.panty2.left  = true; meta.stulp.right = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.panty2.right = true; meta.kliky.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.panty2.left  = true; meta.kliky.right = true; return meta; }
              if (index === 1) { meta.smery.left  = true; meta.panty2.right = true; meta.stulp.left  = true; return meta; }
             }
           }
        break;
        case 27:
          // Plastové dvoukřídlé balkonové dveře se štulpem, hlavní křídlo otevíravě - sklopné, vedlejší křídlo otevíravé, s oboustrannou klikou a zámkem FAB, dělení křídel 2:1

          meta.handle.front 	= true;
          meta.handle.back  	= true;
          meta.cover.front  	= true;
          meta.cover.back   	= false;
          meta.coverdrain.front = false;
          meta.coverdrain.back  = true;

          if (element.orientation === "right")  {
             if (index === 0) { meta.smery.right = true; meta.panty.left = true; meta.stulp.right = true; return meta; }
             if (index === 1) { meta.smery.left  = true; meta.smery.top  = true; meta.panty.right = true; meta.kliky.left = true; return meta; }
           } 
          else if (element.orientation === "left") {
             if (index === 0) { meta.smery.top  = true; meta.smery.right = true; meta.panty.left = true; meta.kliky.right = true; return meta; }
             if (index === 1) { meta.smery.left = true; meta.panty.right = true; meta.stulp.left = true; return meta; }
           }
          else {
            if (element.from === "right") {
              if (index === 0) { meta.smery.top  = true; meta.smery.right = true; meta.kliky2.right = true; return meta; }
              if (index === 1) { meta.smery.left = true; meta.stulp.left  = true; return meta; }
             }
            else {
              if (index === 0) { meta.smery.right = true; meta.stulp.right = true; return meta; }
              if (index === 1) { meta.smery.top   = true;  meta.smery.left = true; meta.kliky2.left = true; return meta; }
             }
           }
       }
      return meta;
     },

/* added 07.03.2023 from Preview.vue END */    

/* added 22.03.2023  from WizardVariations.vue BEGIN */
    getPriceForVariation(variation, source) {
//    console.log('outprice', variation);
//    console.log('this.currentCartItem', this.currentCartItem);
      return this.getProductPrice(variation, null, this.profiles, 0.0, this.currentCartItem, null, null, source);
    },
    fitMinimalForVariation(element) {
      const collection = this.variations.filter( item => element.typenum === item.typenum );
      const minHeight  = this.propertyLimit(collection, "height", "min");
      const minWidth   = this.propertyLimit(collection, "width",  "min");
      return (parseInt(this.width,  10) >= minWidth && parseInt(this.height, 10) >= minHeight);
    },
/* added 22.03.2023  from WizardVariations.vue END */

/* added 22.03.2023 from WizardVariation.vue BEGIN */
    availableVariations() {
      if(typeof this.variations === 'undefined' || this.variations === null) { console.log('Empty variations!'); return false; }
      if(typeof this.category   === 'undefined' || this.category   === null) { console.log('Empty category!');   return false; }
      let counter = 0;
      let prevElementId     =  0;
      let arTmp 	    = [];
      let arVariations      = [];
      let prevElementWidth  =  0;
      let prevElementHeight =  0;
      let out               = [];
      // Get all suitable variant by width and height, with step 50 and than step 100        

      this.variations.forEach(element => {
        for(let i = 50; i <= 100; i = i + 50)
	 {
          let beginWidth  = Math.floor(this.width /i)  * i;
          let beginHeight = Math.floor(this.height/i)  * i;
          let endWidth    = Math.ceil (this.width /i)  * i;
	  let endHeight   = Math.ceil (this.height/i)  * i;
	  if(
	       ((parseInt(element.width,   10) <= endWidth ) && (parseInt(element.width,  10) >= beginWidth ))
	     &&
	       ((parseInt(element.height,  10) <= endHeight) && (parseInt(element.height, 10) >= beginHeight))
	     && (parseInt(element.category,10) == parseInt(this.category.id))
            )
           {
	    arTmp[counter] = element;
	    counter++;
	   }
         }
      });

      // Remove duplicates and select all variations by size
      if(arTmp.length !==0)
       { 
        arVariations = arTmp.filter(element => { 
          if((parseInt(element.width) == prevElementWidth) && (parseInt(element.height) == prevElementHeight) && element.id == prevElementId)
           { return false; }
          else
           {
            prevElementId     = element.id;
            prevElementWidth  = parseInt(element.width);
            prevElementHeight = parseInt(element.height);        
            if(parseInt(this.width)  <=  parseInt(element.width) && parseInt(this.height) <= parseInt(element.height))
             { return true; }
           }
          return false;
         });
       }

       // get minimal fit
       let prev  = [];
       let count = 0;
       arVariations.forEach(elem => 
        {
         if(prev.id == elem.id)
          {
           if ((parseInt(prev.width) <= parseInt(elem.width)))
            { 
             if (parseInt(prev.height) <= parseInt(elem.height)) { out[count] = prev;}
             else 			     { out[count] = elem;}
            }
           else			     
            { out[count] = elem; }
           prev = out[count];
          }
         else
          {
           out[count] = elem;
           prev = out[count];
           count++;
          }
        });
      // Again Remove duplicates and select all variations by size
      prevElementId     =  0;
      prevElementWidth  =  0;
      prevElementHeight =  0;
      if(out.length !==0)
       { 
        arVariations = out.filter(element => { 
          if((parseInt(element.width) == prevElementWidth) && (parseInt(element.height) == prevElementHeight) && element.id == prevElementId)
           { return false; }
          else
           {
            prevElementId     = element.id;
            prevElementWidth  = element.width;
            prevElementHeight = element.height;        
            if(parseInt(this.width)  <=  parseInt(element.width) && parseInt(this.height) <= parseInt(element.height))
             { return true; }
           }
          return false;
         });
       }
     // Sorting by Price ASC Begin     
     arVariations.filter(element => {
        // add calculated price to Array for future sorting by this price
        // console.log(this.getPriceForVariation(element));
        element.outprice = this.getPriceForVariation(element);
        });
     let arVariationsOut = arVariations.sort(function(a, b){return a.type.indexOf('dvou') - b.type.indexOf('dvou')});
     arVariationsOut = arVariationsOut.sort(function(a, b){return a.outprice - b.outprice});     
     // Sorting by Price ASC End
     return arVariationsOut;
    },
/* added 22.03.2023 from WizardVariation.vue END */

    fullPrice(source) {
      let current = this.currentCartItem;
// console.log(source, current);    

//      console.log(this.currentCartItem);
//      let out = current.variation.width + '+' + current.variation.height;
      if (typeof current === "undefined") {
        current = { count: 1 };
      }
      return (
        this.currencyFormatNum(this.getItemPrice(source, current)) *
        (parseInt(current.count, 10) > 10 ? parseInt(current.count, 10) : current.count)
      );
    },
    unitPrice(source) {
      let current = this.currentCartItem;
      if (typeof current === "undefined") {
        current = {
          count: 1
        };
      }
      return (
        this.currencyFormatNum(this.getItemPrice(source, current))
      );
    },

    fullCartPrice(source) {
      let sum = 0;
      this.contents.forEach(element => {
        if (element !== null) {
          sum +=
            this.currencyFormatNum(this.getItemPrice(source, element)) *
            (parseInt(element.count, 10) > 0 ? parseInt(element.count, 10) : element.count);
        }
      });
      return sum;
    },

    fullCartPriceWithVat(source) {
      let sum = 0;
      this.contents.forEach(element => {
        if (element !== null) {
          sum +=
            this.currencyFormatNum(this.countWithVat(parseInt(this.getItemPrice(source, element))) * Number(element.count)) * 1;
        }
      });
      return sum;
    },

    fullCartPriceVat(source) {
      let sum = 0;
      this.contents.forEach(element => {
        if (element !== null) {
          sum +=
            this.currencyFormatNum(this.countVat(parseInt(this.getItemPrice(source, element))) * Number(element.count)) * 1;
        }
      });
      return sum;
    },
    checkCurrentWidth(width) {
     if(parseInt(window.innertWidth) > parseInt(width))
      { return true; }
     return false;
    },
    iPhone() {
        if (typeof navigator !== "undefined")
         {
          if (typeof window === `undefined` || typeof navigator === `undefined`) 
           { return false; }
          return /iPhone/i.test(navigator.userAgent || navigator.vendor || window.opera);
         }
        return false;
    },
    sendMail() {
        this.mailsend = true;	
	/* this allow to start js (by preventing caching) on page after returning from external web by browser's back button */
	window.addEventListener('unload', () => {});
	
	// console.log(this.$store.state.cart.contents);
	
	let contentString = btoa(unescape(encodeURIComponent(JSON.stringify(this.$store.state.cart.contents))));
	/* decode this string -> decodeURIComponent(escape(window.atob(str.replace(/\s/g, '')))) */
        /*
        Note: if you need to get this to work in mobile-safari, you might need 
        to strip all the white-space from the base64 data...
        
        function b64_to_utf8( str ) {
            str = str.replace(/\s/g, '');    
                return decodeURIComponent(escape(window.atob( str )));
                }
        
        source:  https://stackoverflow.com/questions/23223718/failed-to-execute-btoa-on-window-the-string-to-be-encoded-contains-characte
        
        // another variant
        function ToBinary(str){ 
          let result="";
          str = encodeURIComponent(str);
          for(let i=0;i<str.length;i++) { 
            if(str[i]=="%") { result+=String.fromCharCode(parseInt(str.substring(i+1,i+3),16)); i+=2; }
            else { result+=str[i];}                                                                                 
          }
          return result;
        }
        btoa(ToBinary("тест"));//0YLQtdGB0YI=
        
        */
       
        const http = new XMLHttpRequest();
	const url = 'https://www.skladoken.cz/index.php?fc=module&module=konfigproduct&controller=sendmail';

        const data = { contents: contentString, email: this.email, name: this.name, text: this.text };

        http.open("POST", url, true);
	http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        http.onreadystatechange = () => {
          if (http.readyState === 4 && http.status === 200) {
            // Redirect
            // window.location.reload();
            // window.location.href = http.responseText;
            this.mailsend = false;
            this.sendMailShow = false;
          }
        };
        http.send(querystring.stringify(data));
    },
    removeColor(type) {
      var colorsArray            = [];
      var colorsAccessoriesArray = [];
      
      var defaultColor           = null;
      var defaultHandle          = null;
      var defaultCover           = null;
      var defaultCoverdrain      = null;
      
      if (typeof this.colors === 'object') { colorsArray = Object.values(this.colors); } 
      else 			           { colorsArray = this.colors;                }
      colorsArray.forEach(color => {
        if ( color.id == 0 ) { defaultColor = color; }
      });
      
      if (typeof this.colors_accessories === 'object') { colorsAccessoriesArray = Object.values(this.colors_accessories); } 
      else 			                       { colorsAccessoriesArray = this.colors_accessories;                }
      colorsAccessoriesArray.forEach(colorAccessory => {
        if ( colorAccessory.type == 'handle'     && colorAccessory.colorRGB == '#ffffff') { defaultHandle     = colorAccessory; }
        if ( colorAccessory.type == 'cover'      && colorAccessory.colorRGB == '#ffffff') { defaultCover      = colorAccessory; }
        if ( colorAccessory.type == 'coverdrain' && colorAccessory.colorRGB == '#ffffff') { defaultCoverdrain = colorAccessory; }
      });
//console.log(type);
     this[type]					       = defaultColor;
     this.windowfaces[type]                            = defaultColor;
     this.currentCartItem.accessories.handle[type]     = defaultHandle;
     this.currentCartItem.accessories.cover[type]      = defaultCover;
     this.currentCartItem.accessories.coverdrain[type] = defaultCoverdrain;

     document.querySelectorAll('img[id^=img]').forEach((elem) => elem.remove()); 
     document.getElementById('imgLoaded').innerHTML = "0";

//   console.log('main.js this.currentCartItem.accessories.handle.'     + type, this.currentCartItem.accessories.handle[type]);
//   console.log('main.js this.currentCartItem.accessories.cover.'      + type, this.currentCartItem.accessories.cover[type]);
//   console.log('main.js this.currentCartItem.accessories.coverdrain.' + type, this.currentCartItem.accessories.coverdrain[type]);
    },    
  }
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
