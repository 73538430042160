<template>
  <div class="container">
    <h2 class="headline--padded text-center" v-html="$t('profileInfoTitle')"></h2>

    <span @click="close" class="icon icon-close"></span>

    <div class="profile__detail" v-html="profile.details"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WizardProfileInfo",
  computed: {
    ...mapState({
      profiles: state => state.profiles.profiles.slice(1)
    }),
    profile() {
      let profileObject = {};

      for (var profileKey in this.profiles) {
        if (this.profiles[profileKey].id === this.$route.params.id) {
          profileObject = this.profiles[profileKey];
        }
      }

      return profileObject;
    }
  },
  methods: {
    close() {
      this.$router.push({ name: "profile" });
    }
  }
};
</script>

<style lang="scss">
</style>
