<template>
<div>
    
    <div style="height: 130px; flex: auto;">
     <div v-show="loading"  class="loaderShadow"><div class="loaderSVG"><div class="loaderText">Vyčkejte na přesměrování do košíku</div></div></div>
<!-- <div v-show="loading" class="loaderShadow"><div class="loaderSVG"><div class="loaderText">Vyčkejte na přesměrování do konfigurátoru</div></div></div> -->
     <div class="stickyFooterAround" v-if="$route.name === 'order' && this.selected">
      <div class="stickyFooter">
        <div class='helpFooter'>
<!--         <div class="helpImg" @click="helpText();"></div> -->
<!--     <div id="helpText" class="helpText" style="inline-block;">Potřebujete radu?<br> <span>info@skladoken.cz <br> +420 776 327 222</span></div> -->
             <div id="helpText" class="helpText" style="inline-block;"><span class="infoTel"><a href="tel:+420 776 327 222">+420 776 327 222</a></span> <br> <span class="infoMail"><a href="mailto:info@skladoken.cz">info@skladoken.cz</a></span></div>
        </div>
        <button @click="backButton" class="stickyButtonBack" v-html="$t('backButtonText')"></button>
        <button @click="order" class="stickyButtonOrder"><div class="stickyButtonText">{{ $t('orderButton') }}</div></button>
      </div>
     </div>
    </div>

</div>
</template>
<script>
  import querystring from "querystring";
  import { mapState } from "vuex";
  import { createHelpers } from "vuex-map-fields";
  
  const { mapFields } = createHelpers({
    getterType: "getCurrentCartField",
    mutationType: "updateCurrentCartField"
  });

  export default {
    name: "Summary",
    computed: {
      ...mapState({
        variationsLoaded: state => state.variations.loaded,
        variations: state => state.variations.variations
      }),
      ...mapFields("cart", [
        "size.width",  "size.height",
        "variation",   "orientation",
        "windowfaces", "accessories",
        "category",    "profile",
        "glass"
      ]),
      selected() {
        if (!this) { return false; }
        return this.variation;
      },
      isEligiblePrev() { return true; },
      isEligibleNext() {
        if      (this.$route.meta.step === 3) { return (this.variation && this.variation.height && this.variation.width && this.variation.type && this.variation.price && this.width && this.height); } 
        else if (this.$route.meta.step === 4) { return this.profile && this.profile.name; } 
        else if (this.$route.meta.step === 5) { return this.faces("front") !== null && this.faces("back") !== null; } 
        else if (this.$route.meta.step === 6) { return this.glass && this.glass.id; } 
        else if (this.$route.meta.step === 7) { return true; }
        return false;
      }
    },
    data() {
      return { 
        title: "Kalkulovaný výrobek", 
        price: 0, vat: 0, priceVat: 0, 
        loading: false, 
      };
    },
    beforeDestroy() {
     window.addEventListener('unload', () => {});
     window.location.reload();
    },
    methods: {
      faces(face) { return this.windowfaces[face]; },
      prev() {
        if      (this.$route.meta.step === 4) { this.$router.push({ name: "profile" });     } 
        else if (this.$route.meta.step === 3) { this.$router.push({ name: "size" });        } 
        else if (this.$route.meta.step === 5) { this.$router.push({ name: "color" });       } 
        else if (this.$route.meta.step === 6) { this.$router.push({ name: "glass" });       } 
        else if (this.$route.meta.step === 7) { this.$router.push({ name: "accessories" }); }
      },
      next() {
        if      (this.$route.meta.step === 2) { this.$router.push({ name: "size" });       } 
        else if (this.$route.meta.step === 3) { this.$router.push({ name: "profile" });    } 
        else if (this.$route.meta.step === 4) { this.$router.push({ name: "color" });      } 
        else if (this.$route.meta.step === 5) { this.$router.push({ name: "glass" });      } 
        else if (this.$route.meta.step === 6) { this.$router.push({ name: "accessories"}); } 
        else if (this.$route.meta.step === 7) { this.$router.push({ name: "order" });      }
      },
      backButton() {
	if (this.filteredFullCart.length > 0)
	 { this.$router.push({ name: "accessories" }); }
	else
	 {
          this.variation = {}; // clear object
          this.variation.type   = "";
          this.variation.price  = "";
          this.variation.width  = "";
          this.variation.height = "";
          location.href = '/';
         }
      },
      order() {
        this.loading = true;	
	/* this allow to start js (by preventing caching) on page after returning from external web by browser's back button */
	window.addEventListener('unload', () => {});

	window.onpageshow = function(event) {
	  if (event.persisted) { window.location.reload() }
        };

        const http = new XMLHttpRequest();
//	const url = 'https://development.skladoken.cz/index.php?fc=module&module=konfigproduct&controller=configure';
//    	const url = 'https://dev.schema.pro/index.php?fc=module&module=konfigproduct&controller=configure';
    	const url = 'https://www.skladoken.cz/index.php?fc=module&module=konfigproduct&controller=configure';

        var cart_contents = this.$store.state.cart.contents.map((content) => {
          content.price      = this.currencyFormatNum(this.getItemPrice('summary-content-price', content, content.count || 1));
          content.fullPrice  = this.currencyFormatNum(this.getItemPrice('summary-content-fullPrice', content, content.count || 1));
	  content.unitPrice  = this.currencyFormatNum(this.getItemPrice('summary-content-unitPrice', content, 1));
	  content.priceVat   = this.countWithVat(this.fullPrice('summary-content-priceVat'));
	  content.vatPrice   = this.countVat(this.fullPrice('summary-content-vatPrice'));
          content.meta	     = this.getMeta(content);
          return content;
        });
//console.log(cart_contents); 
        const data = {
          contents: JSON.stringify(cart_contents),
          unitPrice: this.unitPrice('summary-unitPrice'),
          price: this.fullPrice('summary-price'),
          priceVat: this.countWithVat(this.fullPrice('summary-priceVat')),
          vatPrice: this.countVat(this.fullPrice('summary-vatPrice')),
          colorSurchargeValue: this.colorSurcharge ? this.colorSurchargeValue : 0 
        };

        http.open("POST", url, true);
	http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        http.onreadystatechange = () => {
          if (http.readyState === 4 && http.status === 200) {
            // Redirect
            window.location.href = http.responseText;
          }
        };
        http.send(querystring.stringify(data));
      }
    }
  };
</script>
