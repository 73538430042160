import axios from 'axios';

// initial state
const state = {
  currencies: []
};

// getters
const getters = {};

// actions
const actions = {
  loadCurrencies({ commit }) {
    axios
//    .get('/wizard/public/storage/currencies.php')
      .get('/wizard/public/storage/currencies.json')
      .then(response => {
        commit('updateCurrencies', response.data);
      });
  }
};

// mutations
const mutations = {
  updateCurrencies(state, currencies) {
    state.currencies = currencies;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
