<template>
  <div class="container">
    <div class      	    = "sizeInfoIcon"
        @mousedown          = "showInfo('top', true)"
        @touchstart.prevent = "showInfo('top', true)"
        @mouseover          = "showInfo('top')"
        @mouseleave         = "closeInfo">
        <div class="infoTitle">Nápověda <div class="infoTitleString">Kategorie</div></div>
    </div>
    <div class="sizeInfo" v-show="isInfoVisible && infoCategoryId == 'top'"
        @mouseover  = "showInfo('top')"
        @mouseleave = "closeInfo"
    >
	<span @click="closeInfo" @touch="closeInfo" class="icon icon-infoClose"></span>
	<div  class="profileDetail" v-html="details"></div>
    </div>
    <div class="titleH1">
      <h2 class="headline--padded text-center" v-html="$t('categoryTitle')"></h2>
    </div>
    <div class="category_cols padded--bottom justify-center wizard-profile-main">
      <div v-for="(category, index) in categories"
      v-bind:key="index"
      class="category"
      :class="[{ 'selected' : isSelected(category) }]">
        <div class="category__inner">
          <div class="category__about">
            <div class="category__title" @click="select(category); confirm();">
              <h1>{{ category.type }}</h1>
            </div>
            <div class="tooltip category__tooltip">
                <div class="cols--mini align-center">
                  <i class="icon icon-info" 
                     @mousedown          = "showInfo(category.id, true)" 
                     @touchstart.prevent = "showInfo(category.id, true)" 
                     @mouseover          = "showInfo(category.id)" 
                     @mouseleave         = "closeInfo"></i>
                </div>
		<div v-show="isInfoVisible && infoCategoryId == category.id" class="categoryInfo" 
		     @mouseover      = "showInfo(category.id)" 
		     @mouseleave     = "closeInfo">
		  <span @click="closeInfo" @touch="closeInfo" class="icon icon-infoClose"></span>
		  <div class="categoryDetail" v-html="category.details"></div>
		</div>
            </div>

	    <div class="category__description" v-html="getCategoryDescription(category)"></div>

            <div class="category__info">
              <div class="category__property">
                <div class="category__label">Ceny od</div>
                <div class="category__value" v-html="$t(`priceVat`, { value: priceMin(variations, category) })"></div>
              </div>

              <div class="category__image">
                <img :src="getStorageUrl(category.image)">
              </div>

              <div class="category__property">
                <div style="text-align: left;">
		 <span class="categoryWidthIcon"></span>
                 <div class="categoryHelpBlock">
                  <div v-html="$t('widthLabelCat')"></div>
                  <span class="categorySizeFont">{{ widthMin(category) }} - {{ widthMax(category) }}</span>
                 </div>
                </div>
                <div style="text-align: left;">
		 <span class="categoryHeightIcon"></span>
                 <div class="categoryHelpBlock">
                  <div v-html="$t('heightLabelCat')"></div>
                  <span class="categorySizeFont">{{ heightMin(category) }} - {{ heightMax(category) }}</span>
                 </div>
		</div>
              </div>

	      <button
	      type="button"
	      @click="select(category); confirm();"
	      :class="[{ 'disabled' : !isConfirmReady() }]"
	      v-html="$t('Konfigurovat')"></button>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } 	 from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType:   "getCurrentCartField",
  mutationType: "updateCurrentCartField"
});

export default {
  name: "Home",
  data() {
    return { isInfoVisible: false, infoCategoryId: "" };
  },
  computed: {
    ...mapState({
      variationsLoaded: state 	=> state.variations.loaded,
      variations: 	state 	=> state.variations.variations,
      categories: 	state 	=> state.categories.categories.slice(1),
      catminmax:	state	=> state.catminmax.catminmax
    }),
    ...mapFields("cart", ["variation", "category"]),
    details() {
     if(this.$store.state.categories.categories[0])
      { 
       return this.$store.state.categories.categories[0].details; 
      }
     return false;
    },    
  },
  methods: {
    showInfo(id, click)
     {
      if(this.isInfoVisible == false && this.infoCategoryId == "")
       {
        this.isInfoVisible  = true;
        this.infoCategoryId = id;
       }
      else if (this.isInfoVisible == true && this.infoCategoryId != "")
       {
	if(this.infoCategoryId == id && click)
	 {
          this.isInfoVisible  = false;
          this.infoCategoryId = "";
	 }
	else
	 {
          this.isInfoVisible  = true;
          this.infoCategoryId = id;
	 }
       }
      else
       {
        this.isInfoVisible  = false;
	this.infoCategoryId = "";
       }
     },
    closeInfo() 
     {
      this.isInfoVisible = false;
      this.infoCategoryId = "";
     },
    heightMin(category) {
      return this.propertyLimitCat(this.catminmax, "height", "min", category);
    },
    heightMax(category) {
      return this.propertyLimitCat(this.catminmax, "height", "max", category);
    },
    widthMin(category) {
      return this.propertyLimitCat(this.catminmax, "width", "min",  category);
    },
    widthMax(category) {
      return this.propertyLimitCat(this.catminmax, "width", "max",  category);
    },
    priceMin(variations, category) 
     {
      if (!this.variationsLoaded) { return 0; }
      let categoryId = category.id;
      let out = variations.filter(function(m) { return m["category"] == categoryId; });
      let thisObj  = this;
      let outArray =[];
      if(Array.isArray(out) && out.length > 0)
       {
        out.forEach(function(item, index) 
	 { 
	  let curValue = parseInt(thisObj.getProductMinPrice(item, thisObj.profiles.slice(1), thisObj.widthMin(category), thisObj.heightMin(category)));
	  outArray[index] = curValue;
	 });
        let outValue = outArray.reduce((prev, curr) => { return prev < curr ? prev : curr; });
        return outValue;
       }
     },
    getCategoryDescription(category) {
      return category.description;
    },
    isSelected(category) {
      if (this.category === null) 
       { return false; }
      if (typeof(this.category) !== "undefined")
       { return this.category.type === category.type; }
      else
       { return false; }
    },
    select(category) {
     this.category = category;
    },
    isConfirmReady() {
      return this.category !== null;
    },
    confirm() {
      if (this.isConfirmReady()) {
        this.$router.push({ name: "size" });
      }
    },
    selectFirstCategory() {
      if (this.category === null) {
        var categoriesArray = [];

        if (typeof this.categories === 'object') 
	 { categoriesArray = Object.values(this.categories); } 
        else 
         { categoriesArray = this.categories; }

        categoriesArray.forEach(category => {
          if ( category.id == 1 ) 
	   { this.select(category); }
        });
      }
    }
  },
  mounted() {
//  this.$store.dispatch("loadCategories", () => { this.selectFirstCategory(); });
    this.$store.dispatch("loadCategories");
    this.$store.dispatch("loadProfiles");
    this.$store.dispatch("loadCatMinMax");
  }
};
</script>
