<template>
  <div class="container" v-if="accessoryDataLoaded">
    <h2 class="headline--padded text-center" v-html="$t('accessoryInfoTitle')"></h2>

    <span @click="close" class="icon icon-close"></span>
    
    <div v-for="accessory in items" :key="accessory.id">
        <div v-if="accessory.id === $route.params.id" v-html="accessory.details"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WizardAccessoryInfo",
  data() {
    return {
      accessoryDataLoaded: false
    };
  },
  computed: {
    ...mapState({
      products: state => state.accessories.products
    }),
    items() {
      return this.products[this.$route.params.accessory];
    },
    accessory() {
      return this.items.find(element => element.id === this.$route.params.id);
    }
  },
  methods: {
    close() {
      this.$router.push({ name: "accessories" });
    }
  },
  mounted() {
    this.$store.dispatch("loadAccessories", {
      type: this.$route.params.accessory,
      callback: () => {
        this.accessoryDataLoaded = true;
      }
    });
  }
};
</script>

<style lang="scss">
</style>
